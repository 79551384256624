import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import shortid from "shortid";
import moment from "moment";

import { buildUrl } from "../utils/fetchV2";

const SearchContext = React.createContext();
export const SearchUpdaterContext = React.createContext();
export const SearchCalendarFilterTypeContext = React.createContext();

export const hasFilters = (q) => {
  return [
    q.filter.categories.length,
    q.filter.dateFilter.start !== null,
    q.filter.dateFilter.end !== null,
    q.filter.guestStatusFilter.length > 0,
    q.filter.meetingStatusFilter.length > 0,
    q.filter.meetingTypeFilter.length > 0,
    q.filter.tagFilter.length > 0,
    !!q.filter.query,
    q.sort.field !== "lastActivity" || q.sort.order !== "desc",
  ].some((d) => d);
};
const DATETIME_PATTERN = "YYYY-MM-DDTHH:mm:ss";

function useInitialSearch() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  let dateFilterBy = params.get("dateFilterBy");
  if (dateFilterBy === null) {
    dateFilterBy = "startTime";
  }
  let dateFilterEnd = params.get("dateFilterEnd");
  if (dateFilterEnd !== null) {
    dateFilterEnd = moment(dateFilterEnd, DATETIME_PATTERN);
  }
  let dateFilterStart = params.get("dateFilterStart");
  if (dateFilterStart !== null) {
    dateFilterStart = moment(dateFilterStart, DATETIME_PATTERN);
  }
  let tags = params.get("tags");
  if (tags !== null) {
    tags = tags.split(",").map((s) => {
      const [id, name] = s.split(":");
      return {
        id: parseInt(id),
        name,
      };
    });
  } else {
    tags = [];
  }
  let meetingTypes = params.get("meetingTypes");
  if (meetingTypes !== null) {
    meetingTypes = meetingTypes.split(",").map((s) => {
      const [id, name] = s.split(":");
      return {
        id: parseInt(id),
        name,
      };
    });
  } else {
    meetingTypes = [];
  }
  let meetingStatuses = params.get("meetingStatuses");
  if (meetingStatuses !== null) {
    meetingStatuses = meetingStatuses.split(",");
  } else {
    meetingStatuses = [];
  }
  let guestStatuses = params.get("guestStatuses");
  if (guestStatuses !== null) {
    guestStatuses = guestStatuses.split(",");
  } else {
    guestStatuses = [];
  }
  let query = params.get("query");
  if (query === null) {
    query = "";
  }
  let sortField = params.get("sortField");
  if (sortField === null) {
    sortField = "lastActivity";
  }
  let sortOrder = params.get("sortOrder");
  if (sortOrder === null) {
    sortOrder = "desc";
  }
  let categories = params.get("categories");
  if (categories !== null) {
    categories = categories.split(",");
  } else {
    categories = ["Guest Email"]; // default filter for search by category
  }
  return useRef({
    filter: {
      categories,
      dateFilter: {
        by: dateFilterBy,
        end: dateFilterEnd,
        start: dateFilterStart,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      guestStatusFilter: guestStatuses,
      meetingStatusFilter: meetingStatuses,
      meetingTypeFilter: meetingTypes,
      query,
      tagFilter: tags,
    },
    id: shortid.generate(),
    sort: {
      field: sortField,
      order: sortOrder,
    },
  });
}

function parseCalendarFilterType() {
  const params = new URLSearchParams(document.location.search);
  return params.get("calendarFilterType") || "All Time";
}

export function useHasSearchFilters() {
  return hasFilters(useContext(SearchContext));
}

export function useSearch() {
  return useContext(SearchContext);
}

export function useSearchUpdater() {
  return useContext(SearchUpdaterContext);
}

export function useSearchCalendarFilterType() {
  return useContext(SearchCalendarFilterTypeContext);
}

export function Search({ children }) {
  const history = useHistory();
  const calendarFilterTypeState = useState(parseCalendarFilterType());
  const [calendarFilterType] = calendarFilterTypeState;
  const { current: initialSearch } = useInitialSearch();
  const [search, setSearch] = useState(initialSearch);
  useEffect(() => {
    const query = {};
    if (
      search.filter.dateFilter.by &&
      search.filter.dateFilter.by !== "startTime"
    ) {
      query.dateFilterBy = search.filter.dateFilter.by;
    }
    if (calendarFilterType !== null && calendarFilterType !== "All Time") {
      query.calendarFilterType = calendarFilterType;
    }
    if (search.filter.dateFilter.end !== null) {
      query.dateFilterEnd =
        search.filter.dateFilter.end.format(DATETIME_PATTERN);
    }
    if (search.filter.dateFilter.start !== null) {
      query.dateFilterStart =
        search.filter.dateFilter.start.format(DATETIME_PATTERN);
    }
    if (search.filter.tagFilter.length > 0) {
      query.tags = search.filter.tagFilter.map((t) => `${t.id}:${t.name}`);
    }
    if (search.filter.query.length > 0) {
      query.query = search.filter.query;
    }
    if (search.filter.meetingTypeFilter.length > 0) {
      query.meetingTypes = search.filter.meetingTypeFilter.map(
        (mt) => `${mt.id}:${mt.name}`,
      );
    }
    if (search.filter.meetingStatusFilter.length > 0) {
      query.meetingStatuses = search.filter.meetingStatusFilter;
    }
    if (search.filter.guestStatusFilter.length > 0) {
      query.guestStatuses = search.filter.guestStatusFilter;
    }
    if (search.sort.field !== "lastActivity" || search.sort.order !== "desc") {
      query.sortField = search.sort.field;
      query.sortOrder = search.sort.order;
    }
    if (search.filter.categories.length) {
      query.categories = search.filter.categories.join(",");
    }
    if (Object.keys(query).length > 0) {
      const { pathname, search: srch } = window.location;
      const url = buildUrl(pathname, query);
      if (pathname + srch !== url) history.push(url);
    } else {
      const { pathname, search: srch } = window.location;
      const url = buildUrl(pathname);
      if (pathname + srch !== url) history.push(url);
    }
  }, [calendarFilterType, history, search]);

  const queryUpdater = useRef({
    clearDateFilter: () => {
      setSearch((prev) => {
        const { filter } = prev;

        return {
          ...prev,
          filter: {
            ...filter,
            dateFilter: {
              end: null,
              start: null,
            },
          },
          id: shortid.generate(),
        };
      });
    },
    clearFilters: () => {
      const [, setCalendarFilterType] = calendarFilterTypeState;
      setCalendarFilterType("All Time");
      setSearch((prev) => {
        return {
          ...prev,
          filter: {
            categories: [],
            dateFilter: {
              end: null,
              start: null,
              timezone: "UTC",
            },
            guestStatusFilter: [],
            meetingStatusFilter: [],
            meetingTypeFilter: [],
            query: "",
            tagFilter: [],
          },
          sort: {
            field: "lastActivity",
            order: "desc",
          },
        };
      });
    },
    clearGuestStatusFilters: () => {
      setSearch((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            guestStatusFilter: [],
          },
        };
      });
    },
    refresh: () => {
      return setSearch((prev) => {
        return {
          ...prev,
          id: shortid.generate(),
        };
      });
    },
    setCategories: (newCategories) => {
      setSearch((prev) => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            categories: newCategories,
          },
          id: shortid.generate(),
        };
      });
    },
    setDateFilter: (dateFilter) => {
      setSearch((prev) => {
        const { filter } = prev;
        const { dateFilter: prevDateFilter } = filter;
        return {
          ...prev,
          filter: {
            ...filter,
            dateFilter: {
              ...prevDateFilter,
              ...dateFilter,
            },
          },
          id: shortid.generate(),
        };
      });
    },
    setDateFilterBy: (dateFilterBy) => {
      setSearch((prev) => {
        const { filter } = prev;
        const { dateFilter } = filter;
        return {
          ...prev,
          filter: {
            ...filter,
            dateFilter: {
              ...dateFilter,
              by: dateFilterBy,
            },
          },
          id: shortid.generate(),
        };
      });
    },
    setGuestStatusFilter: (guestStatusFilter) => {
      setSearch((prev) => {
        const { filter } = prev;
        return {
          ...prev,
          filter: {
            ...filter,
            guestStatusFilter,
          },
          id: shortid.generate(),
        };
      });
    },
    setMeetingStatusFilter: (meetingStatusFilter) => {
      setSearch((prev) => {
        const { filter } = prev;
        return {
          ...prev,
          filter: {
            ...filter,
            meetingStatusFilter,
          },
          id: shortid.generate(),
        };
      });
    },
    setMeetingTypeFilter: (meetingTypeFilter) => {
      setSearch((prev) => {
        const { filter } = prev;
        return {
          ...prev,
          filter: {
            ...filter,
            meetingTypeFilter,
          },
          id: shortid.generate(),
        };
      });
    },
    setQuery: (query) => {
      setSearch((prev) => {
        const { filter } = prev;
        return {
          ...prev,
          filter: {
            ...filter,
            query,
          },
          id: shortid.generate(),
        };
      });
    },
    setSort: (newSort) => {
      setSearch((prev) => {
        return {
          ...prev,
          id: shortid.generate(),
          sort: newSort,
        };
      });
    },
    setTagFilter: (tagFilter) => {
      setSearch((prev) => {
        const { filter, paging } = prev;
        return {
          ...prev,
          filter: {
            ...filter,
            tagFilter,
          },
          id: shortid.generate(),
          paging: {
            ...paging,
            offset: 0,
          },
        };
      });
    },
    toggleGuestStatusFilter: (value) => {
      setSearch((prev) => {
        const { filter } = prev;
        const { guestStatusFilter } = filter;
        if (guestStatusFilter.includes(value)) {
          const newGuestStatusFilter = guestStatusFilter.filter(
            (f) => f !== value,
          );
          return {
            ...prev,
            filter: {
              ...filter,
              guestStatusFilter: newGuestStatusFilter,
            },
            id: shortid.generate(),
          };
        }
        return {
          ...prev,
          filter: {
            ...filter,
            guestStatusFilter: [...guestStatusFilter, value],
          },
          id: shortid.generate(),
        };
      });
    },
    toggleMeetingStatusFilter: (value) => {
      setSearch((prev) => {
        const { filter } = prev;
        const { meetingStatusFilter } = filter;
        if (meetingStatusFilter.includes(value)) {
          const newMeetingStatusFilter = meetingStatusFilter.filter(
            (f) => f !== value,
          );
          return {
            ...prev,
            filter: {
              ...filter,
              meetingStatusFilter: newMeetingStatusFilter,
            },
            id: shortid.generate(),
          };
        }
        return {
          ...prev,
          filter: {
            ...filter,
            meetingStatusFilter: [...meetingStatusFilter, value],
          },
          id: shortid.generate(),
        };
      });
    },
    toggleMeetingTypeFilter: (value) => {
      setSearch((prev) => {
        const { filter } = prev;
        const { meetingTypeFilter } = filter;
        const meetingTypeFilterIds = meetingTypeFilter.map((mt) => mt.id);
        if (meetingTypeFilterIds.includes(value.id)) {
          const newMeetingTypeFilter = meetingTypeFilter.filter(
            (f) => f.id !== value.id,
          );
          return {
            ...prev,
            filter: {
              ...filter,
              meetingTypeFilter: newMeetingTypeFilter,
            },
            id: shortid.generate(),
          };
        }
        return {
          ...prev,
          filter: {
            ...filter,
            meetingTypeFilter: [...meetingTypeFilter, value],
          },
          id: shortid.generate(),
        };
      });
    },
    toggleSortOrder: () => {
      setSearch((prev) => {
        const { sort } = prev;
        let newOrder = "desc";
        if (sort.order === "desc") {
          newOrder = "asc";
        }
        return {
          ...prev,
          id: shortid.generate(),
          sort: {
            ...sort,
            order: newOrder,
          },
        };
      });
    },
    toggleTagFilter: (value) => {
      setSearch((prev) => {
        const { filter, paging } = prev;
        const { tagFilter } = filter;
        if (tagFilter.includes(value)) {
          const newTags = tagFilter.filter((f) => f !== value);
          return {
            ...prev,
            filter: {
              ...filter,
              tagFilter: newTags,
            },
            id: shortid.generate(),
            paging: {
              ...paging,
              offset: 0,
            },
          };
        }
        return {
          ...prev,
          filter: {
            ...filter,
            tagFilter: [...tagFilter, value],
          },
          id: shortid.generate(),
          paging: {
            ...paging,
            offset: 0,
          },
        };
      });
    },
  });
  return (
    <SearchContext.Provider value={search}>
      <SearchCalendarFilterTypeContext.Provider value={calendarFilterTypeState}>
        <SearchUpdaterContext.Provider value={queryUpdater.current}>
          {children}
        </SearchUpdaterContext.Provider>
      </SearchCalendarFilterTypeContext.Provider>
    </SearchContext.Provider>
  );
}
