import React, { useCallback, useMemo, useState } from "react";

import { Logo } from "./Logo";
import { INTEGRATION_AUTH_STATUS } from "../props";
import { EnvironmentURLInput } from "./EnvironmentURLInput";
import {
  ComingSoon,
  DeleteButton,
  ReauthorizeButton,
  ConnectButton,
  DisconnectButton,
} from "../buttons";
import {
  IntegrationCard,
  IntegrationCardButtonArea,
  IntegrationCardTitle,
} from "../IntegrationCard";
import { ConnectionStatus } from "../ConnectionStatus";
import { determineIntegrationStatus } from "../util";
import style from "./style.module.scss";

export function DynamicsIntegrationCard({
  enabled,
  onConnect,
  onDisconnect,
  integration = null,
  testURL = "",
  isLoading,
}) {
  const connected = integration !== null;
  const authStatus = determineIntegrationStatus(integration);

  const [environmentURL, setEnvironmentURL] = useState("");

  const connectionStatus = useMemo(
    () => `Auth Status: ${authStatus}`,
    [authStatus],
  );

  // reauthorize existing integration
  const reauthorize = useCallback(() => {
    onConnect(integration.id, environmentURL);
  }, [integration, onConnect, environmentURL]);

  // connect new integration
  const connect = useCallback(() => {
    onConnect(0, environmentURL);
  }, [onConnect, environmentURL]);

  const handleEnvironmentUrlChange = useCallback(
    (event) => {
      setEnvironmentURL(event.target.value);
    },
    [setEnvironmentURL],
  );

  return (
    <IntegrationCard enabled={enabled}>
      {connected && <ConnectionStatus>{connectionStatus}</ConnectionStatus>}
      <Logo />

      <IntegrationCardTitle>Microsoft Dynamics</IntegrationCardTitle>

      <IntegrationCardButtonArea>
        {isLoading && <span>Loading Integrations...</span>}
        {enabled &&
          !connected &&
          authStatus === INTEGRATION_AUTH_STATUS.DISABLED && (
            <>
              <ConnectButton
                name="/integrations/dynamics/connect"
                onClick={connect}
                disabled={!environmentURL}
              />
              <EnvironmentURLInput
                name="/integrations/dynamics/environment_url"
                onChange={handleEnvironmentUrlChange}
                value={environmentURL}
                testURL={testURL}
              />
            </>
          )}

        {enabled &&
          connected &&
          authStatus === INTEGRATION_AUTH_STATUS.EXPIRED && (
            <>
              <div className={style.reauth_container}>
                <ReauthorizeButton
                  name="/integrations/dynamics/reauthorize"
                  onClick={reauthorize}
                  disabled={!environmentURL}
                />
                <DeleteButton
                  name="/integrations/dynamics/delete"
                  onClick={onDisconnect}
                />
              </div>
              <EnvironmentURLInput
                name="/integrations/dynamics/environment_url"
                onChange={handleEnvironmentUrlChange}
                value={environmentURL}
                testURL={testURL}
              />
            </>
          )}

        {enabled &&
          connected &&
          authStatus === INTEGRATION_AUTH_STATUS.CURRENT && (
            <DisconnectButton
              name="/integrations/dynamics/disconnect"
              onClick={onDisconnect}
            />
          )}
        {!enabled && <ComingSoon name="/integrations/dynamics/coming_soon" />}
      </IntegrationCardButtonArea>
    </IntegrationCard>
  );
}
