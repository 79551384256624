import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Meeting } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

interface routeMeetingResponse {
  routing: Meeting[];
  failedList: any[];
}

export function useRouteMeetings() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (
    ids: number[],
  ): Promise<{ failures: any[]; instances: Meeting[] }> => {
    const response: routeMeetingResponse = await service
      .post(`/api/meetings/instances/routing`)
      .set(headers)
      .send(ids)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.startsWith("/api/meetings/instance"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);

    return { failures: response?.failedList, instances: response?.routing };
  };
}
