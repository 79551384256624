import { useCallback } from "react";
import {
  // guest statuses
  ACCEPTED_STATUS,
  AI_NEGOTIATING_STATUS,
  AWAITING_RESPONSE_STATUS,
  AWAY_STATUS,
  CANCELLED_STATUS,
  DECLINED_STATUS,
  HOST_INTERVENED_STATUS,
  NO_RESPONSE_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  UNDELIVERABLE_STATUS,
} from "../props";

// OVERRIDE_RULES contains the rules of from/to guest status overrides
// if this gets changed, please change the corresponding object in services repo
// app/domain/bo/meeting_guest_status.go
const OVERRIDE_RULES = {
  [ACCEPTED_STATUS]: [
    DECLINED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
  ],
  [CANCELLED_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    UNDELIVERABLE_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [UNDELIVERABLE_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    CANCELLED_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [DECLINED_STATUS]: [
    ACCEPTED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
  ],
  [AWAY_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [AWAITING_RESPONSE_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [AI_NEGOTIATING_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [REQUIRES_HOST_INTERVENTION_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [HOST_INTERVENED_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    CANCELLED_STATUS,
    UNDELIVERABLE_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    NO_RESPONSE_STATUS,
  ],
  [NO_RESPONSE_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    RESPONDED_UNKNOWN_INTENT_STATUS,
    UNDELIVERABLE_STATUS,
    CANCELLED_STATUS,
  ],
  [RESPONDED_UNKNOWN_INTENT_STATUS]: [
    ACCEPTED_STATUS,
    DECLINED_STATUS,
    UNDELIVERABLE_STATUS,
    CANCELLED_STATUS,
    NO_RESPONSE_STATUS,
  ],
};

export default function useOverrideStatusRules() {
  const overrideOptions = useCallback(({ fromStatus }) => {
    if (!(fromStatus in OVERRIDE_RULES)) {
      return null;
    }

    const allowableStatuses = OVERRIDE_RULES[fromStatus];

    if (allowableStatuses.length < 1) {
      return null;
    }

    return allowableStatuses;
  }, []);

  const overridable = useCallback(
    ({ fromStatus }) => overrideOptions({ fromStatus }) !== null,
    [overrideOptions],
  );

  return {
    overridable,
    overrideOptions,
  };
}
