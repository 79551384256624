import { Box, Link, Typography, useTheme } from "@mui/material";
import { INTEGRATION_AUTH_STATUS } from "src/integrations/props";
import { determineAuthStatus } from "src/integrations/util";
import useUserAccounts from "src/queries/useUserAccounts";
import { PageBanner } from "./PageBanner";
import { Link as RouterLink } from "react-router-dom";

export function AccountStatusBanner() {
  const theme = useTheme();
  const { data, loading, error } = useUserAccounts();
  if (loading) {
    return null;
  }
  if (error) {
    return null;
  }

  const invalidAuth =
    data?.data?.filter(
      (account) =>
        determineAuthStatus(account) !== INTEGRATION_AUTH_STATUS.CURRENT,
    ) || [];

  if (invalidAuth.length === 0) {
    return null;
  }

  // Show an error banner if all accounts are disconnected.
  if (invalidAuth.length === data?.data?.length) {
    return (
      <PageBanner>
        <Box
          sx={{
            p: 2,
            backgroundColor: theme.palette.error.main,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.error.contrastText,
            }}
          >
            Kronologic does not have access required to be able to schedule your
            meetings. Go to the{" "}
            <Link component={RouterLink} to="/settings" color="inherit">
              settings page
            </Link>{" "}
            to re-authorize the app now.
          </Typography>
        </Box>
      </PageBanner>
    );
  }

  return (
    <PageBanner>
      <Box
        sx={{
          p: 2,
          backgroundColor: theme.palette.warning.main,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.warning.contrastText,
          }}
        >
          Kronologic does not have access some of your accounts:{" "}
          {invalidAuth.map((account) => account.email).join(", ")}. Go to the{" "}
          <Link component={RouterLink} to="/settings" color="inherit">
            settings page
          </Link>{" "}
          to re-authorize the app now.
        </Typography>
      </Box>
    </PageBanner>
  );
}
