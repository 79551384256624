import React, { useCallback, useState } from "react";
import ReactSelectAsyncCreatableSelect from "react-select/async-creatable";

import { useDebounce } from "../utils/debounce";

function AsyncCreatableSelect({
  components,
  customTheme,
  disabled,
  dataCy = null,
  dataGtm = null,
  onChange,
  onCreateOption,
  defaultOptions,
  load,
  menuPortalTarget = null,
  name = null,
  noOptionsMessage,
  toOption,
  placeholder,
  value,
  isOptionDisabled,
  isMulti = false,
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  const [, setPreviousPromise] = useState(null);
  const loadImpl = useCallback(
    async (text, resolve) => {
      const {
        data: { data },
        error,
      } = await load(text);
      if (error) {
        resolve([]);
        return;
      }
      resolve(data.map(toOption));
      setPreviousPromise(null);
    },
    [load, toOption, setPreviousPromise],
  );
  const debouncedLoadImpl = useDebounce(loadImpl, 300);
  // To debounce the load return a promise that resolves to the debounced call to debounceLoadImpl
  const debouncedLoad = useCallback(
    (text) => {
      return new Promise(async (resolve, reject) => {
        setPreviousPromise((prev) => {
          if (prev !== null) {
            // Reject the previous promise, AsyncCreatableSelect is no longer referencing it
            prev();
          }
          return reject;
        });
        await debouncedLoadImpl(text, resolve);
      });
    },
    [debouncedLoadImpl, setPreviousPromise],
  );
  // AsyncCreatableSelect takes a new promise each time text is entered
  const loadOptions = useCallback(
    async (text) => {
      return debouncedLoad(text);
    },
    [debouncedLoad],
  );
  return (
    <ReactSelectAsyncCreatableSelect
      aria-label={name}
      components={components}
      isDisabled={disabled}
      isMulti={isMulti}
      createOptionPosition="first"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onCreateOption={onCreateOption}
      styles={customTheme}
      name={name}
      onChange={onChange}
      menuPortalTarget={menuPortalTarget}
      placeholder={placeholder}
      isOptionDisabled={isOptionDisabled}
      value={value}
      {...dataProps}
    />
  );
}

export default AsyncCreatableSelect;
