import { useEffect } from "react";

// these are the listener types that can be fed into an event listener -- add as needed
export const LISTENER_TYPES = {
  MOUSEDOWN: "mousedown",
};

export default function useEventListener(
  type: string,
  listener: EventListenerOrEventListenerObject,
) {
  useEffect(() => {
    document.addEventListener(type, listener);
    return () => {
      document.removeEventListener(type, listener);
    };
  }, [type, listener]);
}
