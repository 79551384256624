import Papa from "papaparse";
import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useUserService } from "../services";
import { Role } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

function stringToArray(text: string): Promise<number[]> {
  return new Blob([text], { type: "text/csv" })
    .arrayBuffer()
    .then((a) => [...new Uint8Array(a)]);
}

export function useUploadUsers() {
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const accessToken = getUserToken();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (users: { email: string; role: Role }[]): Promise<void> => {
    return stringToArray(
      Papa.unparse(users.map((user) => [user.email || "", user.role ?? ""])),
    ).then((table) =>
      service
        .post(`/api/users/import/csv`)
        .send({
          table,
          headers: ["email", "role"],
          meta: {
            platform: "web",
            source: "users_page/upload_users",
          },
        })
        .set(headers)
        .then((res: Response) => res.body)
        .then((res: Response) => {
          // @ts-ignore
          const keys = cache.keys();

          // Invalidate contact queries and force refresh.
          Array.from(keys)
            .filter((r: any) => r.includes("/api/user"))
            .forEach((k: any) => {
              mutate(k);
            });

          return res;
        })
        .catch(errorHandler),
    );
  };
}

export default useUploadUsers;
