import moment from "moment";

class HttpError extends Error {
  constructor(text, { response }) {
    super(text);
    this.response = response;
  }
}

class LoggableHttpError extends HttpError {
  constructor(
    text,
    { className = null, methodName = null, response = null } = {},
  ) {
    super(text, { response });
    const { location } = window;
    const { pathname } = location;
    this.log = {
      className,
      methodName,
      path: pathname,
      severity: 6,
      text,
      threadId: "main",
      timestamp: moment.utc().toISOString(),
    };
  }
}

export class BadRequestError extends LoggableHttpError {}
export class NotFoundError extends LoggableHttpError {}
export class UnauthorizedError extends LoggableHttpError {}
export class ConflictError extends LoggableHttpError {}
export class ForbiddenError extends LoggableHttpError {}

export class UnknownError extends Error {
  constructor(text) {
    super(text);
    const { location } = window;
    const { pathname } = location;
    this.log = {
      path: pathname,
      text,
      timestamp: moment.utc().toISOString(),
    };
  }
}

export function isConflictError(error) {
  return error instanceof ConflictError;
}

export function hasUnauthorizedErrors(...errors) {
  return errors.some((e) => e instanceof UnauthorizedError);
}

export function hasForbiddenErrors(...errors) {
  return errors.some((e) => e instanceof ForbiddenError);
}

export function toErrorLogInfo(className, request, response) {
  return {
    className,
    methodName: request.url,
    response,
  };
}
