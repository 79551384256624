import React, { useMemo } from "react";
import { ButtonContainer } from "../../../../components/button";
import Card from "../../../../components/card/Card";
import Flexbox from "../../../../components/flexbox";
import useMeetingStatusFilterOnClick from "../useMeetingStatusFilterOnClick";
import { P1 } from "../../../../components/typography";
import { LoadingCard } from "../../../../components/genericView/GenericView";
import {
  SCHEDULING_QUEUED_STAGE,
  SCHEDULING_STAGE,
  INSTANCE_STAGE_TO_LABEL,
  SCHEDULED_STAGE,
} from "../../../props";
import style from "./instanceActive.module.scss";

const InstanceActive = ({
  isLoading = true,
  scheduling = 0,
  queued = 0,
  scheduled = 0,
}) => {
  const handleOnSchedulingClick = useMeetingStatusFilterOnClick([
    SCHEDULING_STAGE,
  ]);

  const handleOnSchedulingQueuedClick = useMeetingStatusFilterOnClick([
    SCHEDULING_QUEUED_STAGE,
  ]);

  const handleOnScheduledClick = useMeetingStatusFilterOnClick([
    SCHEDULED_STAGE,
  ]);

  const handleOnTotalClick = useMeetingStatusFilterOnClick([
    SCHEDULING_STAGE,
    SCHEDULING_QUEUED_STAGE,
    SCHEDULED_STAGE,
  ]);

  const total = useMemo(
    () => scheduling + queued + scheduled,
    [scheduling, queued, scheduled],
  );

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <div className={style.instanceActive}>
          {isLoading && (
            <LoadingCard
              className={style.instanceActive__loadingCard}
              height={160}
            />
          )}

          {!isLoading && (
            <Card className={style.instanceActive__card}>
              <Column className={style.instanceActive__card_content}>
                <Row>
                  <P1
                    className={style.instanceActive__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[SCHEDULING_QUEUED_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceActive__value}
                    name="/instances/dashboard_is/queued"
                    onClick={handleOnSchedulingQueuedClick}
                    transparent
                  >
                    <P1 bold>{queued}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    className={style.instanceActive__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[SCHEDULING_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceActive__value}
                    name="/instances/dashboard_is/scheduling"
                    onClick={handleOnSchedulingClick}
                    transparent
                  >
                    <P1 bold>{scheduling}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    className={style.instanceActive__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[SCHEDULED_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceActive__value}
                    name="/instances/dashboard_is/scheduled"
                    onClick={handleOnScheduledClick}
                    transparent
                  >
                    <P1 bold>{scheduled}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1 className={style.instanceActive__text_total} small bold>
                    Total Meetings
                  </P1>

                  <ButtonContainer
                    className={style.instanceActive__value}
                    name="/instances/dashboard_is/total_instances_scheduled_group"
                    onClick={handleOnTotalClick}
                    transparent
                  >
                    <P1 bold>{total}</P1>
                  </ButtonContainer>
                </Row>
              </Column>
            </Card>
          )}
        </div>
      )}
    </Flexbox>
  );
};

export default InstanceActive;
