import { useMemo } from "react";
import { WHITE, BLACK } from "../colors";

const useTimezoneAbbrTheme = () => {
  return useMemo(
    () => ({
      container: (base, { isFocused }) => ({
        ...base,
        backgroundColor: "#f8f9fb",
        borderRadius: "4px",
        color: isFocused ? WHITE : BLACK,
        height: "30px",
        justifyContent: "flex-start",
      }),
      control: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "#f8f9fb",
        border: 0,
        borderRadius: "2px",
        boxShadow: 0,
        color: "#d5dbe9",
        cursor: "pointer",
        display: "flex",
        fontSize: "10px",
        height: "30px",
        justifyContent: "center",
        padding: "0px",
        width: "140px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        position: "absolute",
        right: "0",
        visibility: "hidden",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: BLACK,
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: BLACK,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0",
        width: "140px",
      }),
      menuList: (base) => ({
        ...base,
        alignItems: "center",
        border: "0.5px solid #A8B1BD",
        borderRadius: 0,
        justifyContent: "center",
        marginTop: "0px",
        padding: "0px",
      }),
      option: (base, { isFocused }) => ({
        ...base,
        alignItems: "center",
        backgroundColor: isFocused ? "#F1FAFF" : WHITE,
        color: BLACK,
        cursor: "pointer",
        justifyContent: "flex-start",
        overflow: "hidden",
        paddingBottom: "7px",
        paddingLeft: "2px",
        paddingTop: "7px",
        textOverflow: "ellipsis",
        whitespace: "nowrap",
      }),
      singleValue: (base) => ({
        ...base,
        color: BLACK,
        justifyContent: "flex-start",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whitespace: "nowrap",
        width: "140px",
      }),
      valueContainer: (base) => ({
        ...base,
        alignItems: "center",
        color: BLACK,
        fontSize: "10px",
        fontWeight: "500",
        height: "50%",
        justifyContent: "flex-start",
        paddingLeft: "7px",
        width: "140px",
      }),
    }),
    [],
  );
};

export default useTimezoneAbbrTheme;
