import { useCallback, useMemo } from "react";
import rawTimezones from "../../assets/data/timezones.json";

const useTimezones = () => {
  const tmzData = useMemo(() => {
    const options = [];
    rawTimezones.forEach(({ text, utc }) => {
      return utc.forEach((value) => {
        options.push({
          label: `${text} - ${value}`,
          value,
        });
      });
    });
    return options;
  }, []);

  const tmzAbbrvData = useMemo(() => {
    const options = [];
    rawTimezones.forEach(({ abbr, utc }) => {
      return utc.forEach((value) => {
        options.push({
          label: `${abbr} - ${value}`,
          value,
        });
      });
    });
    return options;
  }, []);

  const getTimezoneText = useCallback(
    (utc) => {
      return tmzData.find((d) => d.value === utc)?.label;
    },
    [tmzData],
  );

  const getTimezoneAbbreviation = useCallback(
    (timezone) =>
      rawTimezones.find((tz) => tz.utc.includes(timezone))?.abbr || "",
    [],
  );

  const getBrowserTimezoneInUTC = () => {
    return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null;
  };

  const browserTimezone = useMemo(() => {
    const timezone =
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || null;

    if (timezone === null) {
      return "";
    }

    return getTimezoneAbbreviation(timezone);
  }, [getTimezoneAbbreviation]);

  return {
    browserTimezone,
    getBrowserTimezoneInUTC,
    getTimezoneAbbreviation,
    getTimezoneText,
    tmzAbbrvData,
    tmzData,
  };
};

export default useTimezones;
