import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { errorHandler } from "src/hooks/errorHandler";
import { useUserService } from "../services";
import { ChannelWithDetails } from "../types";

interface Filters {
  name: string;
  type: string;
  meetingTemplateID: number;
  integrationType: string;
  status: string[];
}

interface Query {
  filter?: Partial<Filters>;
}

export function useChannelsV2(limit: number, offset: number, query?: Query) {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const body = { ...query, limit, offset };

  const { data, error } = useSWR(
    [`/api/user/channels/list`, body],
    (u: string) =>
      service
        .post(u)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
  );

  return {
    data: data as { total: number; data: ChannelWithDetails[] },
    error,
    loading: !error && !data,
  };
}
