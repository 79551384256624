import React, { useState, useCallback, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import cx from "classnames";
import style from "./style.module.scss";

export const GeneralNotification = ({
  id,
  userDismiss = false,
  wait = 3000,
  setNotifications,
  children,
}) => {
  const [dismissed, setDismissed] = useState(false);

  const onDismiss = useCallback(() => setDismissed(true), []);

  const removeNotification = useCallback(() => {
    setNotifications((prev) => {
      const newState = new Map(prev);
      newState.delete(id);

      return newState;
    });
  }, [id, setNotifications]);

  // Starts notification fadeOut animation after waiting
  useEffect(() => {
    let timeoutId;

    if (!userDismiss && !dismissed) {
      timeoutId = setTimeout(() => {
        setDismissed(true);
      }, wait);
    }

    return () => clearTimeout(timeoutId);
  }, [userDismiss, wait, id, dismissed]);

  // Waits for the fadeOut animation to finish before removing the notification
  useEffect(() => {
    let timeoutId;

    if (dismissed) {
      timeoutId = setTimeout(() => {
        removeNotification(id);
      }, 800);
    }

    return () => clearTimeout(timeoutId);
  }, [dismissed, removeNotification, id]);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onDismiss}
      onKeyPress={onDismiss}
      className={cx(
        style.notification__body,
        style["notification__body--blur"],
        style["notification_type--general"],
        { [style["notification--dismissed"]]: dismissed },
      )}
    >
      {children}

      {userDismiss && (
        <Icon path={mdiClose} size={1} className={style.notification__close} />
      )}
    </div>
  );
};

export const ErrorNotification = ({
  id,
  userDismiss = false,
  wait = 3000,
  setNotifications,
  children,
}) => {
  const [dismissed, setDismissed] = useState(false);

  const onDismiss = useCallback(() => setDismissed(true), []);

  const removeNotification = useCallback(() => {
    setNotifications((prev) => {
      const newState = new Map(prev);
      newState.delete(id);

      return newState;
    });
  }, [id, setNotifications]);

  // Starts notification fadeOut animation after waiting
  useEffect(() => {
    let timeoutId;

    if (!userDismiss && !dismissed) {
      timeoutId = setTimeout(() => {
        setDismissed(true);
      }, wait);
    }

    return () => clearTimeout(timeoutId);
  }, [userDismiss, wait, id, dismissed]);

  // Waits for the fadeOut animation to finish before removing the notification
  useEffect(() => {
    let timeoutId;

    if (dismissed) {
      timeoutId = setTimeout(() => {
        removeNotification(id);
      }, 800);
    }

    return () => clearTimeout(timeoutId);
  }, [dismissed, removeNotification, id]);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onDismiss}
      onKeyPress={onDismiss}
      className={cx(
        style.notification__body,
        style["notification__body--blur"],
        style["notification_type--error"],
        { [style["notification--dismissed"]]: dismissed },
      )}
    >
      {children}

      {userDismiss && (
        <Icon path={mdiClose} size={1} className={style.notification__close} />
      )}
    </div>
  );
};
