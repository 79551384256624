import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useProvisioningService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useAddDomain() {
  const accessToken = getUserToken();

  const { mutate, cache } = useSWRConfig();

  const service = useProvisioningService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    orgId: number,
    domain: string,
    preferredClient: "google" | "microsoft",
  ): Promise<void> =>
    service
      .post(`/api/provisioning/org/${orgId}/domain`)
      .set(headers)
      .send({
        domain,
        preferredClient,
      })
      .then(tokenRefreshHandler)
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate contact queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/org"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .then((res: Response) => res.body)
      .catch(errorHandler);
}
