import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { MeetingDefinition, TemplateStatus } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export type SkinnyMeetingDefinition = Pick<
  MeetingDefinition,
  "id" | "name" | "inviteStyle" | "active" | "creationData" | "schedulingUrls"
>;

export type ListMeetingDefinitionFilters = {
  templateName?: string;
  templateIDs?: number[];
  creators?: number[];
  teams?: number[];
  status?: TemplateStatus[];
  tags?: number[];
  inviteStyle?: string[];
};

export function useMeetingTemplates(
  limit: number,
  offset: number,
  options: ListMeetingDefinitionFilters,
) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error, mutate } = useSWR(
    [`/api/meetings/definition/list`, limit, offset, options],
    async (
      url: string,
      limit: number,
      offset: number,
      options: ListMeetingDefinitionFilters,
    ) => {
      const queriesToFetch: Promise<{
        data: SkinnyMeetingDefinition[];
        total: number;
      }>[] = [];

      for (
        let currentOffset = 0;
        currentOffset <= offset;
        currentOffset += limit
      ) {
        const body = {
          filter: {
            templateName: options.templateName,
            templateIDs: options.templateIDs,
            creators: options.creators,
            teams: options.teams,
            status: options.status,
            tags: options.tags,
            inviteStyle: options.inviteStyle,
          },
          limit,
          offset: currentOffset,
        };
        const dataPromise = service
          .post(url)
          .set(headers)
          .send(body)
          .then(tokenRefreshHandler)
          .then((res: Response) => res.body)
          .then(
            (body: { data: SkinnyMeetingDefinition[]; total: number }) => body,
          )
          .catch(errorHandler);

        queriesToFetch.push(dataPromise);
      }

      const resultArray = await Promise.all(queriesToFetch);
      const dataToReturn: { data: SkinnyMeetingDefinition[]; total: number } = {
        data: [],
        total: 0,
      };

      resultArray.forEach(
        (result) =>
          (dataToReturn.data = dataToReturn.data.concat(result.data || [])),
      );
      dataToReturn.total = resultArray[0].total;

      return dataToReturn;
    },
  );

  return {
    data: data as
      | { data: SkinnyMeetingDefinition[]; total: number }
      | undefined,
    error,
    mutate,
    loading: !error && !data,
  };
}
