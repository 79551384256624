import cx from "classnames";
import { Icon } from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import React from "react";

import style from "./select.module.scss";

export function ClearIndicator({ innerProps: { ref, ...restInnerProps } }) {
  return (
    <div
      className={cx(
        style.select__dropdownIndicator,
        style.select__dropdownIndicatorButton,
      )}
      ref={ref}
      {...restInnerProps}
    >
      <Icon path={mdiPlus} size={0.7} rotate={45} />
    </div>
  );
}
