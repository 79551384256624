import { MeetingDefinition } from "src/types";
import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { buildQueryString } from "../utils";
import { errorHandler } from "src/hooks/errorHandler";

// ********************
// FIXME: kill this one
// ********************
export function useMeetingTypes(
  limit: number,
  offset: number,
  query?: string,
  inviteStyleFilter?: string[],
) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error, mutate } = useSWR(
    [`/api/meetings/definition`, limit, offset, query],
    async (url: string, limit: number, offset: number, query?: string) => {
      const queriesToFetch: Promise<{
        data: MeetingDefinition[];
        total: number;
      }>[] = [];

      for (
        let currentOffset = 0;
        currentOffset <= offset;
        currentOffset += limit
      ) {
        const queryString = buildQueryString({
          limit,
          offset: currentOffset,
          query,
          inviteStyleFilter,
        });
        const dataPromise = service
          .get(`${url}?${queryString}`)
          .set(headers)
          .then(tokenRefreshHandler)
          .then((res: Response) => res.body)
          .then((body: { data: MeetingDefinition[]; total: number }) => body)
          .catch(errorHandler);

        queriesToFetch.push(dataPromise);
      }

      const resultArray = await Promise.all(queriesToFetch);
      const dataToReturn: { data: MeetingDefinition[]; total: number } = {
        data: [],
        total: 0,
      };

      resultArray.forEach(
        (result) => (dataToReturn.data = dataToReturn.data.concat(result.data)),
      );
      dataToReturn.total = resultArray[0].total;

      return dataToReturn;
    },
  );

  return {
    data: data as { data: MeetingDefinition[]; total: number } | undefined,
    error,
    mutate,
    loading: !error && !data,
  };
}
