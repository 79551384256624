import * as React from "react";

import {
  Menu,
  Button,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  AccountCircleOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

export interface Option {
  icon: React.ReactNode;
  name: string;
  onClick: () => void;
}

function UserMenu({
  user,
  options,
  additionalOptions,
}: {
  user: {
    email?: string;
  } | null;
  options: Option[];
  additionalOptions?: Option[];
}) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const theme = useTheme();

  return (
    <>
      <Button
        size="small"
        endIcon={anchorElUser ? <ExpandLess /> : <ExpandMore />}
        onClick={handleOpenUserMenu}
        disableRipple
        sx={{
          color: theme.palette.text.primary,
          display: { md: "flex", xs: "none" },
          textTransform: "none",
        }}
      >
        {user?.email}
      </Button>
      <IconButton
        size="small"
        onClick={handleOpenUserMenu}
        disableRipple
        sx={{
          display: { md: "none", xs: "flex" },
        }}
      >
        <AccountCircleOutlined />
        {anchorElUser ? <ExpandLess /> : <ExpandMore />}
      </IconButton>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        keepMounted
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {additionalOptions &&
          additionalOptions.map((option) => (
            <MenuItem
              key={option.name}
              onClick={() => {
                option.onClick();
                handleCloseUserMenu();
              }}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.name}</ListItemText>
            </MenuItem>
          ))}
        {additionalOptions && additionalOptions.length > 0 && <Divider />}
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => {
              option.onClick();
              handleCloseUserMenu();
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default UserMenu;
