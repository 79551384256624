import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "../input";

function TagNameInput({ name, value, setValue }) {
  const [isAtLimit, setIsAtLimit] = useState(false);
  const [isAllWhiteSpace, setIsAllWhiteSpace] = useState(false);
  useEffect(() => {
    if (value.length > 128) {
      setIsAtLimit(true);
    } else {
      setIsAtLimit(false);
    }
  }, [value, setIsAtLimit]);
  useEffect(() => {
    if (!/\S/.test(value) && value.length > 0) {
      setIsAllWhiteSpace(true);
    } else {
      setIsAllWhiteSpace(false);
    }
  }, [value, setIsAllWhiteSpace]);
  const onNewNameChange = useCallback(
    ({ target: { value: newValue } }) => {
      setValue(newValue);
    },
    [setValue],
  );
  return (
    <>
      <Input
        name={name}
        small
        value={value}
        onChange={onNewNameChange}
        placeholder="Enter tag name"
      />
      {isAtLimit && (
        <span>
          128 character limit exceeded. Please shorten tag name to save.
        </span>
      )}
      {!isAtLimit && isAllWhiteSpace && (
        <span>
          No non-white space characters detected. Please add non-whitespace
          characters.
        </span>
      )}
    </>
  );
}

export function useTagNameInput(tag) {
  const [newName, setNewName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const tagId = useMemo(() => tag?.id, [tag]);
  const reset = useCallback(() => {
    if (tag) {
      setNewName(tag.name);
    } else {
      setNewName("");
    }
  }, [setNewName, tag]);
  useEffect(() => {
    if (
      newName === null ||
      newName.length > 128 ||
      newName.length === 0 ||
      !/\S/.test(newName)
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [newName, setIsValid]);
  const input = useMemo(() => {
    const inputNameSuffix = tagId ? `edit/${tagId}` : "create";
    return (
      <TagNameInput
        name={`/tagmanager/${inputNameSuffix}`}
        value={newName}
        setValue={setNewName}
      />
    );
  }, [newName, setNewName, tagId]);
  return {
    input,
    isValid,
    name: newName,
    reset,
  };
}
