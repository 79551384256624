import { createContext, useContext } from "react";

export const DEFAULT_MEETING_TYPE_STATE = {
  active: false,
  cost: 0,
  id: null,
  inviteTemplates: [],
  name: "",
  tags: [],
};

export const MeetingTypeStateContext = createContext(
  DEFAULT_MEETING_TYPE_STATE,
);

export const useMeetingTypeState = () => useContext(MeetingTypeStateContext);

export const SET_MEETING_TYPE = "SET_MEETING_TYPE";

export const meetingTypeReducer = (state, action) => {
  const { type, payload } =
    typeof action === "function" ? action(state) : action;

  switch (type) {
    case SET_MEETING_TYPE: {
      return { ...state, ...payload.meetingType };
    }
    default: {
      throw new Error(
        `Dispatched type of ${type} does not exist in the meetingTypeReducer`,
      );
    }
  }
};
