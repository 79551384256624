import { Card, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useUserEmailAddress } from "../../auth";
import { OneClickLoading } from "../../componentsV2/OneClickLoading";
import { OneClickLogin } from "../../componentsV2/OneClickLogin";
import { InvalidUrlError } from "../../componentsV2/confirmation";
import { useLocalStorage } from "../../hooks";
import { isLinkFirst } from "../../meetingTypes/invite/props";
import {
  oneClickValidationData,
  useOneClickScheduling,
  useValidateOneClickToken,
} from "../../queries";
import { OneClickBackground } from "../../componentsV2/OneClickBackground";
import { useLogoUrl } from "../../utils/logoUrl";

type RouteProps = {
  schedulingToken?: string;
  header?: string;
  data?: string;
  signature?: string;
};

export default function Page() {
  const { header, data, signature, schedulingToken } = useParams<RouteProps>();
  const token = schedulingToken || `${header}.${data}.${signature}`;

  const {
    data: validationData,
    loading,
    error,
  } = useValidateOneClickToken(token);

  const [, setLogoUrl] = useLogoUrl();
  useEffect(() => {
    if (validationData?.logoURL) {
      setLogoUrl(validationData.logoURL);
    }
  }, [validationData?.logoURL]);

  const isLinkFirstType = isLinkFirst(validationData?.meetingInviteStyle);

  if (loading) {
    return (
      <OneClickBackground>{loading && <CircularProgress />}</OneClickBackground>
    );
  }

  if (error) {
    return (
      <Redirect
        to={{
          pathname: "/error",
          state: {
            refId: error?.response?.body?.traceID,
            url: window.location.href,
          },
        }}
      />
    );
  }

  return (
    <OneClickBackground>
      {isLinkFirstType && (
        <Card
          sx={{
            zIndex: 10,
            px: "50px",
            py: "30px",
            maxWidth: "748px",
            width: "100%",
            minHeight: "440px",
          }}
        >
          <OneClickLinkFirst token={token} validationData={validationData} />
        </Card>
      )}
      {!isLinkFirstType && (
        <Card
          sx={{
            zIndex: 10,
            px: "50px",
            py: "30px",
            maxWidth: "448px",
            width: "100%",
            minHeight: "440px",
          }}
        >
          <OneClickEmail token={token} validationData={validationData} />
        </Card>
      )}
    </OneClickBackground>
  );
}

type OneClickEmailProps = {
  token: string;
  validationData: oneClickValidationData | undefined;
};

function OneClickEmail(props: OneClickEmailProps) {
  const history = useHistory();
  const [storedEmail] = useLocalStorage("VALID_EMAIL_ADDRESS", null);
  const [email, setEmail] = useState(
    useUserEmailAddress() || storedEmail || "",
  );

  const { data, loading, error } = useOneClickScheduling(
    email,
    props.validationData?.valid ? props.token : null,
  );

  if (loading) {
    return <OneClickLoading />;
  }

  if (error?.response?.status === 400) {
    return (
      <InvalidUrlError
        referenceId={error.response?.body?.traceID}
        onOkClicked={() => history.push("/")}
      />
    );
  }

  if (error?.response?.status === 409) {
    return (
      <Redirect
        to={{ pathname: "/pending", state: { url: window.location.href } }}
      />
    );
  }

  if (error) {
    return (
      <Redirect
        to={{
          pathname: "/error",
          state: {
            refId: error?.response?.body?.traceID,
            url: window.location.href,
          },
        }}
      />
    );
  }

  if (data) {
    return (
      <Redirect
        to={{
          pathname: "/success",
          state: { email, url: window.location.href },
        }}
      />
    );
  }

  return (
    <OneClickLogin
      onSubmit={(newEmail: string | null) => {
        newEmail && setEmail(newEmail);
      }}
      // backend will only provide meeting id for "link-first" meeting invite style.
      meetingId={undefined}
      oneClickToken={props.token}
    />
  );
}

type OneClickLinkFirstProps = {
  token: string;
  validationData: oneClickValidationData | undefined;
};
export const OneClickLinkFirst = (props: OneClickLinkFirstProps) => {
  const history = useHistory();
  const [storedEmail] = useLocalStorage("VALID_EMAIL_ADDRESS", null);
  const [email, setEmail] = useState(
    useUserEmailAddress() || storedEmail || "",
  );
  const [meetingStartTime, setMeetingStartTime] = useState<dayjs.Dayjs | null>(
    null,
  );

  const meetingId = props.validationData?.meetingId;
  const startTime = meetingStartTime;

  const linkFirstInviteData = {
    meetingId,
    startTime,
    isValid: () => {
      return !!(meetingId && startTime?.isValid());
    },
  };

  const { data, loading, error } = useOneClickScheduling(
    email,
    props.validationData?.valid ? props.token : null,
    linkFirstInviteData,
  );

  if (loading) {
    return <OneClickLoading />;
  }

  if (error?.response?.status === 400) {
    return (
      <InvalidUrlError
        referenceId={error.response?.body?.traceID}
        onOkClicked={() => history.push("/")}
      />
    );
  }

  if (error?.response?.status === 409) {
    return (
      <Redirect
        to={{ pathname: "/pending", state: { url: window.location.href } }}
      />
    );
  }

  if (error) {
    return (
      <Redirect
        to={{
          pathname: "/error",
          state: {
            refId: error?.response?.body?.traceID,
            url: window.location.href,
          },
        }}
      />
    );
  }

  if (data) {
    return (
      <Redirect
        to={{
          pathname: "/success",
          state: { email, url: window.location.href },
        }}
      />
    );
  }

  return (
    <OneClickLogin
      onSubmit={(
        newEmail: string | null,
        newMeetingStartTime: dayjs.Dayjs | null,
      ) => {
        newEmail && setEmail(newEmail);
        newMeetingStartTime && setMeetingStartTime(newMeetingStartTime);
      }}
      meetingId={meetingId}
      oneClickToken={props.token}
    />
  );
};
