import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from "react";
import { ConfirmationDialog } from "../componentsV2/dialogs";

const ConfirmationDialogContext = createContext<
  (title: string, message: string, onConfirmation: () => void) => void
>(() => undefined);

export function useConfirmationDialog() {
  return useContext(ConfirmationDialogContext);
}

export function ConfirmationDialogProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [confirmation, setConfirmation] = useState<{
    title: string;
    onConfirmation: () => void;
    message: string;
  } | null>(null);

  const handleClose = useCallback(() => {
    setConfirmation(null);
  }, [setConfirmation]);

  const handleConfirmation = useCallback(() => {
    if (confirmation?.onConfirmation) confirmation.onConfirmation();
    setConfirmation(null);
  }, [confirmation]);

  return (
    <ConfirmationDialogContext.Provider
      value={useCallback(
        (title: string, message: string, onConfirmation: () => void) =>
          setConfirmation({ message, onConfirmation, title }),
        [setConfirmation],
      )}
    >
      {children}

      {confirmation && (
        <ConfirmationDialog
          open={confirmation !== null}
          onClose={handleClose}
          title={confirmation.title}
          message={confirmation.message}
          onConfirm={handleConfirmation}
        />
      )}
    </ConfirmationDialogContext.Provider>
  );
}
