import React, { useCallback, useState } from "react";
import ReactSelectAsyncSelect from "react-select/async";

import { useDebounce } from "../utils/debounce";
import style from "./select.module.scss";

const DEFAULT_DEBOUNCE_TIME = 300;

export default function AsyncSelect({
  components,
  closeMenuOnScroll = undefined,
  customTheme,
  dataCy = null,
  dataGtm = null,
  disabled,
  onMenuClose,
  onMenuOpen,
  onChange,
  defaultOptions,
  load,
  menuIsOpen,
  menuPortalTarget,
  isMulti,
  isLoading,
  name = null,
  noOptionsMessage,
  toOption,
  placeholder,
  value,
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  const [, setPreviousPromise] = useState(null);
  const loadImpl = useCallback(
    async (text, resolve) => {
      const {
        data: { data },
        error,
      } = await load(text);
      if (error) {
        resolve([]);
        return;
      }
      resolve(data.map(toOption));
      setPreviousPromise(null);
    },
    [load, toOption],
  );

  const debouncedLoadImpl = useDebounce(loadImpl, DEFAULT_DEBOUNCE_TIME);
  // To debounce the load return a promise that resolves to the debounced call to debounceLoadImpl
  const debouncedLoad = useCallback(
    (text) => {
      return new Promise(async (resolve, reject) => {
        setPreviousPromise((prev) => {
          if (prev !== null) {
            // Reject the previous promise, AsyncCreatableSelect is no longer referencing it
            prev();
          }
          return reject;
        });
        await debouncedLoadImpl(text, resolve);
      });
    },
    [debouncedLoadImpl],
  );
  // AsyncCreatableSelect takes a new promise each time text is entered
  const loadOptions = useCallback(
    async (text) => {
      return debouncedLoad(text);
    },
    [debouncedLoad],
  );
  return (
    <ReactSelectAsyncSelect
      aria-label={name}
      className={style.selectInputSize}
      closeMenuOnScroll={closeMenuOnScroll}
      components={components}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.value}
      isDisabled={disabled}
      isLoading={isLoading}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      menuPortalTarget={menuPortalTarget}
      name={name}
      noOptionsMessage={noOptionsMessage}
      onChange={onChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      placeholder={placeholder}
      styles={customTheme}
      value={value}
      {...dataProps}
    />
  );
}
