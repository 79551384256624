import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { mdiClose } from "@mdi/js";
import { Stack, Typography } from "@mui/material";

import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import { useRemindersRepository } from "../../../repository/reminders";
import { useMeeting } from "../state";
import { Spinner } from "../../../components/Spinner";
import { confirm } from "../../../components/confirmModal/ConfirmModal";
import { SCHEDULED_STAGE } from "../../props";
import SelectReminderTime from "./SelectReminderTime";
import { ButtonContainer, ButtonIcon } from "../../../components/button";
import style from "./style.module.scss";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";

function ReminderBanner() {
  const { getMeetingReminder, removeMeetingReminder, updateMeetingReminder } =
    useRemindersRepository();
  const { addError } = useGeneralNotifications();
  const [meeting] = useMeeting();
  const [currentReminder, setCurrentReminder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSelectingTime, setIsSelectingTime] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  const reminderConfigured = useMemo(() => {
    if (!meeting?.id) {
      return false;
    }

    if (!meeting?.meetingType?.properties?.meetingReminder?.enabled) {
      return false;
    }

    return true;
  }, [meeting]);

  const openCalendar = useCallback(() => {
    setIsSelectingTime(true);
  }, []);

  const closeCalendar = useCallback(() => {
    setIsSelectingTime(false);
  }, []);

  const fetchReminder = useCallback(async () => {
    if (!meeting?.id) {
      return;
    }

    setLoading(true);

    try {
      const response = await getMeetingReminder({
        meetingId: meeting.id,
      });

      if (dayjs(response.executionTime).unix() < 1) {
        setLoading(false);
        return;
      }

      setCurrentReminder(response);
    } catch {
      addError("There was a problem retrieivng reminder information");
    }

    setLoading(false);
  }, [addError, getMeetingReminder, meeting]);

  const updateReminder = useCallback(async () => {
    if (!meeting?.id) {
      return;
    }

    if (!reminderConfigured) {
      return;
    }

    if (selectedTime.isAfter(meeting.startTime)) {
      addError("Reminder cannot be scheduled for after meeting start time");
      return;
    }

    if (selectedTime.isBefore(dayjs())) {
      addError("Reminder cannot be scheduled for before right now");
      return;
    }

    try {
      await confirm({
        title: "Are you sure you would like to schedule this reminder?",
      });
    } catch {
      return;
    }

    setLoading(true);

    try {
      await updateMeetingReminder({
        executionTime: selectedTime.format(`YYYY-MM-DDTHH:mm:ssZ`),
        meetingId: meeting.id,
      });
    } catch (error) {
      try {
        const errList = JSON.parse(error.message).errors;
        if (errList?.length > 0) {
          errList.forEach((err) => {
            addError(err);
          });
        }
      } catch {
        addError("There was a problem scheduling the reminder");
      }
    }

    closeCalendar();
    fetchReminder();
    setLoading(false);
  }, [
    addError,
    closeCalendar,
    fetchReminder,
    meeting,
    reminderConfigured,
    selectedTime,
    updateMeetingReminder,
  ]);

  const removeReminder = useCallback(async () => {
    if (!meeting?.id) {
      return;
    }

    try {
      await confirm({
        title: "Are you sure you would like to remove this reminder?",
      });
    } catch {
      return;
    }

    setLoading(true);

    try {
      await removeMeetingReminder({ meetingId: meeting.id });
      setCurrentReminder(null);
    } catch {
      addError("There was a problem removing meeting reminder");
    }

    setLoading(false);
  }, [addError, meeting, removeMeetingReminder]);

  useEffect(() => {
    fetchReminder();
  }, [fetchReminder]);

  return (
    <div className={style.header}>
      {isSelectingTime && (
        <div className={style.calendar}>
          <ButtonContainer
            transparent
            small
            onClick={closeCalendar}
            className={style.close}
          >
            <ButtonIcon path={mdiClose} size={1} />
          </ButtonContainer>
          <SelectReminderTime
            reminderTime={selectedTime}
            setReminderTime={setSelectedTime}
            meetingStartTime={meeting.startTime}
          />

          <div className={style.schedule_button}>
            <PrimaryButton
              onClick={updateReminder}
              disabled={selectedTime === null}
            >
              Schedule Reminder
            </PrimaryButton>
          </div>
        </div>
      )}
      {loading && <Spinner />}
      {currentReminder && reminderConfigured && (
        <Stack
          className={style.text}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="inherit">
            {`Reminder scheduled for 
          ${dayjs(currentReminder.executionTime).format(
            `dddd, MMM D, YYYY [at] hh:mma`,
          )}`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PrimaryButton nowrap onClick={openCalendar} size="small">
              Change
            </PrimaryButton>
            <PrimaryButton nowrap onClick={removeReminder} size="small">
              Remove
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {currentReminder === null &&
        reminderConfigured &&
        meeting.status === SCHEDULED_STAGE && (
          <div className={style.add_reminder_button}>
            <PrimaryButton onClick={openCalendar} size="small" nowrap>
              Add Meeting Reminder
            </PrimaryButton>
          </div>
        )}
    </div>
  );
}

export default ReminderBanner;
