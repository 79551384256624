import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useOrgReporting, useOrgReportingCompanyWide } from "../../../queries";
import { OrganizationSelectInput } from "../../../componentsV2/inputs";

import { Loading } from "../../../componentsV2/Loading";
import ErrorPage from "src/pages/ErrorPage";
import { Pagination } from "../../../componentsV2/Pagination";
import Table from "../../../componentsV2/tables/Table";
import { TableSort } from "../../../componentsV2/Table";
import { SingleValue } from "src/componentsV2/reporting/SingleValue";
import { MetricsGrid } from "src/componentsV2/reporting/MetricsGrid";
import { PageLayout } from "../../../componentsV2/PageLayout";
import PageHeader from "../../../componentsV2/PageHeader";

const PAGE_SIZE = 200;

function SelectedMetrics({ orgCount }: { orgCount: number }) {
  const { data } = useOrgReportingCompanyWide();

  if (!data) {
    return (
      <MetricsGrid>
        {[1, 2, 3, 4].map((i) => (
          <Grid item xs={1} key={i}>
            <Skeleton
              variant="rectangular"
              sx={{ height: "180px", width: "100%" }}
            />
          </Grid>
        ))}
      </MetricsGrid>
    );
  }

  return (
    <MetricsGrid>
      <SingleValue
        title="Total Orgs"
        help="This is the total number of organizations in the platform"
      >
        {orgCount}
      </SingleValue>
      <SingleValue
        title="Active Orgs"
        help="This is the total number of active organizations in the platform"
      >
        N/A
      </SingleValue>
      <SingleValue
        title="Total Macs"
        help="This is the total number of MACs consumed since inception"
      >
        {data.totals.macsConsumed}
      </SingleValue>
      <SingleValue
        title="Macs Per Day"
        help="Average number of MACs consumed per day"
      >
        {data.averages.macsUsedPerDay}
      </SingleValue>
    </MetricsGrid>
  );
}

export default function Page() {
  const history = useHistory();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const [sort, setSort] = useState<TableSort>({
    columnId: "accountName",
    order: "asc",
  });

  const { data, error } = useOrgReporting(PAGE_SIZE, 0);

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (!data) {
    return <Loading />;
  }

  const reports = data?.reports.sort((a, b) => {
    switch (sort.columnId) {
      default:
        return 0;
      case "accountName": {
        return a.accountName.localeCompare(b.accountName);
      }
      case "userCount": {
        return a.totals.users - b.totals.users;
      }
      case "expiredUserCount": {
        return a.expiredUsers - b.expiredUsers;
      }
      case "macsConsumed": {
        return a.totals.macsConsumed - b.totals.macsConsumed;
      }
    }
  });

  if (sort.order === "desc") {
    reports.reverse();
  }

  return (
    <PageLayout
      title="Reporting"
      breadcrumbs={[
        {
          name: "Global",
          route: "/admin/reporting",
        },
      ]}
    >
      <Stack spacing={2}>
        <Typography>
          Select an organization from the drop down below to view org specific
          data.
        </Typography>
        <OrganizationSelectInput
          value={null}
          onChange={(org) =>
            org && history.push(`/admin/reporting/${org.name}`)
          }
        />
        <Divider>
          <Typography variant="h6">Global Data</Typography>
        </Divider>
        <SelectedMetrics orgCount={data.orgCount} />
        <Paper>
          <Table
            loading={!data}
            sort={sort}
            onSortChange={setSort}
            columns={[
              {
                component: (row) => <>{row.accountName}</>,
                id: "accountName",
                label: "Account Name",
                sortable: true,
              },
              {
                component: (row) => <>{row.totals.users}</>,
                id: "userCount",
                label: "User Count",
                sortable: true,
                visible: sm,
              },
              {
                component: (row) => <>{row.expiredUsers}</>,
                id: "expiredUserCount",
                label: "Expired User Count",
                sortable: true,
                visible: md,
              },
              {
                component: (row) => <>{row.totals.importChannels}</>,
                id: "importChannelsActive",
                label: "Import Channels Active",
                visible: md,
              },
              {
                component: (row) => <>{row.totals.updateChannels}</>,
                id: "updateChannelsActive",
                label: "Update Channels Active",
                visible: md,
              },
              {
                component: (row) => <>{row.totals.macsConsumed}</>,
                id: "macsConsumed",
                label: "Macs Consumed",
                sortable: true,
              },
              {
                component: (row) => <>{row.totals.periodData.rolling}</>,
                id: "averageMacsConsumedRolling",
                label: "Macs Consumed Past 14 Days Rolling",
              },
              {
                component: (row) => (
                  <>
                    {row.totals.periodData.current}{" "}
                    {row.totals.periodData.current >
                    row.totals.periodData.previous
                      ? "up"
                      : row.totals.periodData.current <
                          row.totals.periodData.previous
                        ? "down"
                        : undefined}
                  </>
                ),
                id: "averageMacsConsumedStatic",
                label: "Macs Consumed Current 14 Days Static",
              },
              {
                component: (row) => <>{row.macsUsedPerDay}</>,
                id: "averageMacsPerDay",
                label: "Average Macs/Day (Lifetime)",
              },
              {
                component: (row) => <>{row.totals.meetingsOnCalendar}</>,
                id: "meetingsOnCalendar",
                label: "Meetings On Calendar",
                visible: lg,
              },
              {
                component: (row) => (
                  <>{row.totals.periodData.meetingsOnCalendarRolling}</>
                ),
                id: "meetingOnCalendarRolling",
                label: "Meetings On Calendar Past 14 Days Rolling",
                visible: lg,
              },
              {
                component: (row) => (
                  <>{row.totals.periodData.meetingsOnCalendarCurrent}</>
                ),
                id: "meetingOnCalendarStatic",
                label: "Meetings On Calendar Current 14 Days Static",
                visible: false,
              },
              {
                component: (row) => (
                  <>
                    <div>{`Hi: ${row.meetingTypePerformances.high.acceptRate}%`}</div>
                    <div>{`Lo: ${row.meetingTypePerformances.low.acceptRate}%`}</div>
                  </>
                ),
                id: "meetingTemplatePerformance",
                label: "Meeting Template Performance",
                visible: xl,
              },
              {
                component: (row) => <>{row.totals.instancesProvisioned}</>,
                id: "meetingsProvisioned",
                label: "Meetings Provisioned",
                visible: lg,
              },
              {
                component: (row) => <>{row.totals.instancesInFinalState}</>,
                id: "meetingsInFinalState",
                label: "Meetings In Final State",
                visible: xl,
              },
              {
                component: (row) => <>{row.acceptRate}%</>,
                id: "acceptRate",
                label: "Accept Rate (Final Stage)",
                visible: xl,
              },
            ]}
            data={reports}
          />
          {!xl && (
            <Typography
              variant="caption"
              align="right"
              fontWeight="bold"
              sx={{ p: 1, width: "100%" }}
            >
              * Some columns are hidden. View this page on a larger screen to
              see all columns.
            </Typography>
          )}
          <Pagination
            pages={Math.ceil(data.orgCount / PAGE_SIZE)}
            currentPage={1}
            onPageChange={() => null}
          />
        </Paper>
      </Stack>
    </PageLayout>
  );
}
