import { useMemo } from "react";

// Returns the raw string value of a configuration setting or the supplied
// default value if undefined.
export function useConfigurationValue(
  key: string,
  defaultValue: string,
): string {
  return useMemo(() => {
    // @ts-ignore config does not exists on window warning
    const config = window?.config || {};

    const value = config[key] || defaultValue;

    return value;
  }, [key, defaultValue]);
}
