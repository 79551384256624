import React, { useCallback, useState } from "react";
import cx from "classnames";

import style from "./checkbox.module.scss";

export function Checkbox({
  // TODO: Throw error if name not specified
  name = null,
  disabled: isDisabled,
  checked,
  onChange,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const onCheckboxChange = useCallback(
    ({ target: { checked: value } }) => {
      onChange(value);
    },
    [onChange],
  );
  const onMouseOver = useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);
  const onMouseOut = useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);

  return (
    <div
      className={style.checkbox__container}
      onBlur={onMouseOut}
      onFocus={onMouseOver}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {!checked && (
        <div
          className={cx(style.checkbox__plate, {
            [style["checkbox__plate--highlight"]]: isHovering && !isDisabled,
          })}
        />
      )}
      {checked && <div className={style.checkbox__check} />}
      <input
        aria-label={name}
        name={name}
        className={style.checkbox__input}
        disabled={isDisabled}
        checked={checked}
        indeterminate="false"
        onChange={onCheckboxChange}
        type="checkbox"
      />
    </div>
  );
}
