import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { mdiApi, mdiAccountAlertOutline } from "@mdi/js";
import Card from "../components/card/Card";
import { confirm } from "../components/confirmModal/ConfirmModal";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import {
  ENVIRONMENT_URLS,
  INTEGRATION_TYPES,
  INTEGRATION_TYPES_LABELS,
} from "./props";
import { SalesforceIntegrationCard } from "./salesforce/SalesforceIntegrationCard";
import { HubspotIntegrationCard } from "./hubspot/HubspotIntegrationCard";
import { DynamicsIntegrationCard } from "./dynamics/DynamicsIntegrationCard";
import useIntegrationsRepository from "../repository/integrations";
import style from "./style.module.scss";
import { OutreachIntegrationCard } from "./outreach/OutreachIntegrationCard";

export function Integrations() {
  const {
    connectOrgIntegration,
    connectOrgIntegrationSandbox,
    disconnectOrgIntegration,
    getIntegrations,
  } = useIntegrationsRepository();
  const [salesforce, setSalesforce] = useState(null);
  const [hubspot, setHubspot] = useState(null);
  const [dynamics, setDynamics] = useState(null);
  const [outreach, setOutreach] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { addGeneralNotification } = useGeneralNotifications();

  // handleConnect handles both connecting for the first time and reconnecting
  const handleConnect = useCallback(
    async (integrationType, integrationId = 0, url = null) => {
      try {
        const response = await connectOrgIntegration(
          integrationType,
          integrationId,
          url,
        );

        if (response && response.url) {
          window.location.href = response.url;
        }
      } catch (err) {
        setError(
          `Failed to connect to ${INTEGRATION_TYPES_LABELS[integrationType]}`,
        );
      }
    },
    [connectOrgIntegration],
  );

  const handleTest = useCallback(
    async (integrationType) => {
      try {
        const response = await connectOrgIntegrationSandbox(integrationType);

        if (response && response.url) {
          window.location.href = response.url;
        }
      } catch (error) {
        setError(`Connection test for ${integrationType} failed`);
      }
    },
    [connectOrgIntegrationSandbox],
  );

  const handleDisconnect = useCallback(
    async (integrationType, intergrationId, setState) => {
      try {
        await confirm({
          title: (
            <div>
              You are about to disconnect the following integration:
              <div>{`${INTEGRATION_TYPES_LABELS[integrationType]}*`}</div>
              <div className={style.warning_message}>
                *WARNING: All associated channels with this integration will
                also be deleted.
              </div>
              <div>Would you like to proceed?</div>
            </div>
          ),
        });
      } catch (error) {
        return null;
      }

      try {
        const response = await disconnectOrgIntegration(intergrationId);

        if (response) {
          setState(null);
        }
      } catch (error) {
        setError(
          `${INTEGRATION_TYPES_LABELS[integrationType]} disconnect failed`,
        );
      }
    },
    [disconnectOrgIntegration],
  );

  const fetchIntegrations = useCallback(async () => {
    setIsLoading(true);

    const response = await getIntegrations();

    if (response) {
      const salesforceFound = response.find(
        (integration) =>
          integration.name === INTEGRATION_TYPES.SALESFORCE ||
          integration.name === INTEGRATION_TYPES.SALESFORCE_TEST,
      );

      const hubspotFound = response.find(
        (integration) => integration.name === INTEGRATION_TYPES.HUBSPOT,
      );

      const dynamicsFound = response.find(
        (integration) => integration.name === INTEGRATION_TYPES.DYNAMICS,
      );

      const outreachFound = response.find(
        (integration) => integration.name === INTEGRATION_TYPES.OUTREACH,
      );

      if (salesforceFound) {
        setSalesforce(salesforceFound);
      }

      if (hubspotFound) {
        setHubspot(hubspotFound);
      }

      if (dynamicsFound) {
        setDynamics(dynamicsFound);
      }

      if (outreachFound) {
        setOutreach(outreachFound);
      }

      setIsLoading(false);
    }
  }, [getIntegrations]);

  const handleOnSalesforceConnect = useCallback(
    (id = 0) => {
      handleConnect(INTEGRATION_TYPES.SALESFORCE, id);
    },
    [handleConnect],
  );

  const handleOnSalesforceSandboxConnect = useCallback(() => {
    handleTest(INTEGRATION_TYPES.SALESFORCE);
  }, [handleTest]);

  const handleOnSalesforceDisconnect = useCallback(() => {
    handleDisconnect(
      INTEGRATION_TYPES.SALESFORCE,
      salesforce.id,
      setSalesforce,
    );
  }, [handleDisconnect, salesforce]);

  const handleOnHubspotConnect = useCallback(
    (id = 0) => {
      handleConnect(INTEGRATION_TYPES.HUBSPOT, id);
    },
    [handleConnect],
  );

  const handleOnHubspotDisconnect = useCallback(() => {
    handleDisconnect(INTEGRATION_TYPES.HUBSPOT, hubspot.id, setHubspot);
  }, [handleDisconnect, hubspot]);

  const handleOnDynamicsConnect = useCallback(
    (id = 0, url) => {
      handleConnect(INTEGRATION_TYPES.DYNAMICS, id, url);
    },
    [handleConnect],
  );

  const handleOnDynamicsDisconnect = useCallback(() => {
    handleDisconnect(INTEGRATION_TYPES.DYNAMICS, dynamics.id, setDynamics);
  }, [handleDisconnect, dynamics]);

  const handleOnOutreachConnect = useCallback(
    (id = 0) => {
      handleConnect(INTEGRATION_TYPES.OUTREACH, id);
    },
    [handleConnect],
  );

  const handleOnOutreachDisconnect = useCallback(() => {
    handleDisconnect(INTEGRATION_TYPES.OUTREACH, outreach.id, setOutreach);
  }, [handleDisconnect, outreach]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  useEffect(() => {
    if (error) {
      addGeneralNotification(error, mdiAccountAlertOutline);
      setError(null);
    }
  }, [error, addGeneralNotification]);

  return (
    <>
      <div className="container is--fluid">
        <div className="row">
          <div className="col-12">
            <Card
              icon={mdiApi}
              header="integrations"
              className={style.integrations}
            >
              <div className={cx(style.integrationsContainer, "row")}>
                <SalesforceIntegrationCard
                  enabled
                  onConnect={handleOnSalesforceConnect}
                  onSandboxConnect={handleOnSalesforceSandboxConnect}
                  onDisconnect={handleOnSalesforceDisconnect}
                  integration={salesforce}
                  isLoading={isLoading}
                  onFilterChange={(i) => {
                    setSalesforce(i);
                  }}
                />

                <HubspotIntegrationCard
                  enabled
                  onConnect={handleOnHubspotConnect}
                  onDisconnect={handleOnHubspotDisconnect}
                  integration={hubspot}
                  isLoading={isLoading}
                />

                <DynamicsIntegrationCard
                  enabled
                  onConnect={handleOnDynamicsConnect}
                  onDisconnect={handleOnDynamicsDisconnect}
                  integration={dynamics}
                  isLoading={isLoading}
                  testURL={ENVIRONMENT_URLS[INTEGRATION_TYPES.DYNAMICS]}
                />

                <OutreachIntegrationCard
                  enabled
                  onConnect={handleOnOutreachConnect}
                  onDisconnect={handleOnOutreachDisconnect}
                  integration={outreach}
                  isLoading={isLoading}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
