import { useMaxDayRange } from "src/features/useMaxDayRange";
import { InviteStyle } from "../meetingTypes/invite/props";
import { Tag } from "./Tag";
import { WeekSchedule } from "./Availability";

export const MeetingDefinitionMaxDayRange = 90;
export const MeetingDefinitionDefaultDayRange = 30;

export function useMaxDaysAllowed() {
  return useMaxDayRange()
    ? MeetingDefinitionMaxDayRange
    : MeetingDefinitionDefaultDayRange;
}

export interface EmailTemplate {
  id?: number;
  title: string;
  body: string;
  order: number;
}
export interface InviteTemplate {
  id: number;
  title: string;
  body: string;
  location: string;
  name?: string;
}

export interface MeetingDefinitionTeamMember {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
}

export interface MeetingDefinition {
  id: number;
  name: string;
  team: {
    id: number;
    name: string;
  };
  inviteStyle: InviteStyle;
  cost: number;
  routing: string;
  routingJustMe: boolean;
  distribution: string;
  buffer_duration_mins: number;
  active: boolean;
  emailTemplates: EmailTemplate[];
  inviteTemplates: InviteTemplate[];
  routing_logic?: {
    metadata?: {
      subtypeDetails: {
        field: "routing_field" | "logic_field";
        subtype: "email";
      };
    };
    custom: Record<
      string,
      {
        enabled: boolean;
        mapping_logic: Array<{
          field: string;
          operator: string;
          value: string;
        }> | null;
      }
    >;
    sequential: {
      order: number[] | null;
      last_processed?: number;
    };
  };
  properties: {
    bcc: string;
    gap: number;
    dayRange: {
      to: number;
      from: number;
    };
    hasMeetingLinkProvider?: boolean;
    duration: number;
    sameDayBuffer: number;
    meetingReminder: {
      body: string;
      days: number;
      hours: number;
      title: string;
      enabled: boolean;
      minutes: number;
    };
    cleanDeclineRule: {
      action?: {
        meta: {
          body: string;
          title: string;
        };
        type: string;
      };
      enabled: boolean;
      trigger?: {
        field: string;
        object: string;
        values: string[];
      };
    };
    routeOnActivation: boolean;
  };
  tags: Tag[];
  tagAutomation: {
    tagContacts: [];
    tagMeetings: [];
    tagUsers: [];
  };
  timeNegotiationEnabled: boolean;
  oooProcessingEnabled: boolean;
  creationData: CreationData;
  schedulingUrls:
    | [
        {
          url: string;
          meetingsCreated: number;
          createdAt: string;
          updatedAt: string;
        },
      ]
    | null;
  unsubscribeTemplate?: {
    body: string;
    enabled: boolean;
    hyperlinkText: string;
  };
  schedulingMode: SchedulingMode;
  weekSchedule: WeekSchedule;
  timezone: string;
}

export type SchedulingMode = "default" | "custom";

export const SCHEDULING_MODES = {
  CUSTOM: "custom",
  DEFAULT: "default",
};

export function isCustomSchedulingMode(schedulingMode: SchedulingMode) {
  return schedulingMode === SCHEDULING_MODES.CUSTOM;
}

export interface CreationData {
  creatorData: {
    userId: number;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userRole: number;
    adminEmail: string;
    adminId: number;
  };
  createdAt: string;
}
