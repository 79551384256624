import { validateDynamicFields } from "../utils/helpers";
import { SYSTEM_FIELDS } from "./useDynamicVariableMappings";

class ValueMissingError extends Error {}
class InvalidDynamicFieldError extends Error {}

function containsSignatureVariable(text = "") {
  if (!text || text.length < 1) {
    return false;
  }

  return text.includes(SYSTEM_FIELDS.HOST_SIGNATURE);
}

function bodyErrorMessage(error) {
  if (error !== null) {
    return error.message;
  }
  return null;
}

function bodyCompletion(error) {
  if (error !== null) {
    return 0;
  }
  return 100;
}

function validateInviteBody(body) {
  if (body === null || body?.length === 0) {
    return new ValueMissingError("Body is empty");
  }
  const filteredBody = body.replace(/[^a-zA-Z0-9]/g, "");
  if (filteredBody?.length === 0) {
    return new ValueMissingError("Body is empty");
  }
  const error = validateDynamicFields(body);

  if (error) {
    return new InvalidDynamicFieldError("Invalid dynamic fields in Body.");
  }

  // special case, prevent signature dynamic variable
  if (containsSignatureVariable(body)) {
    return new InvalidDynamicFieldError(
      "Signature dynamic variable not allowed in Invite",
    );
  }

  return null;
}

function locationErrorMessage(error) {
  if (error !== null) {
    return error.message;
  }
  return null;
}

function locationCompletion(error) {
  if (error !== null) {
    return 0;
  }
  return 100;
}

function validateLocation(location) {
  if (location === null || location?.length === 0) {
    return new ValueMissingError("Location is empty");
  }
  const error = validateDynamicFields(location);
  if (error) {
    return new InvalidDynamicFieldError("Invalid dynamic fields in Location.");
  }

  // special case, prevent signature dynamic variable
  if (containsSignatureVariable(location)) {
    return new InvalidDynamicFieldError(
      "Signature dynamic variable not allowed in Invite",
    );
  }

  return null;
}

function titleErrorMessage(error) {
  if (error !== null) {
    return error.message;
  }
}

function titleCompletion(error) {
  if (error !== null) {
    return 0;
  }
  return 100;
}

function validateTitle(title) {
  if (title === null || title?.length === 0) {
    return new ValueMissingError("Title is empty.");
  }
  if (title?.length < 5) {
    return new ValueMissingError("Title must be 5 characters minimum");
  }
  const error = validateDynamicFields(title);
  if (error) {
    return new InvalidDynamicFieldError("Invalid dynamic fields in Title.");
  }

  // special case, prevent signature dynamic variable
  if (containsSignatureVariable(title)) {
    return new InvalidDynamicFieldError(
      "Signature dynamic variable not allowed in Invite",
    );
  }

  return null;
}

function inviteValidationSummary({ title, location, body }) {
  const complete = [
    title.complete * 0.25,
    location.complete * 0.25,
    body.complete * 0.5,
  ].reduce((acc, portion) => {
    return acc + portion;
  }, 0);
  if (title.error) {
    return {
      complete,
      description: title.error,
    };
  }
  if (location.error) {
    return {
      complete,
      description: location.error,
    };
  }
  if (body.error) {
    return {
      complete,
      description: body.error,
    };
  }
  return { complete, description: "All Good!" };
}

// TODO(Matt): Make the interfaces of all the functions in this file similar
export function validateInvite(invite) {
  const locationValidationError = validateLocation(invite?.location || null);
  const bodyValidationError = validateInviteBody(invite?.body || null);
  const titleValidationError = validateTitle(invite?.title || null);
  const validation = {
    location: {
      complete: locationCompletion(locationValidationError),
      error: locationErrorMessage(locationValidationError),
    },
    body: {
      complete: bodyCompletion(bodyValidationError),
      error: bodyErrorMessage(bodyValidationError),
    },
    title: {
      complete: titleCompletion(titleValidationError),
      error: titleErrorMessage(titleValidationError),
    },
  };
  const summary = inviteValidationSummary(validation);
  return {
    summary,
    validation,
  };
}

// TODO(Matt): Does this belong somewhere more broad? like context?
function getInvite(meetingType) {
  const inviteTemplates = meetingType?.inviteTemplates;
  if (Array.isArray(inviteTemplates) && inviteTemplates.length > 0) {
    return inviteTemplates[0];
  }
  return null;
}

function validateName(meetingType) {
  const name = meetingType?.name || null;
  if (name === null || name.length === 0) {
    return {
      error: "Name not filled in!",
      summary: {
        complete: 0,
      },
    };
  }
  return {
    error: "All Good!",
    summary: {
      complete: 100,
    },
  };
}

function validateRouting(meetingType) {
  const { distribution, routing } = meetingType;
  const isDistributionValid = distribution !== null;
  const isRoutingValid = routing !== null;
  const distributionCompletion = isDistributionValid ? 100 : 0;
  const routingCompletion = isRoutingValid ? 100 : 0;
  // const isDistributionValid = distribution !== null;
  return {
    summary: {
      complete: 0.5 * distributionCompletion + 0.5 * routingCompletion,
    },
    validation: {
      distribution: {
        complete: distributionCompletion,
        error: !isDistributionValid
          ? ValueMissingError("No distribution found.")
          : null,
      },
      routing: {
        complete: routingCompletion,
        error: routing === null ? ValueMissingError("No routing found.") : null,
      },
    },
  };
}

function validateSubject(subject) {
  if (subject?.length === 0) {
    return new ValueMissingError("Subject is empty");
  }
  if (subject?.length < 5) {
    return new ValueMissingError("Subject is must be 5 characters minimum");
  }
  const error = validateDynamicFields(subject);
  if (error) {
    return new InvalidDynamicFieldError("Invalid dynamic fields in Subject.");
  }

  // special case, prevent signature dynamic variable
  if (containsSignatureVariable(subject)) {
    return new InvalidDynamicFieldError(
      "Signature dynamic variable not allowed in Email Subject",
    );
  }

  return null;
}

function validateBody(body) {
  if (body?.length === 0) {
    return new ValueMissingError("Body is empty");
  }
  const error = validateDynamicFields(body);
  if (error) {
    return new InvalidDynamicFieldError("Invalid dynamic fields in Body.");
  }
  return null;
}

function summarizeEmailValidations(validations) {
  const total = validations.length;
  const complete = Math.ceil(
    validations.reduce((acc, validation) => {
      return acc + (1 / total) * validation.summary.complete;
    }, 0),
  );
  let description = "All Good!";
  for (let i = 0; i < validations.length; i += 1) {
    const validation = validations[i];
    if (validation.error !== null) {
      description = validation.error;
      break;
    }
  }
  return { complete, description };
}

const HTML_TAG_REGEX = /(<([^>]+)>)/gi;

export function validateEmail(email) {
  if (email === null) {
    return null;
  }
  let description = "All Good!";
  let strippedBody = null;
  if (typeof email?.body === "string") {
    strippedBody = email?.body.replace(HTML_TAG_REGEX, "");
  }
  const bodyError = validateBody(strippedBody);
  const subjectError = validateSubject(email?.title);
  const body = {
    complete: bodyError === null ? 100 : 0,
  };
  const subject = {
    complete: subjectError === null ? 100 : 0,
  };
  if (bodyError !== null) {
    description = bodyError.message;
    body.error = bodyError.message;
  } else {
    body.error = null;
  }
  if (subjectError !== null) {
    description = subjectError.message;
    subject.error = subjectError.message;
  } else {
    subject.error = null;
  }
  return {
    body,
    subject,
    summary: {
      complete: 0.5 * body.complete + 0.5 * subject.complete,
      description,
    },
  };
}

function validateEmails(meetingType) {
  const { emailTemplates } = meetingType;
  if (!Array.isArray(emailTemplates) || emailTemplates.length === 0) {
    return {
      error: "No email templates found!",
      summary: {
        complete: 0,
      },
    };
  }
  const emails = emailTemplates.map(validateEmail);
  return {
    emails,
    summary: summarizeEmailValidations(emails),
  };
}

function validateReminder(meetingType) {
  const meetingReminder = meetingType?.properties?.meetingReminder || {};
  const { body = "", enabled = false, title = "" } = meetingReminder;
  const meetingReminderEmail = {
    body,
    title,
  };
  const email = validateEmail(meetingReminderEmail);
  if (enabled) {
    return {
      email,
      summary: {
        complete: email.summary.complete,
      },
    };
  }
  return null;
}

function validateDecline(meetingType) {
  const declineEmail =
    meetingType?.properties?.cleanDeclineRule?.action?.meta || null;
  const email = validateEmail(declineEmail);
  if (email === null) {
    return null;
  }
  if (email !== null) {
    return {
      email,
      summary: {
        complete: email.summary.complete,
      },
    };
  }
  return null;
}

// TODO(Matt): Make validation objects consistent
export default function validateMeetingType({ meetingType }) {
  const name = validateName(meetingType);
  const emails = validateEmails(meetingType);
  const invite = validateInvite(getInvite(meetingType));
  const routing = validateRouting(meetingType);
  const reminder = validateReminder(meetingType);
  const decline = validateDecline(meetingType);
  const validations = [
    name.summary.complete,
    emails.summary.complete,
    invite.summary.complete,
    routing.summary.complete,
  ];
  let start = 63.63;
  if (reminder) {
    validations.push(reminder.summary.complete);
    start -= 9.1;
  }
  if (decline) {
    validations.push(decline.summary.complete);
    start -= 9.1;
  }
  const complete = Math.floor(
    validations.reduce((acc, value) => {
      return acc + value * 0.091;
    }, start),
  );
  const summary = {
    complete: complete > 100 ? 100 : complete,
  };
  return {
    decline,
    emails,
    invite,
    name,
    reminder,
    routing,
    summary,
  };
}
