import { useCallback, useEffect, useState } from "react";

import { useIsDefaultUser } from "../auth";

import useCreateNewMeeting from "./multiGuestTable/newMeetingTableRow/useCreateNewMeeting";
import { getUserDetails } from "src/utils/jwtToken";

export default function useNewMeeting({ onClose, onCreate = null }) {
  const isDefaultUser = useIsDefaultUser();
  const [meetingType, setMeetingType] = useState(null);
  const [guests, setGuests] = useState([]);
  const [host, setHost] = useState(null);
  const [tags, setTags] = useState([]);
  const loggedInUser = getUserDetails();
  useEffect(() => {
    if (isDefaultUser) {
      setHost(loggedInUser);
    }
  }, [isDefaultUser, loggedInUser, setHost]);
  const close = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setMeetingType(null);
    setGuests(null);
    if (!isDefaultUser) {
      setHost(null);
    }
  }, [isDefaultUser, onClose]);
  const [isSaving, createNewMeeting] = useCreateNewMeeting({
    onClose: close,
    onCreate,
  });
  const setContactAndSave = useCallback(
    async (value) => {
      setGuests([value]);
      await createNewMeeting({
        contact: value,
        meetingType,
        user: host,
      });
    },
    [createNewMeeting, setGuests, host, meetingType],
  );
  const setMeetingTypeAndSave = useCallback(
    async (value) => {
      setMeetingType(value);
      await createNewMeeting({
        contact: guests[0],
        meetingType: value,
        user: host,
      });
    },
    [createNewMeeting, setMeetingType, guests, host],
  );
  const setUserAndSave = useCallback(
    async (value) => {
      setHost(value);
      await createNewMeeting({
        contact: guests[0],
        meetingType,
        user: value,
      });
    },
    [createNewMeeting, setHost, meetingType, guests],
  );
  return {
    guests,
    host,
    isSaving,
    meetingType,
    setContactAndSave,
    setMeetingTypeAndSave,
    setTags,
    setUserAndSave,
    tags,
  };
}
