import useSWR from "swr";
import { useUserService } from "../../services";
import { useTokenRefreshHandler } from "../../hooks";
import { errorHandler } from "src/hooks/errorHandler";

export function useSignUpUrl() {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const { data, error } = useSWR(`/api/user/sign_up/web/url`, (url: string) =>
    service
      .get(url)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler),
  );

  return {
    data: data as { [index: string]: string } | undefined,
    error,
    loading: !error && !data,
  };
}
