import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import ReactModal from "react-modal";
import moment from "moment";
import { ChannelsStateContext, ModalStateContext } from "../ChannelsState";
import { patch } from "../../utils/fetch";
import { API } from "../../props";
import MeetingInstanceCard from "./cards/MeetingInstanceCard";
import { IntegrationsCard } from "./cards/IntegrationsCard";
import ConditionCards from "./cards/ConditionCards";
import { formatConditions } from "./helpers";
import { BLANK_FIELD, DEFAULT_STATE } from "./cards/cardProps";
import SampleImportData from "./cards/SampleImportData";
import Loader from "./Loader";
import { CHANNEL_TYPES } from "../props";
import { INTEGRATION_TYPES } from "../../integrations/props";
import { Button } from "../../components/button";
import useSampleImportData from "./useSampleImportData";
import FieldMappingCard from "./cards/FieldMappingCard";
import ChannelTagAutomation from "./cards/tagAutomation/ChannelTagAutomation";
import { useHasOrgAdminPermissions } from "../../auth";
import style from "./style.module.scss";

const ERROR = "There was a problem retrieving sample data";

function UpdateModal({
  channelType,
  onClose,
  onUpdate,
  meetingTypes: { isLoading: meetingsIsLoading, data: meetingTypes },
  presetMeetingDef,
}) {
  const {
    clearSampleData,
    hasError,
    isRetrievingSampleData,
    runSampleImportData,
    sampleImportData,
  } = useSampleImportData();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const scrollRef = useRef(null);
  const [
    {
      [channelType]: {
        update: { isOpen },
        selectedChannel,
      },
    },
  ] = useContext(ModalStateContext);
  const [{ [`${channelType}Fields`]: channelFields }] =
    useContext(ChannelsStateContext);
  const [state, dispatch] = useContext(ChannelsStateContext);
  const [canCreate, setCanCreate] = useState(false);
  const [tempModalState, setTempModalState] = useState(DEFAULT_STATE);
  const [showInvalidFields, setShowInvalidFields] = useState(false);
  const [meetingInstanceCardIsValid, setMeetingInstanceCardIsValid] =
    useState(false);
  const [integrationsCardIsValid, setIntegrationsCardIsValid] = useState(false);
  const [conditionCardsAreValid, setConditionCardsAreValid] = useState(false);

  const [meetingDef, setMeetingDef] = useState(
    presetMeetingDef || { id: null },
  );

  const integrations = useMemo(
    () => [...state[`${channelType}Integrations`].values()],
    [state, channelType],
  );

  const fieldObjectTypeData = useMemo(() => {
    const object = tempModalState.fieldObjectType.type;
    if (selectedChannel) {
      const { integration } = selectedChannel;
      if (state[`${channelType}Fields`].has(integration.name)) {
        return state[`${channelType}Fields`].get(integration.name)[object];
      }
      return null;
    }
  }, [
    channelType,
    state,
    tempModalState.fieldObjectType.type,
    selectedChannel,
  ]);

  const meetingTypeOptions = useMemo(
    () =>
      meetingTypes.map((m) => ({
        id: m.id,
        name: m.name,
      })),
    [meetingTypes],
  );

  const isOutreach = useMemo(() => {
    if (tempModalState.selectedIntegration.id !== null) {
      return (
        tempModalState.selectedIntegration.name === INTEGRATION_TYPES.OUTREACH
      );
    }

    return false;
  }, [tempModalState]);

  const config = useMemo(() => {
    if (channelType === CHANNEL_TYPES.IMPORT) {
      return {
        checkAssociatedObjects: tempModalState.checkAssociatedObjects,
        fieldMappings: tempModalState.fieldMappings,
        filters: formatConditions[channelType](tempModalState.conditions),
        meetingStatus: tempModalState.channelStatus.value,
        object: tempModalState.fieldObjectType.type,
      };
    }

    if (channelType === CHANNEL_TYPES.INTERCEPT) {
      return {
        triggers: formatConditions[channelType](
          tempModalState.conditions,
          tempModalState.fieldObjectType?.type,
        )[0],
      };
    }

    if (channelType === CHANNEL_TYPES.UPDATE) {
      return {
        triggers: formatConditions[channelType](
          tempModalState.conditions,
          tempModalState.fieldObjectType?.type,
          tempModalState.meetingStatus,
        )[0],
      };
    }
  }, [channelType, tempModalState]);

  // bring sample data area into view when sample import begins to load
  // this is here because in the event of a "long" channel (i.e., a lot of conditions), the sample data area
  // will be hidden
  useEffect(() => {
    if (isRetrievingSampleData) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isRetrievingSampleData]);

  const handleOnUpdate = useCallback(
    async (isSample) => {
      if (!canCreate) {
        setShowInvalidFields(true);
        return;
      }

      if (selectedChannel && selectedChannel.id) {
        const channelObject = {
          advanced: tempModalState.advanced,
          config,
          description: "",
          enabled: tempModalState.enabled,
          integration: tempModalState.selectedIntegration.id,
          meeting: meetingDef.id,
          name: tempModalState.name,
          tagAutomation: {
            // only send the ids of the tags we are saving
            tagContacts: tempModalState.tagAutomation.tagContacts.map(
              (t) => t.id,
            ),
            // only send the ids of the tags we are saving
            tagMeetings: tempModalState.tagAutomation.tagMeetings.map(
              (t) => t.id,
            ),
          },
          type: channelType,
        };

        // if this is a channel test run
        // explicit truth test as `isSample` will not be empty even if not testing
        if (isSample === true) {
          runSampleImportData(channelObject, selectedChannel.id);
          return;
        }

        const response = await patch(
          API.channels[channelType](selectedChannel.id),
          null,
          channelObject,
        ).then((res) => res.json());

        if (response && response.success) {
          onUpdate(channelObject.name);

          dispatch({
            payload: {
              channelType,
              channels: [
                {
                  ...channelObject,
                  advanced: tempModalState.advanced,
                  id: selectedChannel.id,
                  integration: tempModalState.selectedIntegration,
                  meeting: meetingDef,
                  tagAutomation: tempModalState.tagAutomation,
                },
              ],
            },
            type: "SET_INTEGRATION_CHANNEL",
          });
        }
      }
    },
    [
      canCreate,
      config,
      channelType,
      dispatch,
      meetingDef,
      onUpdate,
      runSampleImportData,
      selectedChannel,
      tempModalState,
    ],
  );

  const handleOnSample = useCallback(() => {
    handleOnUpdate(true);
  }, [handleOnUpdate]);

  useEffect(() => {
    if (selectedChannel) {
      const { name, integration, meeting, enabled, tagAutomation } =
        selectedChannel;
      let conditions = [[{ ...BLANK_FIELD }]];
      let selectedIntegration = integration;

      if (channelType === CHANNEL_TYPES.IMPORT) {
        const {
          config: {
            checkAssociatedObjects = false,
            filters,
            object,
            meetingStatus: status,
            fieldMappings = DEFAULT_STATE.fieldMappings,
          },
        } = selectedChannel;
        const isNewFormat = Array.isArray(filters[0]);

        // If a integration is deleted from the DB, the integration
        // object that is sent to us for existing channels with
        // disconnected integrations is:
        // { id: Number, name: '' }
        // So we check to see if name is an empty string to know
        // if the channel is orphaned or not
        if (integration.name === "") {
          // Hubspots only field object is contacts, so if the channel
          // has a field object of contacts then we know the integration
          // was Hubspot
          if (object === "contacts") {
            selectedIntegration = integrations.find(
              ({ name: n }) => n === INTEGRATION_TYPES.HUBSPOT,
            ) || { name: INTEGRATION_TYPES.HUBSPOT, orphaned: true };
          } else {
            selectedIntegration = integrations.find(
              ({ name: n }) => n === INTEGRATION_TYPES.SALESFORCE,
            ) || {
              name: INTEGRATION_TYPES.SALESFORCE,
              orphaned: true,
            };
          }
        }

        if (isNewFormat) {
          if (filters && filters.length) {
            conditions = filters.map((filter) => {
              return filter.map((condition) => {
                let newLabel = null;

                if (fieldObjectTypeData) {
                  const fieldObject = fieldObjectTypeData.find(
                    ({ name: n }) => n === condition.field.name,
                  );

                  if (fieldObject) {
                    newLabel = fieldObject.label;
                  }
                }

                return {
                  condition: condition.comparison.key,
                  field: {
                    label: newLabel,
                    name: condition.field.name,
                  },
                  value: condition.value.label,
                };
              });
            });
          } else if (filters && !filters.length) {
            conditions = [[{ ...BLANK_FIELD }]];
          }
        } else if (filters && filters.length) {
          conditions = [
            filters.map((condition) => {
              let newLabel = null;

              if (fieldObjectTypeData) {
                const fieldObject = fieldObjectTypeData.find(
                  ({ name: n }) => n === condition.field.name,
                );

                if (fieldObject) {
                  newLabel = fieldObject.label;
                }
              }

              return {
                condition: condition.comparison.key,
                field: {
                  label: newLabel,
                  name: condition.field.name,
                },
                value: condition.value.label,
              };
            }),
          ];
        } else if (filters && !filters.length) {
          conditions = [[{ ...BLANK_FIELD }]];
        }

        setTempModalState((prev) => ({
          ...prev,
          channelStatus: { value: status },
          checkAssociatedObjects,
          conditions,
          fieldMappings,
          fieldObjectType: { type: object },
          selectedIntegration,
          tagAutomation,
        }));
      }

      if (channelType === CHANNEL_TYPES.INTERCEPT) {
        const {
          config: { triggers },
        } = selectedChannel;
        if (integration.name === "") {
          if (triggers[0].event.object === "contacts") {
            selectedIntegration = integrations.find(
              ({ name: n }) => n === INTEGRATION_TYPES.HUBSPOT,
            ) || { name: INTEGRATION_TYPES.HUBSPOT, orphaned: true };
          } else {
            selectedIntegration = integrations.find(
              ({ name: n }) => n === INTEGRATION_TYPES.SALESFORCE,
            ) || {
              name: INTEGRATION_TYPES.SALESFORCE,
              orphaned: true,
            };
          }
        }
        if (triggers && triggers.length) {
          conditions = [
            triggers.map(({ event }) => {
              let newLabel = null;
              const currentName = event.field.name || event.field;

              if (fieldObjectTypeData) {
                const fieldObject = fieldObjectTypeData.find(
                  ({ name: n }) => n === currentName,
                );

                if (fieldObject) {
                  newLabel = fieldObject.label;
                }
              }

              return {
                condition: event.condition,
                field: {
                  label: newLabel,
                  name: currentName,
                },
                value: event.value,
              };
            }),
          ];
        } else if (triggers && !triggers.length) {
          conditions = [[{ ...BLANK_FIELD }]];
        }

        setTempModalState((prev) => ({
          ...prev,
          conditions,
          fieldObjectType: { type: triggers[0].event.object },
          selectedIntegration,
          tagAutomation,
        }));
      }

      if (channelType === CHANNEL_TYPES.UPDATE) {
        const {
          config: { triggers },
        } = selectedChannel;
        if (integration.name === "") {
          if (triggers[0].action.object === "contacts") {
            selectedIntegration = integrations.find(
              ({ name: n }) => n === INTEGRATION_TYPES.HUBSPOT,
            ) || { name: INTEGRATION_TYPES.HUBSPOT, orphaned: true };
          } else {
            selectedIntegration = integrations.find(
              ({ name: n }) => n === INTEGRATION_TYPES.SALESFORCE,
            ) || {
              name: INTEGRATION_TYPES.SALESFORCE,
              orphaned: true,
            };
          }
        }

        if (triggers && triggers.length) {
          conditions = [
            triggers.map(({ action }) => {
              let newLabel = null;
              const currentName = action.field.name || action.field;

              if (fieldObjectTypeData) {
                const fieldObject = fieldObjectTypeData.find(
                  ({ name: n }) => n === currentName,
                );

                if (fieldObject) {
                  newLabel = fieldObject.label;
                }
              }

              return {
                field: {
                  label: newLabel,
                  name: currentName,
                },
                value: action.value,
              };
            }),
          ];
        } else if (triggers && !triggers.length) {
          conditions = [[{ ...BLANK_FIELD }]];
        }

        setTempModalState((prev) => {
          let meetingStatus = [];

          if (triggers[0].event.value) {
            meetingStatus = [triggers[0].event.value];
          }

          if (triggers[0].event.values) {
            meetingStatus = triggers[0].event.values;
          }

          return {
            ...prev,
            conditions,
            fieldObjectType: { type: triggers[0].action.object },
            meetingStatus,
            selectedIntegration,
          };
        });
      }

      setTempModalState((prev) => ({
        ...prev,
        enabled,
        isOpen,
        name,
        selectedIntegration,
        tagAutomation,
      }));

      setMeetingDef(meeting.name === "" ? { id: null } : meeting);
    }
  }, [channelType, fieldObjectTypeData, integrations, isOpen, selectedChannel]);

  useEffect(() => {
    if (
      meetingInstanceCardIsValid &&
      integrationsCardIsValid &&
      conditionCardsAreValid
    ) {
      setCanCreate(true);
      return;
    }
    setCanCreate(false);
  }, [
    meetingInstanceCardIsValid,
    integrationsCardIsValid,
    conditionCardsAreValid,
  ]);

  const displayTime = useMemo(() => {
    let lastProcTime = selectedChannel?.config?.last_process_time || null;
    let createdAt = selectedChannel?.createdAt || null;

    if (!lastProcTime && !createdAt) {
      return null;
    }

    if (lastProcTime) {
      lastProcTime = moment(lastProcTime).calendar();

      if (!createdAt) {
        return `Channel last processed: ${lastProcTime}`;
      }
    }

    if (createdAt) {
      createdAt = moment(createdAt).calendar();

      if (!lastProcTime || createdAt === lastProcTime) {
        return `Channel not yet procesed since creation date: ${createdAt}`;
      }
    }

    return `Channel last processed: ${lastProcTime}`;
  }, [selectedChannel]);

  const handleOnClose = useCallback(() => {
    clearSampleData();
    onClose();
  }, [clearSampleData, onClose]);

  return (
    <ReactModal
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={handleOnClose}
      className="modal--content"
      overlayClassName="modal--overlay"
      shouldCloseOnOverlayClick={false}
    >
      <MeetingInstanceCard
        showInvalidFields={showInvalidFields}
        setMeetingInstanceCardIsValid={setMeetingInstanceCardIsValid}
        channelType={channelType}
        canCreate={canCreate}
        active={tempModalState.enabled}
        setTempModalState={setTempModalState}
        meetingDef={meetingDef}
        setMeetingDef={setMeetingDef}
        meetingTypes={meetingTypes}
        meetingTypeOptions={meetingTypeOptions}
        presetMeetingDef={presetMeetingDef}
        isLoading={meetingsIsLoading}
        handleOnClose={handleOnClose}
        selectedIntegration={tempModalState.selectedIntegration}
        meetingStatus={tempModalState.meetingStatus}
        channelStatus={tempModalState.channelStatus}
        name={tempModalState.name}
      />

      <div className={style.card_wrapper}>
        <IntegrationsCard
          updateModal
          showInvalidFields={showInvalidFields}
          setIntegrationsCardIsValid={setIntegrationsCardIsValid}
          channelType={channelType}
          setTempModalState={setTempModalState}
          integrations={integrations}
          isOpen={tempModalState.isOpen}
          selectedIntegration={tempModalState.selectedIntegration}
          fieldObjectType={tempModalState.fieldObjectType}
          fieldObjectsDropdownData={tempModalState.fieldObjectsDropdownData}
          checkAssociatedObjects={tempModalState.checkAssociatedObjects}
        />
        {channelType === CHANNEL_TYPES.IMPORT && (
          <FieldMappingCard
            fieldMappings={tempModalState.fieldMappings}
            fieldObjectType={tempModalState.fieldObjectType}
            fields={channelFields}
            selectedIntegration={tempModalState.selectedIntegration}
            setTempModalState={setTempModalState}
          />
        )}

        {channelType !== CHANNEL_TYPES.UPDATE && (
          <>
            {!isOutreach && (
              <div className={style.card_wrapper__filter_by}>Filtered By</div>
            )}
            {isOutreach && (
              <div className={style.card_wrapper__filter_by}>When Sequence</div>
            )}
          </>
        )}

        {channelType === CHANNEL_TYPES.UPDATE && (
          <>
            {!isOutreach && (
              <div className={style.card_wrapper__filter_by}>Updated As</div>
            )}
            {isOutreach && (
              <div className={style.card_wrapper__filter_by}>
                Start Sequence
              </div>
            )}
          </>
        )}

        <div className={style.card_wrapper__condition_cards_wrapper}>
          <ConditionCards
            showInvalidFields={showInvalidFields}
            setConditionCardsAreValid={setConditionCardsAreValid}
            channelType={channelType}
            setTempModalState={setTempModalState}
            handleOnCreate={handleOnUpdate}
            conditions={tempModalState.conditions}
            fieldObjectType={tempModalState.fieldObjectType}
            selectedIntegration={tempModalState.selectedIntegration}
          />
        </div>
      </div>
      <div className={style.card_wrapper}>
        <ChannelTagAutomation
          setTempModalState={setTempModalState}
          tagAutomation={tempModalState.tagAutomation}
        />
      </div>
      <div className={style.channel_actions}>
        {channelType === CHANNEL_TYPES.IMPORT && hasOrgAdminPermissions && (
          <div className={style.sampleButtons}>
            <Button
              primary
              large
              onClick={handleOnSample}
              name="/channels/import/edit/sample"
              disabled={isRetrievingSampleData}
            >
              <span>Sample</span>
            </Button>

            {(hasError || sampleImportData !== null) && (
              <Button
                primary
                large
                name="/channels/import/edit/clear_sample"
                onClick={clearSampleData}
                className={style.clearSampleButton}
              >
                <span>Clear sample data</span>
              </Button>
            )}
          </div>
        )}

        <Button
          primary
          large
          disabled={tempModalState.selectedIntegration.orphaned}
          name={`/channels/${channelType}/edit/update`}
          onClick={handleOnUpdate}
        >
          <span>Update</span>
        </Button>
      </div>
      {isRetrievingSampleData && <Loader />}
      {hasError && (
        <div className={style.errorRetrievingSampleData}>{ERROR}</div>
      )}

      {sampleImportData !== null && (
        <SampleImportData importData={sampleImportData} hasError={hasError} />
      )}

      {displayTime && (
        <div className={style.lastProcessTime}>{displayTime}</div>
      )}
      <div ref={scrollRef} />
    </ReactModal>
  );
}

export default UpdateModal;
