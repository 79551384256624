import React from "react";
import { Paper } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import Table, { Column } from "src/componentsV2/tables/Table";

// A data type used to keep track of how column indexes map to specific fields. For example
// consider how column indexes might be mapped to contact field in the example below:
// {
//   0: 'email',
//   1: 'firstName',
//   3: 'lastName',
// }
type ColumnHeaderMap = { [index: number]: string };

// The component is used to render a preview of the data table and allow the user to select an appropriate header
// value from a given dropdown component. It is used to aid in the mapping from CSV data to structured data types.
export function CsvHeaderMapping({
  dropdown,
  data = [],
  value = {},
  onChange,
}: {
  dropdown: React.FunctionComponent<{
    value: string;
    onChange: (event: SelectChangeEvent<string>) => void;
  }>;
  data?: Array<string[]>;
  value?: ColumnHeaderMap;
  onChange?: (value: ColumnHeaderMap) => void;
}) {
  const PAGE_SIZE = 5;

  // Lets restructure our input data so that we can assign an id to each row.
  const table = data.map((row, index) => ({ id: index + 1, values: row }));

  // Pick off the first few rows for the preview so as not to render the whole table.
  const selected = table.slice(0, PAGE_SIZE);

  // Determin how many columns there are in the data. We will determine this
  // by taking the length of the first row (if the row exists) or a default
  // value of 0.
  const numColumns = data?.[0]?.length || 0;

  // Initialize the columns so that we have a row id as the first column.
  const columns: Column<{ id: number; values: string[] }>[] = [
    { id: "row", label: "Row", component: (row) => <>{row.id}</> },
  ];

  return (
    <Paper elevation={0} sx={{ overflowY: "auto" }}>
      <Table
        columns={columns
          // Add a column for each column in the data array. The label element for each column will be used to select a column type.
          .concat(
            new Array(numColumns).fill(null).map((col, i) => ({
              id: (i + 1).toString(),
              label: dropdown({
                value: value[i],
                onChange: (e) => {
                  if (onChange) {
                    const map = { ...value };
                    map[i] = e.target.value;

                    // There is no reason to keep track of unselected columns.
                    if (map[i] === "select") {
                      delete map[i];
                    }
                    onChange(map);
                  }
                },
              }),
              component: (row) => <>{row.values[i]}</>,
            })),
          )}
        data={selected}
        minRows={PAGE_SIZE}
      />
    </Paper>
  );
}

export default CsvHeaderMapping;
