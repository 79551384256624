import React, { useCallback, useRef } from "react";
import { TagManager } from "../../components/tagManager";
import { useModal } from "../../components/modal";
import { useSize } from "../../components/size";
import { TagList } from "../../components/tagList";
import { TagButton } from "../../components/tagButton";
import style from "./style.module.scss";
import { useTagsRepository } from "../../repository";

const EMPTY_LIST = [];

export function NewFilterTags({ tags = EMPTY_LIST, setTags }) {
  const containerRef = useRef(null);
  const { width } = useSize(containerRef);
  const [{ close: closeManageTags }, setModal, { DRAWER: type }] = useModal();
  const onSave = useCallback(
    (selectedTags) => {
      setTags(selectedTags);
    },
    [setTags],
  );
  const repository = useTagsRepository();
  const onRemove = useCallback(() => [], []);
  const openTagManagement = useCallback(() => {
    setModal({
      blur: false,
      content: (
        <TagManager
          close={closeManageTags}
          onRemove={onRemove}
          onSave={onSave}
          title="Manage Tags"
          repository={repository}
          initialSelectedTags={[]}
        />
      ),
      type,
    });
  }, [closeManageTags, repository, onRemove, onSave, setModal, type]);
  return (
    <div ref={containerRef}>
      {tags && tags.length > 0 && (
        <TagList
          onClick={openTagManagement}
          tags={tags}
          size={0.3}
          maxCharPerRow={Math.floor(width / 6)}
          maxRows={6}
        />
      )}

      {(!tags || tags.length === 0) && (
        <TagButton
          className={style.tag__Button}
          onClick={openTagManagement}
          size={0.85}
        />
      )}
    </div>
  );
}
