export interface DataExport {
  id: number;
  storageRef: string;
  createdAt: string;
  status: string;
  progress: number | undefined;
  type: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  details: string;
}

export type ObjectType = "meeting_csv" | "contact_csv" | "user_csv";

export function objectTypeToLabel(objectType: ObjectType) {
  const OBJECT_TYPES_TO_LABEL = {
    contact_csv: "Contact Export",
    meeting_csv: "Meeting Export",
    user_csv: "User Export",
    contact_csv_import: "Contact Import",
    user_csv_import: "User Import",
  };

  if (objectType in OBJECT_TYPES_TO_LABEL) {
    return OBJECT_TYPES_TO_LABEL[objectType];
  }

  return null;
}

export type DownloadStatus = "completed" | "error" | "unknown" | "working";

export function colorFromStatus(status: DownloadStatus, errorDetails: string) {
  switch (status) {
    case "completed":
      if (errorDetails) {
        return "warning";
      }
      return "success";
    case "error":
      return "error";
    case "working":
      return "warning";
    case "unknown":
      return "default";
    default:
      return "default";
  }
}
