import { useCallback, useEffect, useMemo, useState } from "react";

import { UnauthorizedError, toErrorLogInfo } from "../error";
import {
  buildRequest,
  buildUrl,
  fetchWithErrorHandling,
} from "../utils/fetchV2";
import { API } from "../props";
import useDoFetch from "./useDoFetch";

async function createTag(newTag) {
  return await fetchWithErrorHandling(
    buildRequest(API.tags.default(), {
      body: JSON.stringify(newTag, null, 4),
      method: "POST",
    }),
  ).then((response) => response.json());
}

function buildUpdateTagRequest(tagId, update) {
  return buildRequest(API.tags.default(tagId), {
    body: JSON.stringify(update, null, 4),
    method: "PUT",
  });
}

async function updateTag(tagId, update) {
  await fetchWithErrorHandling(buildUpdateTagRequest(tagId, update));
}

function buildDeleteTagsRequest(tagIds) {
  return buildRequest(
    buildUrl(
      API.tags.default(),
      tagIds.map((tagId) => `tagIds=${tagId}`).join("&"),
    ),
    {
      method: "DELETE",
    },
  );
}

async function deleteTags(tagIds) {
  const request = buildDeleteTagsRequest(tagIds);
  await fetchWithErrorHandling(request);
}

export function buildSearchTagsRequest(
  filter = null,
  offset = 0,
  unshift = [],
  limit = 100,
) {
  const query = [
    ["limit", limit],
    ["offset", offset],
  ];
  unshift.forEach((tagId) => {
    query.push(["unshift", tagId]);
  });
  if (filter) {
    query.push(["qry", filter]);
  }
  return buildRequest(buildUrl(API.tags.default(), query));
}

const EMPTY_LIST = [];

function useSearchQuery(
  { filter = null, offset = 0, unshift = EMPTY_LIST } = {
    filter: null,
    offset: 0,
    unshift: EMPTY_LIST,
  },
  setOffset,
) {
  const [isManuallyRefreshing, setIsManuallyRefreshing] = useState(false);
  const searchTagsRequests = useMemo(() => {
    return [buildSearchTagsRequest(filter, offset, unshift)];
  }, [filter, offset, unshift]);
  const {
    errors: [tagsError],
    isInitialized: areTagsInitialized,
    isLoading: areTagsLoading,
    payloads: [tagPayload = null],
    refresh,
    setPayloads,
  } = useDoFetch(searchTagsRequests);
  const remove = useCallback(
    async (tagIds, shouldUpdatePersistentData = false) => {
      setPayloads((prevPayloads) => {
        const [prev = null] = prevPayloads;
        if (prev === null) {
          return prevPayloads;
        }
        const {
          data: prevData,
          total: prevTotal,
          _links: previousLinks,
        } = prev;
        const newTotal =
          prevTotal - prevData.filter((t) => tagIds.includes(t.id)).length;
        const newData = prevData.filter((t) => !tagIds.includes(t.id));
        return [
          {
            _links: previousLinks,
            data: newData,
            total: newTotal,
          },
        ];
      });
      setIsManuallyRefreshing(true);
      if (shouldUpdatePersistentData) {
        return deleteTags(tagIds);
      }
    },
    [setIsManuallyRefreshing, setPayloads],
  );
  const edit = useCallback(
    async (tagId, update, shouldUpdatePersistentData = false) => {
      setPayloads((prevPayloads) => {
        const [prev = null] = prevPayloads;
        if (prev === null) {
          return prevPayloads;
        }
        const { _links: prevLinks, data: prevData, total: prevTotal } = prev;
        const newData = prevData.map((tag) => {
          if (tag.id === tagId) {
            return {
              ...tag,
              ...update,
            };
          }
          return tag;
        });
        return [
          {
            _links: prevLinks,
            data: newData,
            total: prevTotal,
          },
        ];
      });
      // Updates the external resource. Useful when recovering from errors.
      if (shouldUpdatePersistentData) {
        await updateTag(tagId, update);
      }
    },
    [setPayloads],
  );
  const tags = useMemo(() => {
    if (tagPayload === null) {
      return [];
    }
    const { data } = tagPayload;
    return data;
  }, [tagPayload]);
  const total = useMemo(() => {
    if (tagPayload === null) {
      return 0;
    }
    const { total: value } = tagPayload;
    return value;
  }, [tagPayload]);
  const links = useMemo(() => {
    if (tagPayload === null) {
      return null;
    }
    const { _links: value } = tagPayload;
    return value;
  }, [tagPayload]);
  useEffect(() => {
    // Set to the last page available if we're past the available offsets
    if (links !== null) {
      const params = new URLSearchParams(links.last.href.split("?")[1]);
      const last = params.get("offset");
      if (total > 0 && tags.length === 0) {
        if (offset > last) {
          setOffset(parseInt(last));
        }
        setIsManuallyRefreshing(true);
      } else {
        setIsManuallyRefreshing(false);
      }
    }
  }, [links, offset, total, tags, setOffset]);
  return {
    edit,
    error: tagsError,
    isInitialized: areTagsInitialized,
    isLoading: areTagsLoading,
    isManuallyRefreshing,
    payload: tagPayload,
    refresh,
    remove,
    setIsManuallyRefreshing,
    tags,
    total,
  };
}

async function search(filter) {
  try {
    const request = buildSearchTagsRequest(filter);
    const response = await fetchWithErrorHandling(request);
    const { status } = response;
    if (status === 401) {
      throw new UnauthorizedError(
        await response.text(),
        toErrorLogInfo("UnauthorizedError", request),
      );
    }
    if (status !== 200) {
      throw new Error(await response.text());
    }
    return {
      data: await response.json(),
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
}

export default function useTagsRepository() {
  return {
    buildSearchTagsRequest,
    createTag,
    deleteTags,
    search,
    updateTag,
    useSearchQuery,
  };
}
