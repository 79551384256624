import React, { useCallback, useMemo } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";

import { useGuestStatusOptions } from "../props";
import { Select } from "../../inputs";
import { useSearch, useSearchUpdater } from "../Search";
import { useFilterMenuContext } from "../context";
import { useDropdownTheme } from "./useDropdownTheme";
import style from "./style.module.scss";

function noGuestStatusOptions() {
  return "No Guest Status options left.";
}

export function GuestStatusFilter({ menuPortalTarget }) {
  const [components, theme] = useDropdownTheme();
  const guestStatusOptions = useGuestStatusOptions();
  const { setFilterMenuClosed, setFilterMenuOpen } = useFilterMenuContext();
  const {
    filter: { guestStatusFilter },
  } = useSearch();
  const searchUpdater = useSearchUpdater();
  const onGuestStatusFilterChange = useCallback(
    (values) => {
      const newMeetingStatusFilter = values.map((v) => v.value);
      searchUpdater.setGuestStatusFilter([...newMeetingStatusFilter]);
    },
    [searchUpdater],
  );
  const guestStatusValues = useMemo(() => {
    if (guestStatusFilter.length === 0) {
      return null;
    }
    return guestStatusOptions.filter((option) => {
      return guestStatusFilter.includes(option.value);
    });
  }, [guestStatusFilter, guestStatusOptions]);
  const placeholder = useMemo(() => {
    return (
      <div className={style.meetingType__filterPlaceHolder}>
        <div className={style.meetingType__filterPlaceHolderText}>
          Guest Statuses
        </div>
        <Icon
          className={style.meetingType__filterPlaceHolderIcon}
          path={mdiChevronDown}
          size={0.5}
        />
      </div>
    );
  }, []);
  return useMemo(() => {
    return (
      <Select
        closeMenuOnScroll
        components={components}
        customTheme={theme}
        isMulti
        label="Guest Statuses"
        menuPortalTarget={menuPortalTarget}
        name="/instances/action_bar/guest_statuses_dropdown"
        noOptionsMessage={noGuestStatusOptions}
        onChange={onGuestStatusFilterChange}
        onMenuOpen={setFilterMenuOpen}
        onMenuClose={setFilterMenuClosed}
        options={guestStatusOptions}
        placeholder={placeholder}
        value={guestStatusValues}
      />
    );
  }, [
    components,
    guestStatusOptions,
    guestStatusValues,
    menuPortalTarget,
    onGuestStatusFilterChange,
    placeholder,
    setFilterMenuClosed,
    setFilterMenuOpen,
    theme,
  ]);
}
