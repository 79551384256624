import React, { createContext, useEffect, useState } from "react";
import Flexbox from "../components/flexbox/FlexboxV2";
import { XLargeSpacing, XXXLargeSpacing } from "../components/spacing";
import { P1, H1 } from "../components/typography";
import { useWindowConfigRef } from "./useWindowConfigRef";
import { REQUIRED_KEYS, OPTIONAL_KEYS } from "./props";

export const ConfigurationContext = createContext(null);

export function Configuration({ children }) {
  const [configurationState, setConfigurationState] = useState({
    data: null,
    error: null,
    loading: true,
  });
  const windowConfigRef = useWindowConfigRef();
  useEffect(() => {
    const { current: windowConfig } = windowConfigRef;
    const dataKeys = Object.keys(windowConfig || {});
    const missingRequiredKeys = REQUIRED_KEYS.filter(
      (requiredKey) => !dataKeys.includes(requiredKey),
    );
    const hasRequiredKeys = missingRequiredKeys.length === 0;
    if (hasRequiredKeys) {
      setConfigurationState({
        data: [...REQUIRED_KEYS, ...OPTIONAL_KEYS].reduce((acc, key) => {
          if (key in windowConfig) {
            let value = windowConfig[key];
            if (value === "true") {
              value = true;
            }
            if (value === "false") {
              value = false;
            }
            acc[key] = value;
          } else {
            acc[key] = null;
          }
          return acc;
        }, {}),
        error: null,
        loading: false,
      });
      return;
    }
    if (!hasRequiredKeys) {
      setConfigurationState({
        data: null,
        error: new Error(`Missing required keys: ${missingRequiredKeys}`),
        loading: false,
      });
      return;
    }
    setConfigurationState({
      data: null,
      error: null,
      loading: false,
    });
  }, [windowConfigRef]);
  const { error, loading } = configurationState;
  return (
    <ConfigurationContext.Provider value={configurationState}>
      {error === null && !loading && children}
      {error && (
        <Flexbox.Column>
          <XLargeSpacing />
          <Flexbox.Row>
            <XLargeSpacing />
            <H1>Invalid Configuration</H1>
          </Flexbox.Row>
          <XLargeSpacing />
          <Flexbox.Row>
            <XXXLargeSpacing />
            <P1>{` ${error.message}`}</P1>
          </Flexbox.Row>
        </Flexbox.Column>
      )}
    </ConfigurationContext.Provider>
  );
}
