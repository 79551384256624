import { Box, Grid, Slider, Stack, Switch, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Controller, useFormContext } from "react-hook-form";
import { isLinkFirst } from "src/meetingTypes/invite/props";
import {
  MeetingDefinition,
  MeetingDefinitionDefaultDayRange,
  isCustomSchedulingMode,
  useMaxDaysAllowed,
  MeetingDefinitionTeamMember,
} from "src/types";
import SchedulingModeDropdown from "./SchedulingModeDropdown";
import TemplateAvailability from "./TemplateAvailability";
import { TimezoneSelect } from "./inputs/TimezoneSelect";
import { useTemplateAvailability } from "src/features/useTemplateAvailability";
import { useDebounce } from "../hooks";
import { useMeetingDefinitionBusyTeamMembers } from "../queries/useMeetingDefinitionBusyTeamMembers";
import { useEffect, useState } from "react";
import useGeneralNotifications from "../hooks/useGeneralNotifications";

type TemplateEditTimeAttributesProps = {
  meetingDefinitionId?: number;
};

export const TemplateEditTimeAttributes = (
  props: TemplateEditTimeAttributesProps,
) => {
  const { control, setValue, getValues, watch } =
    useFormContext<MeetingDefinition>();
  const maxDays = useMaxDaysAllowed();
  const templateAvailabilityEnabled = useTemplateAvailability();

  const formattedTeamMember = (
    teamMember: MeetingDefinitionTeamMember,
  ): string => {
    const { firstName, lastName, email } = teamMember;
    if (firstName && lastName) {
      return `${firstName} ${lastName} <${email}>`;
    } else if (firstName) {
      return `${firstName} <${email}>`;
    } else {
      return email;
    }
  };

  const { addError } = useGeneralNotifications();
  const debouncedTimezone = useDebounce(watch("timezone"), 1500);
  const debouncedWeekSchedule = useDebounce(watch("weekSchedule"), 1500);
  const schedulingMode = watch("schedulingMode");
  const isRoutingJustMe = watch("routingJustMe");
  const { data, error } = useMeetingDefinitionBusyTeamMembers(
    props.meetingDefinitionId,
    schedulingMode,
    debouncedWeekSchedule,
    debouncedTimezone,
    isRoutingJustMe,
  );

  const [busyTeamMembers, setBusyTeamMembers] = useState<
    MeetingDefinitionTeamMember[]
  >([]);

  useEffect(() => {
    setBusyTeamMembers(data || []);
  }, [data]);

  if (error) {
    addError("Error getting busy team members");
    console.error(error);
  }

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Time Attributes
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Set duration of meeting, gap between meetings, scheduling range, and RSVP required by.`
            : `Set duration of meeting and gap between meetings.`}
        </Typography>
      </Box>

      <Grid sx={{ mb: 7 }} container spacing={10}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" fontWeight="bold">
            Meeting Duration
          </Typography>
          <Typography sx={{ mb: 3 }}>
            How long should the meeting last for?
          </Typography>

          <Controller
            control={control}
            name="properties.duration"
            render={({ field }) => (
              <>
                <Slider
                  {...field}
                  getAriaLabel={() => "Meeting Duration"}
                  defaultValue={30}
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={15}
                  max={180}
                />
                <Typography>{field.value} Minutes</Typography>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5" fontWeight="bold">
            Gap
          </Typography>
          <Typography sx={{ mb: 3 }}>
            What is the minimum amount of time between meetings?
          </Typography>

          <Controller
            control={control}
            name="properties.gap"
            render={({ field }) => (
              <>
                <Slider
                  {...field}
                  defaultValue={0}
                  getAriaLabel={() => "Gap"}
                  value={field.value}
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={0}
                  max={45}
                />
                <Typography>{field.value} Minutes</Typography>
              </>
            )}
          />
        </Grid>

        {!isLinkFirst(getValues("inviteStyle")) && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" fontWeight="bold">
                RSVP Required By
              </Typography>
              <Typography sx={{ mb: 3 }}>
                How far ahead of the proposed time do you need the meeting
                confirmed?
              </Typography>

              <Controller
                control={control}
                name="buffer_duration_mins"
                render={({ field }) => {
                  const hrs = Math.floor(field.value / 60);
                  return (
                    <>
                      <Slider
                        {...field}
                        getAriaLabel={() => "RSVP Required By"}
                        defaultValue={60 * 3}
                        valueLabelDisplay="auto"
                        step={60}
                        marks
                        min={60}
                        max={60 * 24}
                        onChange={(_, value) => {
                          if (Array.isArray(value)) {
                            return;
                          }
                          field.onChange(value);
                          const bufferHours = Math.ceil(value / 60);
                          setValue(
                            "properties.sameDayBuffer",
                            Math.min(bufferHours + 3, 24),
                          );
                        }}
                        valueLabelFormat={(minutes) => Math.floor(minutes / 60)}
                      />
                      <Typography>
                        {hrs} hour{hrs === 1 ? "" : "s"} before meeting start
                        time
                      </Typography>
                    </>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h5" fontWeight="bold">
                Day Range
              </Typography>
              <Typography sx={{ mb: 3 }}>
                How far ahead of time should Kronologic start scheduling these
                meetings?{" "}
              </Typography>

              <Controller
                control={control}
                name="properties.dayRange"
                render={({ field }) => (
                  <>
                    <Slider
                      value={[field.value?.from ?? 1, field.value?.to ?? 30]}
                      onChange={(_, newValue) => {
                        if (!Array.isArray(newValue)) {
                          return;
                        }
                        field.onChange({
                          ...field.value,
                          from: newValue[0],
                          to: newValue[1],
                        });
                      }}
                      getAriaLabel={() => "Day Range"}
                      defaultValue={[1, MeetingDefinitionDefaultDayRange]}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={maxDays}
                    />
                    <Typography>
                      {field.value?.from} day
                      {field.value?.from === 1 ? "" : "s"} to {field.value?.to}{" "}
                      day{field.value?.to === 1 ? "" : "s"}
                    </Typography>
                  </>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>

      {templateAvailabilityEnabled && (
        <Stack sx={{ flexDirection: "row", gap: 2 }}>
          <Grid item xs={12} sm={6} maxWidth="60%">
            <Typography variant="h5" fontWeight="bold">
              Availability
            </Typography>
            <Typography sx={{ mb: 3, width: "50%" }}>
              Do you want to use each user's default availability, or do you
              want to set parameters specific to this template?
            </Typography>

            <Controller
              control={control}
              name="schedulingMode"
              render={({ field }) => (
                <Stack>
                  <Box width="20%">
                    <SchedulingModeDropdown
                      schedulingMode={field.value}
                      onChange={field.onChange}
                    />
                  </Box>
                </Stack>
              )}
            />

            <Controller
              control={control}
              name="timezone"
              render={({ field }) => (
                <Stack>
                  <Box sx={{ mt: "15px", mb: "10px" }}>
                    {isCustomSchedulingMode(watch("schedulingMode")) && (
                      <Stack width="60%">
                        {watch("routingJustMe") ? (
                          <Typography sx={{ mb: 3, width: "83%" }}>
                            Note: Because this template routing behavior is
                            "Just Me", only the time slots available in the
                            settings below will be offered to guests.
                          </Typography>
                        ) : (
                          <Typography sx={{ mb: 3, width: "83%" }}>
                            Note: Because this template will be used to set
                            meetings on behalf of other users, only time slots
                            available in both the settings below AND the
                            individual users' default availability settings will
                            be offered to guests.
                          </Typography>
                        )}
                        <TimezoneSelect
                          label="Template Timezone"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </Stack>
                    )}
                  </Box>
                </Stack>
              )}
            />

            <Controller
              control={control}
              name="weekSchedule"
              render={({ field }) => (
                <Stack>
                  <Box sx={{ mt: "15px", mb: "10px" }}>
                    {isCustomSchedulingMode(watch("schedulingMode")) && (
                      <TemplateAvailability
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  </Box>
                </Stack>
              )}
            />
          </Grid>
          {isCustomSchedulingMode(watch("schedulingMode")) &&
            busyTeamMembers &&
            busyTeamMembers.length > 0 && (
              <Stack
                sx={{
                  backgroundColor: "#fae5e3",
                  padding: 2.5,
                  width: "25%",
                  maxHeight: "800px",
                  overflow: "auto",
                  marginTop: 4,
                  marginLeft: "auto",
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: "bold", marginBottom: 4 }}>
                    The following users will have zero availability for this
                    meeting template given current availability settings:
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    gap: 1,
                  }}
                >
                  {busyTeamMembers.map((busyTeamMember) => (
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      key={busyTeamMember.userId}
                    >
                      <Typography component="span">
                        {formattedTeamMember(busyTeamMember)}
                        <a
                          href={`/users/${busyTeamMember.userId}`}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            marginRight: "10px",
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <OpenInNewIcon
                            sx={{
                              height: "0.6em",
                            }}
                          />
                        </a>
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
        </Stack>
      )}

      {!isLinkFirst(getValues("inviteStyle")) && (
        <>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="timeNegotiationEnabled"
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              AI Negotiation
            </Typography>
          </Stack>
          <Typography sx={{ mb: 3 }}>
            Enabling will allow Kronologic's AI to automatically reschedule
            meetings with guests if they propose a new time
          </Typography>

          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="oooProcessingEnabled"
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              AI Out of Office Detection
            </Typography>
          </Stack>

          <Typography sx={{ mb: 3 }}>
            Enabling will allow Kronologic's AI to detect if an out of office
            response is received and reschedule the meeting upon the guest's
            stated return{" "}
          </Typography>
        </>
      )}
    </Box>
  );
};
