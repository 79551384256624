import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import ReactModal from "react-modal";

import { ModalRenderProps } from "./context";
import { blur, deblur } from "../../utils/blur";
import { DEFAULT, DRAWER, LEGACY } from "./types";
import style from "./modal.module.scss";

export default function Modal({ children }) {
  const [content, setContent] = useState(null);
  const [isBlurEnabled, setBlurEnabled] = useState(false);
  const [type, setType] = useState(DEFAULT);
  const [isOpen, setIsOpen] = useState(false);
  const isDefault = useMemo(() => {
    return type === DEFAULT;
  }, [type]);
  const isDrawer = useMemo(() => {
    return type === DRAWER;
  }, [type]);
  const isLegacy = useMemo(() => {
    return type === LEGACY;
  }, [type]);
  const setContentsToNull = useCallback(() => {
    setContent(null);
  }, []);

  const close = useCallback(() => {
    setContentsToNull();
  }, [setContentsToNull]);

  useEffect(() => {
    if (!isBlurEnabled) {
      deblur();
    }
    if (content !== null) {
      setIsOpen(true);
      if (isBlurEnabled) {
        blur();
      }
    } else {
      setIsOpen(false);
      deblur();
    }
  }, [content, isBlurEnabled]);
  const setModal = useCallback(
    ({
      blur: newBlurSetting = true,
      content: newContent,
      type: newType = LEGACY,
    }) => {
      if (!newContent) {
        throw new Error("No content for modal specified");
      }
      setBlurEnabled(newBlurSetting);
      setContent(newContent);
      setType(newType);
    },
    [setBlurEnabled, setContent, setType],
  );

  const modalRenderProps = useMemo(() => {
    return [
      {
        close,
      },
      setModal,
      // Types of modals available
      {
        DEFAULT,
        DRAWER,
        LEGACY,
      },
    ];
  }, [close, setModal]);

  return (
    <>
      <ModalRenderProps.Provider value={modalRenderProps}>
        {children}
      </ModalRenderProps.Provider>
      {content && (
        <ReactModal
          shouldCloseOnEsc
          isOpen={isOpen}
          onRequestClose={setContentsToNull}
          className={cx({
            [style.default__content]: isDefault,
            [style.drawer__content]: isDrawer,
            "modal--content": isLegacy,
          })}
          overlayClassName={cx({
            [style.default_overlay]: isDefault,
            [style.drawer__overlay]: isDrawer,
            "modal--overlay": isLegacy,
          })}
          shouldCloseOnOverlayClick={!isLegacy}
        >
          {content}
        </ReactModal>
      )}
    </>
  );
}
