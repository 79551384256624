import { Dayjs } from "dayjs";
import { useUserService } from "../services";
import { GuestActionSource } from "../types";

export function useGuestProposeNewTime() {
  const service = useUserService();

  return (
    contactId: string,
    meetingId: string,
    orgId: string,
    proposedTime: Dayjs,
    source: GuestActionSource | null,
  ): Promise<void> =>
    service
      .post(`/api/meeting/guest/action`)
      .send({
        a: "propose_new_time",
        c: contactId,
        m: meetingId,
        o: orgId,
        t: proposedTime,
        s: source,
      })
      .then((res: Response) => res.body);
}
