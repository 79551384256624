import React, { useMemo } from "react";
import moment from "moment";
import Flexbox from "../../../components/flexbox/FlexboxV2";
import { P1 } from "../../../components/typography";
import {
  ACCEPTED_STATUS,
  DECLINED_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  NO_RESPONSE_STATUS,
  GUEST_STATUS_TO_LABEL,
} from "../../props";
import { useMeeting } from "../state";
import style from "./reporting.module.scss";

const FINAL_STATUSES = [
  ACCEPTED_STATUS,
  DECLINED_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  NO_RESPONSE_STATUS,
];

export default function Reporting() {
  const [meeting] = useMeeting();
  const { guests = null } = meeting;
  const isInFinalStatus = useMemo(() => {
    if (!Array.isArray(guests) || guests.length === 0) {
      return false;
    }
    return FINAL_STATUSES.includes(guests[0].status);
  }, [guests]);
  const messageTop = useMemo(() => {
    if (isInFinalStatus) {
      return `${GUEST_STATUS_TO_LABEL[meeting.guests[0].status]} on ${moment(
        meeting.lastActivity,
      ).format("dddd")},`;
    }
    return "--";
  }, [isInFinalStatus, meeting]);
  const messageBottom = useMemo(() => {
    const date = moment(meeting.lastActivity);
    return `${date.format("M/DD")} at ${date.format("h:mma")},`;
  }, [meeting]);
  return (
    <Flexbox.Column className={style.reporting} height="100%">
      <Flexbox.Row height="15%" justifyContent="flex-start">
        <P1 small bold>
          Reporting
        </P1>
      </Flexbox.Row>
      <Flexbox.Column className={style.reporting__body} flex={1}>
        <Flexbox.Row height="10%" justifyContent="flex-start">
          <P1 extraSmall>
            {`Final Guest Statuses:${
              isInFinalStatus ? "" : " No data available"
            }`}
          </P1>
        </Flexbox.Row>

        <Flexbox.Column flex={1} justifyContent="center" alignItems="center">
          <P1 extraSmall textTransform="none">
            {messageTop}
          </P1>
          {isInFinalStatus && (
            <P1 extraSmall textTransform="none">
              {messageBottom}
            </P1>
          )}
        </Flexbox.Column>
      </Flexbox.Column>
    </Flexbox.Column>
  );
}
