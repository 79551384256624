import React, { useCallback, useEffect, useState } from "react";
import { isDeeplyEqual } from "../../../utils/helpers";
import { BLANK_FIELD } from "./cardProps";
import Condition from "./Condition/Condition";
import style from "./style.module.scss";

function ConditionGroup({
  showInvalidFields,
  channelType,
  group,
  groupIndex,
  conditions,
  setConditions,
  conditionFields,
  selectedIntegration,
  addGroup,
  isAddModal,
}) {
  const [conditionsBeingEdited, setConditionsBeingEdited] = useState([]);

  useEffect(() => {
    if (group.length === 1 && isDeeplyEqual(group[0], BLANK_FIELD)) {
      setConditionsBeingEdited([0]);
    }
  }, [group]);

  const handleAddCondition = useCallback(() => {
    const groups = [...conditions];
    groups[groupIndex] = [...groups[groupIndex], { ...BLANK_FIELD }];
    setConditions(groups);

    setConditionsBeingEdited((prev) => [
      ...prev,
      groups[groupIndex].length - 1,
    ]);
  }, [conditions, groupIndex, setConditions]);

  const handleDeleteCondition = useCallback(
    (conditionIdx) => {
      const groups = [...conditions];
      groups[groupIndex].splice(conditionIdx, 1);
      setConditions(groups.filter((g) => g?.length > 0));

      setConditionsBeingEdited((prev) => {
        prev.splice(prev.indexOf(conditionIdx), 1);
        return prev;
      });
    },
    [conditions, groupIndex, setConditions],
  );
  return (
    <div key={`${groupIndex + 1}`} className={style.conditions__card}>
      {group.map(({ field, condition, value }, conditionIndex) => (
        <Condition
          key={`condition-${groupIndex + 1}-${conditionIndex + 1}`}
          showInvalidFields={showInvalidFields}
          groupIndex={groupIndex}
          conditionIndex={conditionIndex}
          channelType={channelType}
          selectedIntegration={selectedIntegration}
          fields={conditionFields}
          total={group.length}
          conditions={conditions}
          setConditions={setConditions}
          field={field}
          condition={condition}
          value={value}
          conditionsBeingEdited={conditionsBeingEdited}
          setConditionsBeingEdited={setConditionsBeingEdited}
          handleDeleteCondition={handleDeleteCondition}
          handleAddCondition={handleAddCondition}
          addGroup={addGroup}
          isAddModal={isAddModal}
        />
      ))}
    </div>
  );
}

export default ConditionGroup;
