import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { Input } from "../input";
import style from "./style.module.scss";

function TagFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter);
  const debouncedSetFilter = useMemo(() => {
    return debounce(setFilter, 300);
  }, [setFilter]);
  const efficientSetFilter = useCallback(debouncedSetFilter, [
    debouncedSetFilter,
    setFilter,
  ]);
  const onChange = useCallback(
    ({ target: { value: newValue } }) => {
      setValue(newValue);
    },
    [setValue],
  );
  useEffect(() => {
    efficientSetFilter(value);
  }, [value, efficientSetFilter]);
  return (
    <Input
      large
      placeholder="Search tags"
      name="/tag_manager/search_tags"
      value={value}
      onChange={onChange}
    />
  );
}

export function useTagFilter(setOffset) {
  const [filter, setFilter] = useState("");
  const setFilterAndResetOffset = useCallback(
    (...args) => {
      setFilter(...args);
      setOffset(0);
    },
    [setFilter, setOffset],
  );
  const searchBar = useMemo(() => {
    return (
      <div className={style.tagManager__searchBar}>
        <TagFilter filter={filter} setFilter={setFilterAndResetOffset} />
      </div>
    );
  }, [filter, setFilterAndResetOffset]);
  return {
    filter,
    searchBar,
  };
}
