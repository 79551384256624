import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useUserService } from "../services";
import { Meeting } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export function useAddUsersToTeams() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    org: string,
    userIds: number[],
    teamIds: number[],
  ): Promise<{ failures: null; instances: Meeting[] }> =>
    service
      .post(`/api/users/teams`)
      .set(headers)
      .send({
        org,
        teamIds,
        userIds,
      })
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/users"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}

export default useAddUsersToTeams;
