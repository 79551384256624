import React from "react";

import Select from "../../../inputs/Select";
import LockedDropdown from "../../../components/dropdown/LockedDropdown";
import { useDefaultSelectTheme } from "../../../components/select";
import { useSalesforceIntegrationObjects } from "../../../hooks/useSalesforceIntegrationObjects";
import { MAPPED_FIELD_OBJECT_TYPES } from "./cardProps";
import { CHANNEL_TYPES } from "../../props";

export function SalesforceObjectSelect({
  value,
  onChange,
  locked,
  disabled,
  integrationId,
}) {
  const options = useSalesforceIntegrationObjects(
    integrationId,
    CHANNEL_TYPES.IMPORT,
  );
  const [components, theme] = useDefaultSelectTheme();

  return (
    <div>
      {locked ? (
        <LockedDropdown value={value?.label} />
      ) : (
        <Select
          disabled={disabled}
          components={components}
          customTheme={theme}
          onChange={onChange}
          options={options
            .map((o) => o.object_key)
            .sort((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            })
            .map((key) => ({
              label: MAPPED_FIELD_OBJECT_TYPES[key],
              type: key,
            }))}
          placeholder={<div>Select Object Type</div>}
          value={value}
        />
      )}
    </div>
  );
}
