import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers";
import {
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  ListItemButton,
  Box,
} from "@mui/material";
import { Dayjs } from "dayjs";

function AvailabilityPicker({
  variant = "horizontal",
  date,
  selection,
  availableSlots,
  onDateChange,
  onSelectionChange,
}: {
  variant?: "horizontal" | "vertical";
  date: Dayjs | null;
  selection: Dayjs | null;
  availableSlots: Dayjs[];
  onDateChange: (newValue: Dayjs | null) => void;
  onSelectionChange: (newValue: Dayjs) => void;
}) {
  const theme = useTheme();

  const availableDays = new Map();
  availableSlots.forEach(function (dt) {
    const dateString = dt.toDate().toDateString();
    if (!availableDays.has(dateString)) {
      availableDays.set(dateString, true);
    }
  });

  let availableTimes: Dayjs[] = [];
  if (date) {
    availableTimes = availableSlots.filter((currentSlot) =>
      currentSlot.isSame(date, "date"),
    );
  }

  return (
    <Box
      sx={{
        alignItems: variant === "horizontal" ? "flex-start" : "center",
        display: "flex",
        flexDirection: variant === "horizontal" ? "row" : "column",
      }}
    >
      <Box sx={{ minWidth: "20em" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarPicker<Dayjs>
            openTo="day"
            date={date}
            disablePast
            shouldDisableDate={(dt) =>
              !dt.isSame(selection, "day") &&
              !availableDays.has(dt.toDate().toDateString())
            }
            onChange={(newValue) => {
              onDateChange(newValue);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          marginLeft: variant === "horizontal" ? theme.spacing(2) : 0,
          minWidth: "12em",
          width: "20em",
        }}
      >
        <Stack
          sx={{
            maxHeight: "20em",
            minWidth: "10em",
            width: "100%",
          }}
          spacing={2}
          paddingTop={theme.spacing(2)}
        >
          <Typography align="center">
            {date ? date.format("dddd, MMM D z") : "No date selected"}
          </Typography>
          <List sx={{ overflow: "auto" }}>
            {date && availableTimes.length === 0 && (
              <ListItem disableGutters>
                <ListItemText
                  sx={{ textAlign: "center" }}
                  primary="No times available"
                />
              </ListItem>
            )}
            {availableTimes.map((slot) => (
              <ListItem key={slot.toISOString()} disableGutters>
                <ListItemButton
                  dense
                  disableGutters
                  selected={slot.isSame(selection)}
                  onClick={() => {
                    onSelectionChange(slot);
                  }}
                  sx={{
                    border: "solid",
                    borderColor: theme.palette.primary.light,
                    borderRadius: "5px",
                    borderWidth: "2px",
                    textAlign: "center",
                  }}
                >
                  <ListItemText primary={slot.format("hh:mm a")} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Box>
    </Box>
  );
}

export default AvailabilityPicker;
