import React, { useCallback, useMemo, useState } from "react";
import { mdiTagOffOutline } from "@mdi/js";
import { isConflictError } from "../../error";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { useTagNameInput } from "./TagManagerNameInput";
import { NewTagForm } from "./TagManagerNewTagForm";

export function useCreateNewTag(
  createTag,
  toggleTag,
  setCreatedMeetingTags,
  onModify,
) {
  const [isNewTagFormShowing, setIsNewTagFormShowing] = useState(false);
  const {
    name: newTagName,
    isValid: isValidTagName,
    input: tagNameInput,
    reset,
  } = useTagNameInput();
  const showNewMeetingForm = useCallback(() => {
    reset();
    setIsNewTagFormShowing(true);
  }, [reset, setIsNewTagFormShowing]);
  const hideNewMeetingForm = useCallback(() => {
    setIsNewTagFormShowing(false);
  }, [setIsNewTagFormShowing]);
  const { addGeneralNotification } = useGeneralNotifications();
  const removeNewlyCreatedTags = useCallback(
    (tagIds) => {
      let deletedTags = false;
      setCreatedMeetingTags((previousTags) => {
        const hasTagsToBeRemoved = previousTags.some((t) =>
          tagIds.includes(t.id),
        );
        if (!hasTagsToBeRemoved) {
          return previousTags;
        }
        deletedTags = true;
        return previousTags.filter((t) => !tagIds.includes(t.id));
      });
      return deletedTags;
    },
    [setCreatedMeetingTags],
  );
  const createNewTag = useCallback(async () => {
    try {
      const newTag = await createTag({ name: newTagName.trim() });
      // 1. Add it to selected tags
      toggleTag(newTag);
      // 2. Add it to new MeetingTagList
      setCreatedMeetingTags((prev) => [newTag, ...prev]);
      hideNewMeetingForm();
      // If there is a listener for tag modifications call it
      if (onModify) {
        onModify();
      }
    } catch (error) {
      if (isConflictError(error)) {
        addGeneralNotification(
          `Tag ${newTagName} already exists`,
          mdiTagOffOutline,
        );
      }
    }
  }, [
    createTag,
    hideNewMeetingForm,
    newTagName,
    toggleTag,
    setCreatedMeetingTags,
    addGeneralNotification,
    onModify,
  ]);
  const createNewMeetingTagForm = useMemo(() => {
    return (
      <NewTagForm
        createNewTag={createNewTag}
        hideNewTagForm={hideNewMeetingForm}
        isValidTagName={isValidTagName}
        tagNameInput={tagNameInput}
      />
    );
  }, [createNewTag, hideNewMeetingForm, isValidTagName, tagNameInput]);
  return {
    createNewMeetingTag: createNewTag,
    createNewMeetingTagForm,
    hideNewMeetingForm,
    isNewMeetingTagFormShowing: isNewTagFormShowing,
    removeNewlyCreatedTags,
    showNewMeetingForm,
  };
}
