import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { validateEmailAddress } from "../utils/helpers";
import PrimaryButton from "./buttons/PrimaryButton";
import AvailabilityPicker from "./AvailabilityPicker";
import dayjs from "dayjs";
import { useHostAvailabilityForOneClick } from "../queries/useHostAvailabilityForOneClick";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import OneClickLogoImage from "./OneClickLogoImage";
import { isEnterpriseMode } from "src/configuration/getApplicationMode";

export function OneClickLogin({
  oneClickToken,
  meetingId,
  onSubmit,
}: {
  oneClickToken?: string;
  meetingId?: string;
  onSubmit: (emaill: string, meetingStartTime: dayjs.Dayjs | null) => void;
}) {
  const [email, setEmail] = useState("");
  const theme = useTheme();

  let termsURL = "https://www.kronologic.com/eula";
  if (isEnterpriseMode) {
    termsURL = "https://www.kronologic.com/terms";
  }

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const { addError } = useGeneralNotifications();

  const [meetingStartTime, setMeetingStartTime] = useState<dayjs.Dayjs | null>(
    null,
  );
  const [selection, setSelection] = useState<dayjs.Dayjs | null>(null);

  // backend will only provide meeting id for "link-first" meeting invite style.
  const isLinkFirstInviteStyle = !!meetingId;

  const { data, loading, error } = useHostAvailabilityForOneClick(
    oneClickToken,
    meetingId,
  );

  useEffect(() => {
    if (!isLinkFirstInviteStyle || !data?.availableTimes?.[0]) {
      return;
    }

    const firstAvailableTime = dayjs(data?.availableTimes[0]);
    setMeetingStartTime(firstAvailableTime);
    setSelection(firstAvailableTime);
  }, [data, isLinkFirstInviteStyle]);

  if (loading) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    addError("Error getting meeting host availability");
    console.error(error);
  }

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Box sx={{ marginBottom: "25px" }}>
        <OneClickLogoImage />
      </Box>
      <Typography variant="h5">Welcome to 1-Click Scheduling</Typography>

      {isLinkFirstInviteStyle && (
        <>
          <Typography variant="caption">Select a date and time</Typography>
          <AvailabilityPicker
            variant={md ? "horizontal" : "vertical"}
            date={meetingStartTime}
            selection={selection}
            availableSlots={
              data?.availableTimes.map((availableTime) =>
                dayjs(availableTime),
              ) || []
            }
            onDateChange={(newDate) => {
              setMeetingStartTime(newDate);

              const newAvailableTimes = data?.availableTimes.filter(
                (availableTime) => dayjs(availableTime).isSame(newDate, "date"),
              );
              if (newAvailableTimes?.[0]) {
                setSelection(dayjs(newAvailableTimes[0]));
              }
            }}
            onSelectionChange={(selectedTime) => {
              setSelection(selectedTime);
              setMeetingStartTime(selectedTime);
            }}
          />
        </>
      )}

      <Typography variant="caption">
        Enter your email to schedule your requested meeting.
      </Typography>
      <Box sx={{ marginTop: theme.spacing(4) }}>
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          sx={{ marginBottom: theme.spacing(3) }}
          onChange={(event) => setEmail(event.target.value)}
        />
        <PrimaryButton
          nowrap
          uppercase
          size={"large"}
          disabled={!validateEmailAddress(email) || !!error}
          onClick={() => onSubmit(email, meetingStartTime)}
          sx={{ marginBottom: theme.spacing(4) }}
        >
          Schedule My Meeting
        </PrimaryButton>
        <Typography align="center" variant="caption" paragraph>
          By providing your email you agree to Kronologic's{" "}
          <Link href={termsURL}>Terms of Service</Link> and{" "}
          <Link href="https://www.kronologic.com/privacy">Privacy Policy</Link>
        </Typography>
      </Box>
    </Box>
  );
}
