import Papa from "papaparse";
import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useUserService } from "../services";
import { ContactWithDetails } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

function stringToArray(text: string): Promise<number[]> {
  return new Blob([text], { type: "text/csv" })
    .arrayBuffer()
    .then((a) => [...new Uint8Array(a)]);
}

export function useUploadContacts() {
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const accessToken = getUserToken();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    contacts: Partial<
      Omit<ContactWithDetails & { meetingCoHost: string }, "id">
    >[],
    meetingTypeId?: number,
    tags?: number[],
  ): Promise<void> => {
    return stringToArray(
      Papa.unparse(
        contacts.map((contact) => [
          contact.firstName || "",
          contact.lastName || "",
          contact.email || "",
          contact.account || "",
          contact.phone || "",
          contact.state || "",
          contact.zip || "",
          contact.meetingCoHost || "",
          contact.logicField || "",
          contact.leadScore || "",
          contact.routingField || "",
          contact.customField1 || "",
          contact.customField2 || "",
          contact.customField3 || "",
          contact.customField4 || "",
          contact.customField5 || "",
          contact.customField6 || "",
          contact.customField7 || "",
          contact.customField8 || "",
          contact.customField9 || "",
          contact.customField10 || "",
          contact.unsubscribed || "",
        ]),
      ),
    ).then((table) =>
      service
        .post(`/api/contact/import/csv`)
        .send({
          table,
          headers: [
            "firstName",
            "lastName",
            "email",
            "account",
            "phone",
            "state",
            "zip",
            "meetingCoHost",
            "logic_field_1",
            "logic_field_2",
            "routing_field",
            "custom_field_1",
            "custom_field_2",
            "custom_field_3",
            "custom_field_4",
            "custom_field_5",
            "custom_field_6",
            "custom_field_7",
            "custom_field_8",
            "custom_field_9",
            "custom_field_10",
            "unsubscribed",
          ],
          meta: {
            platform: "web",
            source: "contacts_page/upload_contacts",
          },
          meetingTypeId,
          tags,
        })
        .set(headers)
        .then((res: Response) => res.body)
        .then((res: Response) => {
          // @ts-ignore
          const keys = cache.keys();

          // Invalidate contact queries and force refresh.
          Array.from(keys)
            .filter((r: any) => r.startsWith("/api/contact/"))
            .forEach((k: any) => {
              mutate(k);
            });

          return res;
        })
        .catch(errorHandler),
    );
  };
}

export default useUploadContacts;
