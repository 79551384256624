import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import useUrlQueryV2 from "src/hooks/useUrlQueryV2";
import { useReportingCampaign } from "../queries/useReportingCampaign";
import { useReportingCampaignAggregate } from "../queries/useReportingCampaignAggregate";
import { MeetingDefinition, Tag, Team } from "../types";
import { Pagination } from "./Pagination";
import CampaignReportingStats from "./ReportingCampaignStats";
import ReportingCampaignTable from "./ReportingCampaignTable";
import { ReportingFilterBar, ReportingQuery } from "./ReportingFilterBar";
import { MeetingHost } from "./inputs";
import { TableLoading, TableSort } from "./tables/Table";

function ReportingCampaign() {
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [selectedHost, setSelectedHost] = useState<MeetingHost | null>(null);
  const [selectedTemplate, setSelectedTemplate] =
    useState<MeetingDefinition | null>(null);

  const [selectedDateRange, setSelectedDateRange] = useState<
    [Dayjs | null, Dayjs | null]
  >([
    // First month we shipped reporting backend
    dayjs("2023-04-01", "YYYY-MM-DD"),
    dayjs().endOf("month"),
  ]);

  // state for table
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [query, setQuery] = useState<ReportingQuery>(defaultReportingQuery);
  const [templateFilter, setTemplateFilter] = useState<number[]>([]);
  const history = useHistory();
  const urlQuery = useUrlQueryV2();

  useEffect(() => {
    const templateQuery = urlQuery.getAll("templateId");
    setTemplateFilter(
      templateQuery.map((templateQueryItem) => Number(templateQueryItem)),
    );
  }, [urlQuery]);

  const [sortModel, setSortModel] = useState<TableSort>({
    columnId: "meetingsCount",
    order: "desc",
  });

  useEffect(() => {
    // reset back to page one when filters change
    setPage(0);
  }, [selectedTeams, selectedTags, selectedTemplate]);

  const { data, loading, error } = useReportingCampaign({
    limit: pageSize,
    offset: page * pageSize,
    sortBy: sortModel?.columnId,
    sortOrder: sortModel?.order,
    team: query.team.map((team) => team.id),
    meetingTags: query.meetingTags.map((tag) => tag.id),
    templateTags: query.templateTags.map((tag) => tag.id),
    user: query.host.map((host) => host.id),
    meetingDefinitionIds: templateFilter, // allow multiple? API allows it, design wants one
    dateFrom: query.date.range[0]?.toISOString(),
    dateTo: query.date.range[1]?.endOf("day").toISOString(),
  });

  const meetingTemplatesTotal = useMemo(() => data?.total, [data?.total]);

  const {
    data: aggregateData,
    loading: aggregateDataLoading,
    error: aggregateDataError,
  } = useReportingCampaignAggregate({
    team: query.team.map((team) => team.id),
    meetingTags: query.meetingTags.map((tag) => tag.id),
    templateTags: query.templateTags.map((tag) => tag.id),
    user: query.host.map((host) => host.id),
    meetingDefinitionIds: templateFilter, // allow multiple? API allows it, design wants one
    dateFrom: query.date.range[0]?.toISOString(),
    dateTo: query.date.range[1]?.endOf("day").toISOString(),
  });

  const total = data?.total || 0;

  return (
    <Stack sx={{ gap: "20px" }}>
      {(error || aggregateDataError) && (
        <Typography>
          Unable to retrieve reporting data, please try again later.
        </Typography>
      )}

      <Paper elevation={0}>
        <Stack direction="row" spacing={2} sx={{ padding: 2, gap: "10px" }}>
          <ReportingFilterBar
            filters={query}
            templateFilter={templateFilter}
            onFilterUpdate={(newFilters) => setQuery(newFilters)}
            onTemplateFilterUpdate={(templates) => {
              urlQuery.delete("templateId");
              templates.forEach((templateItem) =>
                urlQuery.append("templateId", templateItem.id.toString()),
              );
              history.push({
                search: `?${urlQuery.toString()}`,
              });
            }}
            onReset={() => {
              setQuery(defaultReportingQuery);
              history.push({
                search: "",
              });
            }}
          />
        </Stack>
      </Paper>

      {aggregateDataLoading && <CircularProgress />}
      {aggregateData && (
        <CampaignReportingStats
          data={{
            total: meetingTemplatesTotal,
            aggregateData: aggregateData,
          }}
        />
      )}

      <Paper elevation={0} sx={{ p: 2 }}>
        {loading && <TableLoading />}

        {data && (
          <ReportingCampaignTable
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sortModel={sortModel}
            setSortModel={setSortModel}
            data={data}
          />
        )}
        {!loading && total > 0 && (
          <Pagination
            pages={Math.ceil(total / pageSize)}
            currentPage={page + 1}
            onPageChange={(value) => setPage(value - 1)}
          />
        )}
      </Paper>
    </Stack>
  );
}

const defaultReportingQuery: ReportingQuery = {
  host: [],
  hostFilterSearch: "",
  template: [],
  team: [],
  teamFilterSearch: "",
  meetingTags: [],
  templateTags: [],
  date: {
    field: "lastActivity",
    range: [null, null],
  },
  meetingTagFilterSearch: "",
  templateTagFilterSearch: "",
  templateFilterSearch: "",
};

export default ReportingCampaign;
