import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

function MergeFieldsSelect({
  onSelect = () => null,
}: {
  onSelect: (value: string) => void;
}) {
  return (
    <FormControl sx={{ width: "175px" }}>
      <Select
        sx={{ marginLeft: 2 }}
        labelId="merge-tags-select"
        id="merge-tags-select"
        value="placeholder"
        variant="standard"
        onChange={(event: SelectChangeEvent) => {
          onSelect(event.target.value);
        }}
      >
        <MenuItem value="placeholder" style={{ display: "none" }}>
          Merge Fields
        </MenuItem>
        <MenuItem value="{{greeting}}">Greeting</MenuItem>
        <MenuItem value="{{guest_account}}">Guest Account</MenuItem>
        <MenuItem value="{{guest_first_name}}">Guest First Name</MenuItem>
        <MenuItem value="{{guest_last_name}}">Guest Last Name</MenuItem>
        <MenuItem value="{{guest_logic_field}}">Guest Logic Field</MenuItem>
        <MenuItem value="{{guest_logic_field_2}}">Guest Logic Field 2</MenuItem>
        <MenuItem value="{{guest_phone}}">Guest Phone</MenuItem>
        <MenuItem value="{{host_company}}">Host Company</MenuItem>
        <MenuItem value="{{host_first_name}}">Host First Name</MenuItem>
        <MenuItem value="{{host_last_name}}">Host Last Name</MenuItem>
        <MenuItem value="{{host_location}}">Host Location</MenuItem>
        <MenuItem value="{{host_title}}">Host Title</MenuItem>
        <MenuItem value="{{host_signature}}">Host Signature</MenuItem>
        <MenuItem value="{{meeting_date_time}}">Meeting Date & Time</MenuItem>
        <MenuItem value="{{meeting_day_of_week}}">Meeting Day of Week</MenuItem>
        <MenuItem value="{{meeting_duration}}">Meeting Duration</MenuItem>
        <MenuItem value="{{meeting_link}}">Meeing Link</MenuItem>
        <MenuItem value="{{meeting_month_day}}">Meeting Month & Day</MenuItem>
        <MenuItem value="{{meeting_time}}">Meeting Time</MenuItem>
        <MenuItem value="{{time_casual_day}}">Time Casual Day</MenuItem>
        <MenuItem value="{{time_number_date}}">Time Number Date</MenuItem>
        <MenuItem value="{{previous_year}}">Previous Year</MenuItem>
        <MenuItem value="{{current_year}}">Current Year</MenuItem>
        <MenuItem value="{{next_year}}">Next Year</MenuItem>
        <MenuItem value="{{guest_custom_field_1}}">
          Guest Custom Field 1
        </MenuItem>
        <MenuItem value="{{guest_custom_field_2}}">
          Guest Custom Field 2
        </MenuItem>
        <MenuItem value="{{guest_custom_field_3}}">
          Guest Custom Field 3
        </MenuItem>
        <MenuItem value="{{guest_custom_field_4}}">
          Guest Custom Field 4
        </MenuItem>
        <MenuItem value="{{guest_custom_field_5}}">
          Guest Custom Field 5
        </MenuItem>
        <MenuItem value="{{guest_custom_field_6}}">
          Guest Custom Field 6
        </MenuItem>
        <MenuItem value="{{guest_custom_field_7}}">
          Guest Custom Field 7
        </MenuItem>
        <MenuItem value="{{guest_custom_field_8}}">
          Guest Custom Field 8
        </MenuItem>
        <MenuItem value="{{guest_custom_field_9}}">
          Guest Custom Field 9
        </MenuItem>
        <MenuItem value="{{guest_custom_field_10}}">
          Guest Custom Field 10
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default MergeFieldsSelect;
