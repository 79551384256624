import React from "react";
import cx from "classnames";
import Icon from "@mdi/react";

import style from "./buttonv2.module.scss";

export function ButtonIcon({ className, path, size = 1, ...props }) {
  return (
    <Icon
      className={cx(style.button__containerIcon, className)}
      path={path}
      size={size}
      {...props}
    />
  );
}

export function ButtonText({ className, children, ...props }) {
  return (
    <div className={cx(style.button__text, className)} {...props}>
      {children}
    </div>
  );
}

export function ButtonLink({
  className,
  children,
  disabled: isDisabled,
  legacy: isLegacy,
  transparent: isTransparent,
  name,
  ...props
}) {
  return (
    <button
      aria-label={name}
      name={name}
      type="button"
      className={cx(
        style.button__link,
        {
          [style["button__container--disabled"]]: isDisabled,
          [style["button__container--transparent"]]: isTransparent,
        },
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export function ButtonContainer({
  className,
  children,
  dataCy = null,
  dataGtm = null,
  disabled: isDisabled = false,
  large: isLarge = false,
  legacyPrimary: isLegacyPrimary = false,
  legacySecondary: isLegacySecondary = false,
  medium: isMedium = false,
  name = null,
  small: isSmall = false,
  primary: isPrimary = false,
  secondary: isSecondary = false,
  transparent: isTransparent = false,
  ...props
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  return (
    <button
      aria-label={name}
      name={name}
      disabled={isDisabled}
      type="button"
      className={cx(
        style.button__container,
        {
          [style["button__container--disabled"]]: isDisabled,
          [style["button__container--large"]]: isLarge,
          [style["button__container--medium"]]: isMedium,
          [style["button__container--small"]]: isSmall,
          [style["button__container--primary"]]: isPrimary,
          [style["button__container--secondary"]]: isSecondary,
          [style["button__container--transparent"]]: isTransparent,
          [style["button__container--legacyPrimary"]]: isLegacyPrimary,
          [style["button__container--legacySecondary"]]: isLegacySecondary,
        },
        className,
      )}
      {...props}
      {...dataProps}
    >
      {children}
    </button>
  );
}
