import React, { forwardRef } from "react";
import cx from "classnames";

import style from "./style.module.scss";

function SpacingWithRef(
  { className, horizontal: isHorizontalOnly, vertical: isVerticalOnly },
  ref,
) {
  return (
    <div
      className={cx(className, {
        [style["spacing--horizontal-only"]]: isHorizontalOnly,
        [style["spacing--vertical-only"]]: isVerticalOnly,
      })}
      ref={ref}
    />
  );
}

const Spacing = forwardRef(SpacingWithRef);

export function TinySpacing(props) {
  return <Spacing className={style["spacing--tiny"]} {...props} />;
}

export function XSmallSpacing(props) {
  return <Spacing className={style["spacing--x-small"]} {...props} />;
}

export function SmallSpacing(props) {
  return <Spacing className={style["spacing--small"]} {...props} />;
}

export function MediumSpacing(props) {
  return <Spacing className={style["spacing--med"]} {...props} />;
}

export function LargeSpacing(props) {
  return <Spacing className={style["spacing--large"]} {...props} />;
}

export function XLargeSpacing(props) {
  return <Spacing className={style["spacing--x-large"]} {...props} />;
}

export function XXLargeSpacing(props) {
  return <Spacing className={style["spacing--xx-large"]} {...props} />;
}

export function XXXLargeSpacing(props) {
  return <Spacing className={style["spacing--xxx-large"]} {...props} />;
}

function JumboSpacingWithRef(props, ref) {
  return (
    <Spacing className={cx(style["spacing--jumbo"], {})} ref={ref} {...props} />
  );
}
export const JumboSpacing = forwardRef(JumboSpacingWithRef);

export function SuperJumboSpacing(props) {
  return <Spacing className={style["spacing--super-jumbo"]} {...props} />;
}
