import { useRef, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import { Check, Close, Delete, Edit } from "@mui/icons-material";

import { Loading } from "src/componentsV2/Loading";
import { OrgAdminRole, Team } from "src/types";
import { PrimaryButton } from "src/componentsV2/buttons/PrimaryButton";
import { supersedes } from "src/auth/roles";
import { TagList } from "src/componentsV2/TagList";
import { useActingAs } from "src/auth";
import { useCreateTeam, useDeleteTeams, useUpdateTeam } from "src/mutations";
import { useDebounce, useConfirmationDialog, useSnackbar } from "src/hooks";
import { useTeams } from "src/queries";
import Dialog from "src/componentsV2/dialogs/Dialog";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { getUserDetails } from "src/utils/jwtToken";

function TeamManagementDialog(props: {
  label: string;
  open: boolean;
  initialTeams?: Pick<Team, "id" | "name">[];
  onSubmit: (teams: Pick<Team, "id" | "name">[]) => void;
  onClose: () => void;
}) {
  const [teams, setTeams] = useState<Pick<Team, "id" | "name">[]>(
    props.initialTeams || [],
  );

  const [openSnackbar] = useSnackbar();
  const updateTeam = useUpdateTeam();
  const deleteTeams = useDeleteTeams();
  const openConfirmationDialog = useConfirmationDialog();

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();

  // Determine if the user is allowed to edit or create teams.
  const editable = supersedes({
    has: actingAs?.role || loggedInUser?.role,
    required: OrgAdminRole,
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={props.label}
      actions={
        <>
          <PrimaryButton
            onClick={() => {
              props.onSubmit(teams);
            }}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ pt: 1 }}>
        <AddTeamInput
          onSelect={(team) => {
            if (!teams.map((t) => t.id).includes(team.id)) {
              setTeams(teams.concat([team]));
            }
          }}
          onEdit={async (team) => {
            try {
              await updateTeam(team.id, team.name);
              setTeams(teams.filter((t) => team.id != t.id).concat([team]));
            } catch {
              openSnackbar("Failed to update team");
            }
          }}
          onDelete={(team) => {
            openConfirmationDialog(
              "Delete confirmation",
              `Are you sure you want to delete the ${team.name}`,
              async () => {
                try {
                  await deleteTeams([team.id]);
                  setTeams(teams.filter((t) => team.id != t.id));
                } catch {
                  openSnackbar("Failed to delete team");
                }
              },
            );
          }}
          editable={editable}
        />
        <Typography fontWeight="bold">Current Teams</Typography>
        {teams.length > 0 ? (
          <TagList
            tags={teams}
            onDelete={(team) => {
              if (team.name !== "Global") {
                setTeams(teams.filter((t) => team.id != t.id));
              }
            }}
          />
        ) : (
          <Typography>No teams selected</Typography>
        )}
      </Stack>
    </Dialog>
  );
}

function TeamListItemEditMode(props: {
  value: Team;
  onSave: (value: string) => void;
  onClose: () => void;
}) {
  const [input, setInput] = useState(props.value.name);

  const debouncedInput = useDebounce(input, 750);
  const { data, loading } = useTeams(5, 0, debouncedInput);

  const loaded = debouncedInput === input && !loading;
  const empty = input.length < 1;
  const same = input.toLowerCase() === props.value.name.toLowerCase();
  const exists =
    data?.data.some(
      (team) => team.name.toLowerCase() === input.toLowerCase(),
    ) && !same;

  return (
    <ListItem
      secondaryAction={
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            disabled={!loaded || exists || empty || same}
            onClick={() => {
              props.onSave(input);
            }}
          >
            {!loaded ? (
              <CircularProgress size={20} />
            ) : (
              <Check fontSize="small" />
            )}
          </IconButton>

          <IconButton
            size="small"
            onClick={() => {
              props.onClose();
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      }
    >
      <TextField
        fullWidth
        autoFocus
        size="small"
        value={input}
        error={exists}
        helperText={
          exists ? "this team already exists, please change name" : ""
        }
        onChange={(event) => {
          setInput(event.target.value);
        }}
        sx={{
          mr: 6,
        }}
      />
    </ListItem>
  );
}

function TeamListItemDisplayMode(props: {
  value: Team;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
  editable?: boolean;
}) {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        props.editable && (
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              onClick={() => {
                props.onEdit();
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={props.onDelete}>
              <Delete fontSize="small" />
            </IconButton>
          </Stack>
        )
      }
    >
      <ListItemButton
        onClick={() => {
          props.onSelect();
        }}
      >
        <ListItemText
          primary={
            <Typography noWrap sx={{ mr: 6 }}>
              {props.value.name}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

function TeamListItem(props: {
  value: Team;
  onSelect: () => void;
  onEdit: (value: string) => void;
  onDelete: () => void;
  editable?: boolean;
}) {
  const [edit, setEdit] = useState(false);

  return edit ? (
    <TeamListItemEditMode
      value={props.value}
      onSave={(name) => {
        props.onEdit(name);
        setEdit(false);
      }}
      onClose={() => setEdit(false)}
    />
  ) : (
    <TeamListItemDisplayMode
      value={props.value}
      editable={props.editable}
      onSelect={props.onSelect}
      onEdit={() => setEdit(true)}
      onDelete={props.onDelete}
    />
  );
}

function AddTeamInput(props: {
  editable?: boolean;
  onSelect: (value: Pick<Team, "id" | "name">) => void;
  onEdit: (value: Pick<Team, "id" | "name">) => void;
  onDelete: (value: Team) => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>();

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [creatingTeam, setCreatingTeam] = useState(false);
  const createTeam = useCreateTeam();
  const [openSnackbar] = useSnackbar();

  const query = useDebounce(input, 750);

  const { data, loading } = useTeams(5, 0, { qry: query });

  const options: Team[] = data?.data || [];

  return (
    <Box>
      <TextField
        inputRef={inputRef}
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
        }}
        variant="outlined"
        label="Search to add or create Teams"
        onClick={() => setOpen(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setOpen(false);
          }
          setOpen(true);
        }}
        autoComplete="off"
        fullWidth
      />
      <Popover
        open={open}
        disablePortal
        disableAutoFocus
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { minWidth: "300px", width: inputRef.current?.offsetWidth },
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <List>
            {options.length > 0 &&
              options.map((team) => (
                <TeamListItem
                  key={team.id}
                  value={team}
                  onSelect={() => {
                    setOpen(false);
                    setInput("");
                    props.onSelect(team);
                  }}
                  onDelete={() => {
                    props.onDelete(team);
                  }}
                  onEdit={(name) => {
                    props.onEdit({ id: team.id, name });
                  }}
                  editable={props.editable}
                />
              ))}
            {input.trim() !== "" &&
              !options.some((team) => team.name.trim() === input.trim()) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={async () => {
                      try {
                        setCreatingTeam(true);
                        const team = await createTeam(input.trim());
                        props.onSelect({ ...team, name: input.trim() });
                      } catch {
                        openSnackbar("Failed to create team");
                      } finally {
                        setCreatingTeam(false);
                      }
                    }}
                  >
                    {creatingTeam ? (
                      <Loading />
                    ) : (
                      <ListItemText
                        primary={
                          <Typography>Create '{input.trim()}'</Typography>
                        }
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              )}
          </List>
        )}
      </Popover>
    </Box>
  );
}

export default TeamManagementDialog;
