import { useCallback, useMemo } from "react";

export function usePagination(total, offset, setOffset, pageSize) {
  const pageOptions = useMemo(() => {
    const totalPages = Math.ceil(total / pageSize);
    if (totalPages < 1 || Number.isNaN(totalPages)) {
      return [];
    }
    return [...Array(totalPages).keys()];
  }, [total, pageSize]);

  const goToPreviousPage = useCallback(() => {
    setOffset((prev) => prev - 1);
  }, [setOffset]);

  const goToPage = useCallback(
    (page) => {
      setOffset(page);
    },
    [setOffset],
  );

  const goToNextPage = useCallback(() => {
    setOffset((prev) => prev + 1);
  }, [setOffset]);

  const pageIndex = useMemo(() => {
    return offset;
  }, [offset]);

  const hasPreviousPage = useMemo(() => {
    return offset !== 0;
  }, [offset]);

  const hasNextPage = useMemo(() => {
    if (pageOptions.length === 0) {
      return false;
    }
    return offset !== pageOptions.length - 1;
  }, [offset, pageOptions]);

  const goToFirstPage = useCallback(() => {
    setOffset(0);
  }, [setOffset]);

  const goToLastPage = useCallback(() => {
    setOffset(pageOptions[pageOptions.length - 1]);
  }, [setOffset, pageOptions]);

  return {
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPage,
    goToPreviousPage,
    hasNextPage,
    hasPreviousPage,
    pageIndex,
    pageOptions,
  };
}
