import { Box, Radio, useTheme } from "@mui/material";

type AccountsEventColorRadioProps = {
  value: {
    name: string;
    displayColor: string;
  };
};
export const AccountsEventColorRadio = (
  props: AccountsEventColorRadioProps,
) => {
  const theme = useTheme();

  return (
    <Radio
      key={props.value.name}
      value={props.value.name}
      icon={
        <Box
          sx={{
            borderRadius: "4px",
            backgroundColor: props.value.displayColor,
            width: "40px",
            height: "40px",
          }}
        />
      }
      checkedIcon={
        <Box
          sx={{
            borderRadius: "4px",
            backgroundColor: props.value.displayColor,
            width: "40px",
            height: "40px",
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: "4px",
          }}
        />
      }
    />
  );
};
