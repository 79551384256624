import React, { useCallback, useEffect, useState } from "react";
import { mdiCloudUploadOutline } from "@mdi/js";
import { useDropzone } from "react-dropzone";
import Icon from "@mdi/react";
import Papa from "papaparse";

import { UserCSVHeaderDropdown } from "./UserCSVHeaderDropdown";
import { CsvHeaderMapping } from "../CsvHeaderMapping";
import { ImportFooter } from "../ImportFooter";
import { Loading } from "src/componentsV2/Loading";
import { PrimaryButton } from "src/componentsV2/buttons/PrimaryButton";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { UserUpload } from "./UserUpload";
import { OrgAdminRole, Role } from "src/types";

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
};

export function Upload({ onNext }: { onNext: (data: UserUpload) => void }) {
  const [file, setFile] = useState<File | null>(null);
  const [rawData, setRawData] = useState<Array<string[]>>([]);
  const [hasHeaders, setHasHeaders] = useState(true);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<string | null>(null);

  const [mapping, setMapping] = useState<{ [index: string]: string }>({});

  const onDrop = useCallback(
    (selectedFile) => {
      if (selectedFile && selectedFile.length) {
        setFile(selectedFile[0]);
      }
    },
    [setFile],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop,
  });

  useEffect(() => {
    if (file) {
      setLoading(true);
      Papa.parse(file, {
        worker: true,
        skipEmptyLines: "greedy",
        complete: (results) => {
          if (results.errors.length) {
            setRawData([]);
            setError("There was an error while parsing the CSV");
          } else {
            setRawData(results.data as Array<string[]>);
            setError(null);
          }
          setLoading(false);
        },
      });
    }
  }, [file]);

  const data = hasHeaders ? rawData.slice(1) : rawData;

  // Attempt to guess at the headers automatically.
  useEffect(() => {
    const headers: string[] = hasHeaders ? rawData?.[0] || [] : [];
    setMapping(
      headers.reduce((prev, curr, index) => {
        const header = curr.toLowerCase();
        // if (header.includes("first")) {
        //   return { ...prev, [index]: "firstName" };
        // }
        // if (header.includes("last")) {
        //   return { ...prev, [index]: "lastName" };
        // }
        if (header.includes("email")) {
          return { ...prev, [index]: "email" };
        }
        if (header.includes("role")) {
          return { ...prev, [index]: "role" };
        }
        return { ...prev, [index]: "select" };
      }, {}),
    );
  }, [hasHeaders, rawData]);

  // boolean used to indicate if the user has specify which column is the email column.
  const emailSelected = Object.values(mapping).includes("email");
  // boolean used to indicate if a user has specified the same column more than once.
  const duplicateColumnsSelected =
    new Set(Object.values(mapping).filter((v) => v !== "select")).size !==
    Object.values(mapping).filter((v) => v !== "select").length;

  return (
    <>
      <Box sx={{ width: "100%", minHeight: "400px" }}>
        <Stack spacing={2}>
          {file ? (
            <>
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack>
                    <Typography>
                      Uploading contacts in: <strong>{`${file.name}`}</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>Contains headers</Typography>
                      <Stack direction="row" spacing={0} alignItems="center">
                        <Typography>No</Typography>
                        <Switch
                          checked={hasHeaders}
                          onChange={(_, checked) => {
                            setHasHeaders(checked);
                          }}
                        />
                        <Typography>Yes</Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <PrimaryButton onClick={() => setFile(null)}>
                    Choose a Different File
                  </PrimaryButton>
                </Stack>
                {!loading && (
                  <Stack spacing={1}>
                    <CsvHeaderMapping
                      dropdown={(props) => <UserCSVHeaderDropdown {...props} />}
                      data={data}
                      value={mapping}
                      onChange={setMapping}
                    />
                    {!emailSelected && (
                      <Typography color="error" align="right">
                        The 'email' column is required
                      </Typography>
                    )}
                    {duplicateColumnsSelected && (
                      <Typography color="error" align="right">
                        You may only specifiy each column once
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            </>
          ) : (
            <Stack alignItems="center" spacing={2}>
              <div
                {...getRootProps()}
                style={{
                  minHeight: "150px",
                  minWidth: "300px",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <Stack alignItems="center">
                  <Icon path={mdiCloudUploadOutline} size={5} />
                  <span>Drag &amp; drop, or click here to select file</span>
                </Stack>
              </div>
              <PrimaryButton
                onClick={() => {
                  downloadBlob(
                    new Blob(["Email,Role\n"], {
                      type: "text/plain",
                    }),
                    "invite_users.csv",
                  );
                }}
              >
                Use Template
              </PrimaryButton>
            </Stack>
          )}
          {loading && <Loading />}
        </Stack>
      </Box>
      <ImportFooter
        leftButtonDisabled
        rightButtonDisabled={
          !emailSelected || duplicateColumnsSelected || loading
        }
        onRightButtonClick={() => {
          // Convert the raw table to a list of structured JSON objects.
          const contacts = data.map((row) => {
            return row.reduce((p, c, i) => {
              // If the current column is in the column map then add it to our object. If
              // not then simply pass over it.
              if (i in mapping) {
                // We will extract the property name from the map and take the value from
                // the current column of the row.
                return { ...p, [mapping[i]]: c };
              }
              return p;
            }, {});
          }) as UserUpload;

          onNext(
            // Assign all users a role of default user is not otherwise specified.
            contacts.map((contact) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore contact.role will be a string here
              const role = Number.parseInt(contact.role);
              return {
                ...contact,
                role: (Number.isNaN(role) || role > OrgAdminRole
                  ? -1
                  : role) as Role,
              };
            }),
          );
        }}
      />
    </>
  );
}

export default Upload;
