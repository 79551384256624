import React from "react";
import { useMeeting } from "../state";
import Flexbox from "../../../components/flexbox/FlexboxV2";
import { P1 } from "../../../components/typography";
import { FINAL_INSTANCE_STAGES } from "../../props";
import HostSelect from "../../hostSelect";
import { useUpdateMeetings } from "../../../mutations";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";

function HostEmail() {
  const [meeting] = useMeeting();

  const { addError } = useGeneralNotifications();
  const updateMeetings = useUpdateMeetings();

  const canChangeHost =
    !meeting.enabled && !FINAL_INSTANCE_STAGES.includes(meeting.status);
  const host = meeting?.host || null;
  return (
    <>
      {canChangeHost && (
        <HostSelect
          host={host}
          setHost={(newHost) => {
            updateMeetings([{ id: meeting?.id, user: newHost.id }]).catch(
              () => {
                addError("Failed to update meeting host");
              },
            );
          }}
        />
      )}
      {!canChangeHost && (
        <Flexbox.Row alignItems="center">
          <P1>{meeting?.host?.email}</P1>
        </Flexbox.Row>
      )}
    </>
  );
}

export default HostEmail;
