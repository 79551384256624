import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { PersonalAccessToken } from "../../../../../types/PersonalAccessToken";
import SecondaryButton from "../../../../../componentsV2/buttons/SecondaryButton";
import PrimaryButton from "../../../../../componentsV2/buttons/PrimaryButton";
import { useDisablePAT } from "../../../../../mutations/useDisablePAT";
import useGeneralNotifications from "../../../../../hooks/useGeneralNotifications";

// DisablePATDialog warns users about disabling a personal access token
function DisablePATDialog({
  token,
  onClose,
}: {
  token: PersonalAccessToken;
  onClose: () => void;
}) {
  const disablePAT = useDisablePAT();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          Disable{" "}
        </Typography>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          {token.description}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="span">
          You are about to disable the following Personal Access Token:
        </Typography>
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {` ${token.description}. `}
        </Typography>
        <Typography component="span">
          While this token is disabled, any applications using this token will
          not be able to access the Kronologic API. This token may be re-enabled
          at any time.
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={async () => {
            try {
              await disablePAT(token.id);
              addGeneralNotification(`${token.description} disabled`);
              onClose();
            } catch {
              addError(
                `There was a problem disabling ${token.description}. Please try again later.`,
              );
            }
          }}
        >
          Confirm
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default DisablePATDialog;
