import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { ExportMeetingsQuery, useExportMeetings } from "../../../../mutations";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";
import { PageLayout } from "../../../../componentsV2/PageLayout";
import PrimaryButton from "../../../../componentsV2/buttons/PrimaryButton";
import Input from "../../../../componentsV2/inputs/Input";
import { Loading } from "../../../../componentsV2/Loading";
import AttemptsFilter from "../../../../importExport/export/filters/AttemptsFilter";
import MeetingTemplateFilter from "../../../../importExport/export/filters/MeetingTemplateFilter";
import TagFilter from "../../../../importExport/export/filters/TagFilter";
import GuestStatusFilter from "../../../../importExport/export/filters/GuestStatusFilter";
import MeetingStatusFilter from "../../../../importExport/export/filters/MeetingStatusFilter";
import TimeFilter, {
  TimeFilterBy,
} from "../../../../importExport/export/filters/TimeFilter";
import {
  guestStatusOptionFromValue,
  meetingStatusOptionFromValue,
  GuestStatusOption,
  isValidGuestStatus,
  isValidMeetingStatus,
  MeetingSearchCategory,
  MeetingStatusOption,
  Tag,
} from "../../../../types";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { useUrlQuery } from "../../../../hooks";
import { useNewMeetingsPage } from "src/features";
import { SkinnyMeetingDefinition } from "../../../../queries";

function ExportMeetings() {
  const query = useUrlQuery();

  let initialTags;
  try {
    initialTags = JSON.parse(query.tags);
  } catch {
    // do nothing
  }

  let initialMeetingTemplates;
  try {
    initialMeetingTemplates = JSON.parse(query.templates);
  } catch {
    // do nothing
  }

  let initialFilterBy;
  if (query.filterBy === "lastActivity") {
    initialFilterBy = "lastActivity";
  }
  if (query.filterBy === "startTime") {
    initialFilterBy = "meetingTime";
  }

  let initialStart;
  if (!isNaN(Date.parse(query.end))) {
    initialStart = dayjs(query.start);
  }

  let initialEnd;
  if (!isNaN(Date.parse(query.end))) {
    initialEnd = dayjs(query.end);
  }

  const [loading, setLoading] = useState(false);

  const [guestEmail, setGuestEmail] = useState(query.guestEmail || "");
  const [hostEmail, setHostEmail] = useState(query.hostEmail || "");
  const [notes, setNotes] = useState(query.notes || "");
  const [search, setSearch] = useState(query.search || "");
  const [filterBy, setFilterBy] = useState<TimeFilterBy>(
    (initialFilterBy as TimeFilterBy) || "lastActivity",
  );
  const [timeRange, setTimeRange] = useState<DateRange<Dayjs>>([
    initialStart || null,
    initialEnd || null,
  ]);
  const [tags, setTags] = useState<Tag[]>(initialTags || []);
  const [meetingTemplates, setMeetingTemplates] = useState<
    SkinnyMeetingDefinition[]
  >(initialMeetingTemplates || []);
  const [guestStatuses, setGuestStatuses] = useState<GuestStatusOption[]>(
    (query.guestStatuses
      ?.split(",")
      .filter((v) => isValidGuestStatus(v))
      .map((v) => guestStatusOptionFromValue(v)) as GuestStatusOption[]) || [],
  );
  const [meetingStatuses, setMeetingStatuses] = useState<MeetingStatusOption[]>(
    (query.meetingStatuses
      ?.split(",")
      .filter((v) => isValidMeetingStatus(v))
      .map((v) => meetingStatusOptionFromValue(v)) as MeetingStatusOption[]) ||
      [],
  );
  const [includeAttempts, setIncludeAttempts] = useState(false);
  const [searchCategories, setSearchCategories] = useState<
    MeetingSearchCategory[]
  >(
    (query.categories?.split(",") as MeetingSearchCategory[]) || [
      "Guest Email",
    ],
  );
  const [timeRangeSelection, setTimeRangeSelection] = useState(query.timeRange);

  const history = useHistory();
  const exportMeetings = useExportMeetings();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  const newMeetingsPage = useNewMeetingsPage();

  const startProcess = () => {
    let filters: any = { categories: searchCategories, query: search };

    if (newMeetingsPage) {
      filters = { guestEmail, hostEmail, notes, search };
    }

    const body: ExportMeetingsQuery = {
      filter: {
        ...filters,
        guestStatusFilter: guestStatuses.map((i) => i.value),
        meetingStatusFilter: meetingStatuses.map((i) => i.value),
        meetingTypeFilter: meetingTemplates.map((i) => i.id),
        tagFilter: tags.map((i) => i.id),
      },
      select: {
        dialogs: includeAttempts,
      },
      sort: {
        field: filterBy,
        order: "desc",
      },
    };

    if (filterBy === "lastActivity") {
      body.filter.lastActivityFilter = {
        timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone || "UTC",
      };
      if (timeRange[1]) {
        body.filter.lastActivityFilter.end = timeRange?.[1];
      }
      if (timeRange[0]) {
        body.filter.lastActivityFilter.start = timeRange?.[0];
      }
    } else {
      body.filter.dateFilter = {
        timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone || "UTC",
      };
      if (timeRange[1]) {
        body.filter.dateFilter.end = timeRange?.[1];
      }
      if (timeRange[0]) {
        body.filter.dateFilter.start = timeRange?.[0];
      }
    }

    setLoading(true);
    exportMeetings(body)
      .then(() => {
        addGeneralNotification("Request submitted successfully");
        history.push("/reports");
      })
      .catch((err) => {
        console.error(err);
        addError(
          "We are unable to process this request. Please try again later",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageLayout
      title="Export Meetings"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Export", route: "/reports/export" },
        { name: "Meetings", route: "/reports/export/meetings" },
      ]}
    >
      <Stack spacing={2}>
        <FormLabel>Search</FormLabel>
        {newMeetingsPage ? (
          <>
            <TextField
              label="Guest Email"
              value={guestEmail}
              onChange={(event) => setGuestEmail(event.target.value)}
            />
            <TextField
              label="Host Email"
              value={hostEmail}
              onChange={(event) => setHostEmail(event.target.value)}
            />
            <TextField
              label="Notes"
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
            />
          </>
        ) : (
          <>
            <Input
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={searchCategories}
              onChange={(_, value) => setSearchCategories(value)}
            >
              <ToggleButton value="Guest Email" sx={{ textTransform: "none" }}>
                Guest Email
              </ToggleButton>
              <ToggleButton value="Host Email" sx={{ textTransform: "none" }}>
                Host Email
              </ToggleButton>
              <ToggleButton value="Meeting Id" sx={{ textTransform: "none" }}>
                Meeting Id
              </ToggleButton>
              <ToggleButton
                value="Meeting Template"
                sx={{ textTransform: "none" }}
              >
                Meeting Template
              </ToggleButton>
              <ToggleButton value="Notes" sx={{ textTransform: "none" }}>
                Notes
              </ToggleButton>
              <ToggleButton value="Tag Name" sx={{ textTransform: "none" }}>
                Tag Name
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}

        <FormLabel>Time Filter</FormLabel>
        <TimeFilter
          range={timeRangeSelection}
          onRangeChange={setTimeRangeSelection}
          filterBy={filterBy}
          onFilterByChange={setFilterBy}
          value={timeRange}
          onChange={setTimeRange}
        />
        <FormLabel>Misc Filters</FormLabel>
        <MeetingTemplateFilter
          value={meetingTemplates}
          onChange={setMeetingTemplates}
        />
        <TagFilter value={tags} onChange={setTags} />
        <GuestStatusFilter value={guestStatuses} onChange={setGuestStatuses} />
        <MeetingStatusFilter
          value={meetingStatuses}
          onChange={setMeetingStatuses}
        />
        <AttemptsFilter value={includeAttempts} onChange={setIncludeAttempts} />
        {loading ? (
          <Loading />
        ) : (
          <PrimaryButton onClick={startProcess} disabled={loading}>
            Export
          </PrimaryButton>
        )}
      </Stack>
    </PageLayout>
  );
}

export default ExportMeetings;
