import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import HighEmphasisButton from "./buttons/HighEmphasisButton";
import SecondaryButton from "./buttons/SecondaryButton";
import { Account } from "../pages/settings/accounts/index";
import { useDeleteSelfSubaccount } from "../mutations/useDeleteSelfSubaccount";
import useGeneralNotifications from "../hooks/useGeneralNotifications";

function AccountDeleteModal({
  account,
  onClose,
  fetchAccounts,
  open,
}: {
  account: Account | null;
  onClose: () => void;
  fetchAccounts: () => Promise<void>;
  open: boolean;
}) {
  const deleteAccount = useDeleteSelfSubaccount();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  // for safety, should not be possible in normal course of use
  if (account === null) {
    return <div />;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          Delete{" "}
        </Typography>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          {account.email}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="span">
          You are about to delete the following account:
        </Typography>
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {` ${account.email}. `}
        </Typography>
        <Typography component="span">
          Any meetings or processes currently in flight with this account will
          be rendered invalid. This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <HighEmphasisButton
          onClick={async () => {
            try {
              await deleteAccount(account.userId);
              addGeneralNotification(`${account.email} deleted`);
              fetchAccounts();
              onClose();
            } catch {
              addError(
                `There was a problem deleting ${account.email}. Please try again later.`,
              );
            }
          }}
        >
          Confirm
        </HighEmphasisButton>
      </DialogActions>
    </Dialog>
  );
}

export default AccountDeleteModal;
