import React, { useCallback } from "react";
import { SET_DM_IS_OPEN, useMeeting, useMessengerState } from "../state";
import { P1 } from "../../../components/typography";
import { FINAL_INSTANCE_STAGES, DM_STAGES } from "../../props";
import { ButtonContainer, ButtonText } from "../../../components/button";
import GuestSelect from "../../guestSelect";
import style from "./guestHubGuestListGuestEmail.module.scss";
import { useUpdateMeetings } from "../../../mutations";

const GuestHubGuestListGuestEmail = ({ guest }) => {
  const [meeting] = useMeeting();
  const { messengerDispatch } = useMessengerState();

  const updateMeetings = useUpdateMeetings();

  const meetingId = meeting?.id || null;

  const canChangeGuest =
    !meeting.enabled && !FINAL_INSTANCE_STAGES.includes(meeting.status);
  const setGuest = useCallback(
    (newGuest) => {
      updateMeetings([{ contact: newGuest.id, id: meetingId }]);
    },
    [updateMeetings, meetingId],
  );
  const canOpenDM = DM_STAGES.includes(guest?.status);
  const handleOpenDM = useCallback(() => {
    messengerDispatch((prev) => {
      return {
        payload: {
          value: !prev.dmIsOpen,
        },
        type: SET_DM_IS_OPEN,
      };
    });
  }, [messengerDispatch]);
  return (
    <>
      {canChangeGuest && <GuestSelect guest={guest} setGuest={setGuest} />}
      {!canChangeGuest && (
        <>
          {canOpenDM && (
            <ButtonContainer
              className={style.guestListItemInput__button}
              onClick={handleOpenDM}
            >
              <ButtonText className={style.guestListItemInput__text}>
                {guest?.email}
              </ButtonText>
            </ButtonContainer>
          )}
          {!canOpenDM && (
            <P1 className={style.guestListItemInput__text}>{guest?.email}</P1>
          )}
        </>
      )}
    </>
  );
};

export default GuestHubGuestListGuestEmail;
