import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Loading } from "src/componentsV2/Loading";
import { ContactUpload } from "./ContactUpload";
import { MeetingDefinition, Tag } from "src/types";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useUploadContacts } from "src/mutations";
import { useNewMeetingsPage } from "src/features";

export function Submit({
  contacts,
  meetingTemplate,
  tags,
}: {
  contacts: ContactUpload;
  meetingTemplate: MeetingDefinition | null;
  tags: Tag[];
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const newMeetingsPage = useNewMeetingsPage();

  const uploadContacts = useUploadContacts();

  useEffect(() => {
    if (!submitted) {
      uploadContacts(
        contacts,
        meetingTemplate?.id,
        tags?.map((tag) => tag.id),
      )
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
      setSubmitted(true);
    }
  }, [
    uploadContacts,
    contacts,
    meetingTemplate?.id,
    tags,
    submitted,
    setSubmitted,
  ]);

  if (error) {
    return (
      <Typography>There was an error when uploading the contacts.</Typography>
    );
  }

  let seeMyMeetingsLink = `/instances?${
    tags.length > 0
      ? `tags=${tags.map((t) => `${t.id}:${t.name}`).join(",")}`
      : ""
  }&meetingTypes=${meetingTemplate?.id}:${
    meetingTemplate?.name
  }&categories=Guest+Email`;

  if (newMeetingsPage) {
    seeMyMeetingsLink = `/meetings?templateId=${meetingTemplate?.id}`;
  }

  return loading ? (
    <Loading />
  ) : (
    <Box sx={{ width: "100%", minHeight: "400px" }}>
      <Stack spacing={2}>
        <Typography align="center">Congratulations!</Typography>
        <Typography align="center">Your upload is complete</Typography>
        <Typography align="center">
          View the status of your upload{" "}
          <Link to="/reports" component={RouterLink}>
            here
          </Link>
        </Typography>
        {meetingTemplate && (
          <Link align="center" to={seeMyMeetingsLink} component={RouterLink}>
            See my meetings
          </Link>
        )}
      </Stack>
    </Box>
  );
}

export default Submit;
