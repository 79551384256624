import React from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import { usePageNumbers } from "../components/pagination";
import PrimaryButton from "./buttons/PrimaryButton";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export function Pages({
  pages,
  currentPage,
  onPageSelect,
}: {
  pages: number;
  currentPage: number;
  onPageSelect: (value: number) => void;
}) {
  const pageNumbers: (number | string)[] = usePageNumbers(
    currentPage,
    pages,
    4,
  );

  return (
    <Stack direction="row" spacing={1}>
      {pageNumbers.map((n, idx) =>
        Number.isInteger(n) ? (
          <Button
            key={`button-${idx + 1}`}
            color="primary"
            disabled={currentPage === n}
            sx={{
              "&.Mui-disabled": {
                color: "text.primary",
              },
              fontWeight: n === currentPage ? "bold" : undefined,
              height: "2em",
              maxHeight: "2em",
              minHeight: "2em",
              minWidth: "2em",
            }}
            onClick={() => onPageSelect(n as number)}
          >
            {n}
          </Button>
        ) : (
          <Typography key={`current-page-${idx + 1}`}>{n}</Typography>
        ),
      )}
    </Stack>
  );
}

export function Pagination({
  pages,
  currentPage,
  onPageChange,
}: {
  pages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) {
  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="end" alignItems="center">
        <PrimaryButton
          disabled={currentPage <= 1}
          onClick={() =>
            currentPage !== 1 && onPageChange(Math.max(currentPage - 1, 1))
          }
        >
          <ChevronLeft />
        </PrimaryButton>
        <Pages
          pages={pages}
          currentPage={currentPage}
          onPageSelect={onPageChange}
        />

        <PrimaryButton
          disabled={currentPage >= pages}
          onClick={() =>
            currentPage !== pages &&
            onPageChange(Math.min(currentPage + 1, pages))
          }
        >
          <ChevronRight />
        </PrimaryButton>
      </Stack>
    </Box>
  );
}
