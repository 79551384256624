import React, { useMemo } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { BLACK, WHITE } from "../../components/colors";
import Flexbox from "../../components/flexbox";

export const DropdownIndicator = () => {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column>
          <Icon path={mdiChevronDown} size={0.5} />
        </Column>
      )}
    </Flexbox>
  );
};

const useGuestTheme = (hasGuest) => {
  return useMemo(
    () => ({
      container: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? WHITE : "inherit",
        borderRadius: "3px",
        color: BLACK,
        height: "fit-content",
        width:
          !hasGuest && isFocused ? "calc(((100% / 11) * 5) - 60px)" : "100%",
      }),
      control: (base) => ({
        ...base,
        backgroundColor: "inherit",
        border: 0,
        borderRadius: "3px",
        boxShadow: 0,
        cursor: "pointer",
        flexDirection: "row-reverse",
        fontSize: "13px",
        minHeight: "fit-content",
        padding: "0px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        position: "absolute",
        right: "0",
        visibility: "hidden",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: BLACK,
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: BLACK,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        marginTop: "0",
      }),
      menuList: (base) => ({
        ...base,
        border: "0.5px solid #A8B1BD",
        borderRadius: 0,
        padding: 0,
      }),
      option: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? "#F1FAFF" : WHITE,
        border: "0.5px solid #A8B1BD",
        color: BLACK,
        cursor: "pointer",
        paddingBottom: "7px",
        paddingTop: "7px",
      }),
      singleValue: (base) => ({
        ...base,
        color: BLACK,
      }),
      valueContainer: (base) => ({
        ...base,
        fontSize: "13px",
        padding: "0px",
      }),
    }),
    [hasGuest],
  );
};

export default useGuestTheme;
