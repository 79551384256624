import { useUserService } from "../services";
import { useTokenRefreshHandler } from "../hooks";
import { useActingAsOverrideHeader } from "../auth";
import { getUserToken } from "src/utils/jwtToken";
import { errorHandler } from "src/hooks/errorHandler";

export function useUserTokenInfo() {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    id: number,
  ): Promise<{
    valid: boolean;
  }> =>
    service
      .get(`/api/users/${id}/token-info`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler);
}

export default useUserTokenInfo;
