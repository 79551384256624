import { Box, useTheme } from "@mui/material";

type TemplateEditSelectableCardProps = {
  selected: boolean;
  children: React.ReactNode;
  onClick: () => void;
};

export const TemplateEditSelectableCard = (
  props: TemplateEditSelectableCardProps,
) => {
  const theme = useTheme();
  return (
    <Box
      onClick={props.onClick}
      sx={{
        height: "100%",
        padding: "10px",
        borderRadius: "5px",
        border: `1px solid ${props.selected ? "transparent" : "#E1E6EB"}`,
        outline: `2px solid ${
          props.selected ? theme.palette.primary.main : "transparent"
        }`,
        cursor: "pointer",
        "&:hover": {
          outline: `2px solid ${theme.palette.primary.main}`,
          border: "1px solid transparent",
        },
      }}
    >
      {props.children}
    </Box>
  );
};
