import React, { forwardRef, useMemo } from "react";
import cx from "classnames";
import s from "./flexbox.module.scss";

const useFlexComponent = (flexDirection) => {
  return useMemo(() => {
    return forwardRef(function FlexComponent(
      {
        children,
        className,
        width = "100%",
        height = "100%",
        justifyContent = "center",
        alignItems = "center",
        reverse = false,
        style,
        ...props
      },
      ref,
    ) {
      return (
        <div
          className={cx(
            s[`flexbox__${flexDirection}`],
            {
              [s[`flexbox__${flexDirection}--reverse`]]: reverse,
            },
            className,
          )}
          style={{
            ...style,
            alignItems,
            height,
            justifyContent,
            width,
          }}
          ref={ref}
          {...props}
        >
          {children}
        </div>
      );
    });
  }, [flexDirection]);
};

const useFlexbox = () => {
  return {
    Column: useFlexComponent("column"),
    Row: useFlexComponent("row"),
  };
};

const Flexbox = ({ children }) => {
  const { Column, Row } = useFlexbox();
  return children({ Column, Row });
};

export default Flexbox;
