import { useSWRConfig } from "swr";
import dayjs, { Dayjs } from "dayjs";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { GuestStatus, MeetingSearchCategory, MeetingStatus } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export interface ExportMeetingsQueryV1 {
  filter: {
    categories: MeetingSearchCategory[];
    query: string;
    guestStatusFilter: GuestStatus[];
    meetingStatusFilter: MeetingStatus[];
    meetingTypeFilter: number[];
    tagFilter: number[];
    lastActivityFilter?: {
      end?: Date | Dayjs;
      start?: Date | Dayjs;
      timezone: string;
    };
    dateFilter?: {
      end?: Date | Dayjs;
      start?: Date | Dayjs;
      timezone: string;
    };
  };
  select: {
    dialogs: boolean;
  };
  sort: {
    field: string;
    order: "asc" | "desc";
  };
}

export interface ExportMeetingsQueryV2 {
  filter: {
    guestEmail: string;
    hostEmail: string;
    notes: string;
    guestStatusFilter: GuestStatus[];
    meetingStatusFilter: MeetingStatus[];
    meetingTypeFilter: number[];
    tagFilter: number[];
    lastActivityFilter?: {
      end?: Date | Dayjs;
      start?: Date | Dayjs;
      timezone: string;
    };
    dateFilter?: {
      end?: Date | Dayjs;
      start?: Date | Dayjs;
      timezone: string;
    };
  };
  select: {
    dialogs: boolean;
  };
  sort: {
    field: string;
    order: "asc" | "desc";
  };
}

export type ExportMeetingsQuery = ExportMeetingsQueryV1 | ExportMeetingsQueryV2;

export function useExportMeetings() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (query: ExportMeetingsQuery): Promise<void> => {
    // Golang cannot parse ISO time strings with a trailing 'Z' character so we will reformat start and end to account for such **eye roll**
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const q: any = query;
    if (q.filter?.lastActivityFilter?.start) {
      q.filter.lastActivityFilter.start = dayjs(
        q.filter.lastActivityFilter.start,
      ).format("YYYY-MM-DDTHH:mm:ss");
    }
    if (q.filter?.lastActivityFilter?.end) {
      q.filter.lastActivityFilter.end = dayjs(
        q.filter.lastActivityFilter.end,
      ).format("YYYY-MM-DDTHH:mm:ss");
    }
    if (q.filter?.dateFilter?.start) {
      q.filter.dateFilter.start = dayjs(q.filter.dateFilter.start).format(
        "YYYY-MM-DDTHH:mm:ss",
      );
    }
    if (q.filter?.dateFilter?.end) {
      q.filter.dateFilter.end = dayjs(q.filter.dateFilter.end).format(
        "YYYY-MM-DDTHH:mm:ss",
      );
    }

    return service
      .post(`/api/meetings/instances/export/csv`)
      .set(headers)
      .send(q)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/exports"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
  };
}
