import useSWRImmutable from "swr/immutable";
import { useUserService } from "../services";
import { useTokenRefreshHandler } from "../hooks";
import { API } from "../props";
import { InviteStyle } from "../meetingTypes/invite/props";
import { errorHandler } from "src/hooks/errorHandler";

export interface oneClickValidationData {
  valid: boolean | undefined;
  meetingId: string | undefined;
  meetingInviteStyle: InviteStyle;
  logoURL: string | undefined;
}

export function useValidateOneClickToken(
  token: string,
  meetingId?: string | null,
) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const { data, error } = useSWRImmutable(
    token ? [API.meetings.scheduling.validate, { token, meetingId }] : null,
    (url: string, body: object) =>
      service
        .post(url)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
  );

  return {
    data: data as oneClickValidationData | undefined,
    error,
    loading: !error && !data,
  };
}
