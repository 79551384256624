import React, { useCallback, useEffect, useMemo, useState } from "react";
import { mdiAccountAlertOutline, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import useGuestTheme, { DropdownIndicator } from "./useGuestTheme";
import { useGuestsRepository } from "../../repository";
import { NO_OPTIONS } from "../../utils/variables";
import { P1 } from "../../components/typography";
import {
  AsyncCreatableSelect,
  guestsToOptions,
  guestToOption,
} from "../../inputs";

import { post } from "../../utils/fetch";
import style from "./guestSelect.module.scss";
import { CopyButton } from "../../components/copyButton/CopyButton";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { API } from "../../props";
import { AddContactModal } from "../../componentsV2/dialogs";
import { useContacts } from "../../queries";

const Placeholder = () => (
  <Flexbox.Row>
    <Icon
      className={style.guestSelect__placeholder_text_color}
      path={mdiPlus}
      size={1}
    />
    <P1 className={style.guestSelect__placeholder_text_color}>Guest</P1>
  </Flexbox.Row>
);

const GuestSelect = ({ disabled = false, guest, setGuest }) => {
  const [selectedGuest, setSelectedGuest] = useState(guestToOption(guest));
  const selectTheme = useGuestTheme(guest !== null);

  const [createContact, setCreateContact] = useState(null);
  const { searchGuests } = useGuestsRepository();
  const { addGeneralNotification, addError } = useGeneralNotifications();

  const selectComponents = useMemo(() => ({ DropdownIndicator }), []);

  const { data: guests, loading } = useContacts(
    100,
    0,
    // Sometimes selectedGuest is an option representing the selection and
    // sometimes it is the input value representing the search string for
    // the fetch
    typeof selectedGuest !== "object" ? selectedGuest : undefined,
  );

  const initialGuestOptions = useMemo(
    () => guestsToOptions(guests?.data.filter((g) => !g.unsubscribed)),
    [guests],
  );

  const noOptionsMessage = useCallback(() => NO_OPTIONS.guests, []);

  const onChangeGuest = useCallback(
    (g) => {
      setGuest(g.value);
      setSelectedGuest(g);
    },
    [setGuest],
  );

  const handleContactCreate = useCallback(
    async (contact) => {
      try {
        const g = await post(API.contacts.default(), null, contact).then(
          (res) => res.json(),
        );

        onChangeGuest(guestToOption(Object.assign(contact, g)));

        addGeneralNotification(
          `Successfully created '${contact.email}' as a contact`,
          mdiAccountAlertOutline,
        );

        setCreateContact(null);
      } catch {
        addError(`Failed to save contact`);
      }
    },
    [addGeneralNotification, addError, setCreateContact, onChangeGuest],
  );

  useEffect(() => {
    setSelectedGuest(guestToOption(guest));
  }, [guest]);

  return (
    <>
      {createContact && (
        <AddContactModal
          open
          contact={createContact}
          onClose={() => setCreateContact(null)}
          onSubmit={handleContactCreate}
        />
      )}
      <Flexbox.Row alignItems="center">
        <AsyncCreatableSelect
          name="/instances/action_bar/add_new_instance/guest"
          disabled={disabled}
          components={selectComponents}
          customTheme={selectTheme}
          defaultOptions={initialGuestOptions}
          isLoading={loading}
          isMulti={false}
          load={searchGuests}
          noOptionsMessage={noOptionsMessage}
          onCreateOption={(email) => setCreateContact({ email })}
          onChange={onChangeGuest}
          toOption={guestToOption}
          value={selectedGuest}
          placeholder={<Placeholder />}
          isOptionDisabled={(option) => option.disabled}
        />
        {guest && (
          <div
            style={{
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "2.5px",
            }}
          >
            <CopyButton text={guest.email} />
          </div>
        )}
      </Flexbox.Row>
    </>
  );
};

export default GuestSelect;
