import React, { useMemo, useCallback, useEffect } from "react";

import { Select } from "../../../../inputs";
import { useConditionSelectTheme } from "./useConditionSelectTheme";
import LockedDropdown from "../../../../components/dropdown/LockedDropdown";
import {
  IMPORT_CONDITION_TYPES,
  IMPORT_CONDITION_TYPES_MAPPINGS,
  INTERCEPT_CONDITION_TYPES,
  INTERCEPT_CONDITION_TYPES_MAPPINGS,
} from "../cardProps";
import { CHANNEL_TYPES } from "../../../props";
import style from "../style.module.scss";

function ConditionDropdown({
  hasError,
  channelType,
  isOrphaned,
  localCondition,
  setLocalCondition,
  isOutreach,
}) {
  const [components, theme] = useConditionSelectTheme();

  const canShowConditionDropdown = useMemo(
    () =>
      (channelType === CHANNEL_TYPES.IMPORT ||
        channelType === CHANNEL_TYPES.INTERCEPT) &&
      !hasError &&
      !isOrphaned,
    [channelType, hasError, isOrphaned],
  );

  const canShowLockedCondition = useMemo(
    () =>
      (channelType === CHANNEL_TYPES.IMPORT ||
        channelType === CHANNEL_TYPES.INTERCEPT) &&
      (hasError || isOrphaned),
    [channelType, hasError, isOrphaned],
  );

  const conditionTypesForDropdown = useMemo(
    () =>
      channelType === CHANNEL_TYPES.INTERCEPT
        ? INTERCEPT_CONDITION_TYPES.map((c) => ({
            id: c,
            operator: INTERCEPT_CONDITION_TYPES_MAPPINGS[c],
          }))
        : (isOutreach ? ["is"] : IMPORT_CONDITION_TYPES).map((c) => ({
            id: c,
            operator: IMPORT_CONDITION_TYPES_MAPPINGS[c],
          })),
    [channelType, isOutreach],
  );

  const dropdownValue = useMemo(
    () => IMPORT_CONDITION_TYPES_MAPPINGS[localCondition],
    [localCondition],
  );

  const handleOnSelectCondition = useCallback(
    (item) => {
      setLocalCondition(item.id);
    },
    [setLocalCondition],
  );

  const conditionTypesForDropdownOptions = useMemo(() => {
    return conditionTypesForDropdown.map((conditionTypeForDropdown) => ({
      label: conditionTypeForDropdown.operator,
      value: conditionTypeForDropdown,
    }));
  }, [conditionTypesForDropdown]);

  useEffect(() => {
    if (isOutreach) {
      setLocalCondition("is");
    }
  }, [isOutreach, setLocalCondition]);

  return (
    <>
      {canShowConditionDropdown && (
        <div className={style.conditions__card_condition_condition}>
          <Select
            components={components}
            customTheme={theme}
            name={`/channels/${channelType}/add_channel/select_operator`}
            onChange={({ value }) => {
              handleOnSelectCondition(value);
            }}
            options={conditionTypesForDropdownOptions}
            placeholder={<div>Select An Operator...</div>}
            value={conditionTypesForDropdownOptions.find(
              (ctDo) => ctDo.label === dropdownValue,
            )}
          />
        </div>
      )}

      {isOutreach && channelType === CHANNEL_TYPES.IMPORT && (
        <div className={style.outreach_condition_text}>on step</div>
      )}

      {canShowLockedCondition && (
        <LockedDropdown
          className={style.conditions__card_condition_locked_dropdown_condition}
          value={dropdownValue}
        />
      )}
    </>
  );
}

export default ConditionDropdown;
