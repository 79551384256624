import React, { useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useOpenAiCompletion from "../mutations/useOpenAiCompletion";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import SecondaryButton from "./buttons/SecondaryButton";
import PrimaryButton from "./buttons/PrimaryButton";

function TemplateAIModal({
  onClose,
  onAIResponse,
  open,
}: {
  onClose: () => void;
  onAIResponse: (text: string) => void;
  open: boolean;
}) {
  const openAICompletion = useOpenAiCompletion();
  const [instructions, setInstructions] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { addError } = useGeneralNotifications();

  const closeModal = () => {
    setInstructions("");
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {!loading && (
        <>
          <DialogTitle>
            <Typography sx={{ color: "primary.dark", fontWeight: "bold" }}>
              Template AI
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction="row"
              sx={{
                marginBottom: "10px",
              }}
            >
              <Typography component="span">
                Enter instructions, and AI will write your message
              </Typography>
              <Chip
                component="span"
                label="Beta"
                size="small"
                sx={{ marginLeft: "5px" }}
              />
            </Stack>
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                if (instructions.length < 1) {
                  return;
                }

                setLoading(true);
                try {
                  const { text } = await openAICompletion(instructions);
                  onAIResponse(text);
                  closeModal();
                } catch {
                  addError(
                    "Unable to generate AI text at this time. Please try again later.",
                  );
                } finally {
                  setLoading(false);
                }
              }}
            >
              <TextField
                multiline
                fullWidth
                label="Enter AI Instructions"
                value={instructions}
                onChange={(e) => {
                  setInstructions(e.target.value);
                }}
                type="text"
              />
              <DialogActions>
                <>
                  <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    disabled={instructions.length < 1 || loading}
                  >
                    Submit
                  </PrimaryButton>
                </>
              </DialogActions>
            </form>
          </DialogContent>
        </>
      )}

      {loading && (
        <>
          <DialogTitle>
            <Typography sx={{ color: "primary.dark", fontWeight: "bold" }}>
              Generating Text
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
              }}
            >
              <CircularProgress
                sx={{ marginLeft: "auto", marginRight: "auto" }}
              />
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default TemplateAIModal;
