import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

const MeetingTagNameContext = createContext();

function useIdToTagName() {
  return useState({});
}

export default function MeetingTagNames({ children }) {
  const [idToName, setIdToName] = useIdToTagName();
  const upsert = useCallback(
    (id, name) => {
      setIdToName((prev) => {
        return {
          ...prev,
          [id]: name,
        };
      });
    },
    [setIdToName],
  );
  const ref = useRef({ idToName, upsert });
  useEffect(() => {
    ref.current.idToName = idToName;
  }, [idToName]);
  return (
    <MeetingTagNameContext.Provider value={ref.current}>
      {children}
    </MeetingTagNameContext.Provider>
  );
}
