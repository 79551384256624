import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { mdiAlertCircle, mdiAxisZArrow } from "@mdi/js";
import { useModal } from "../../components/modal";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import {
  hasFilters,
  useSearch,
  useSearchCalendarFilterType,
  useSearchUpdater,
} from "../Search";
import { BulkAddTags } from "../tags";
import {
  ActivateButton,
  AddButton,
  AddTags,
  CalendarFilterControls,
  DeleteButton,
  ExportButton,
  RouteButton,
  UploadCsvBtn,
} from "./MenuButtons";
import { ClearFiltersButton } from "./MenuButtons/ClearFiltersButton";
import { SearchBar } from "../../components/searchbar/SearchBar";
import { formatThousands } from "../../utils/format";
import BulkActivateMenu from "./BulkActivateMenu/BulkActivateMenu";
import Flexbox from "../../components/flexbox";
import { useHasOrgAdminPermissions, useIsDefaultUser } from "../../auth";
import { useSearchDebounceInterval } from "../../configuration";
import style from "./menu.module.scss";
import { useRouteMeetings } from "../../mutations";
import { useDeleteConfirmationDialog } from "../../hooks";
import { useDefaultUserDisableMeetingsDelete } from "../../features";

export function Menu({
  selected,
  totalCount,
  totalCost,
  onBulkDelete,
  onBulkSendLater,
  onBulkActivate,
  onNewMeetingButtonClicked,
}) {
  const history = useHistory();

  const { addGeneralNotification, addError } = useGeneralNotifications();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();

  const search = useSearch();
  const calendarFilterType = useSearchCalendarFilterType();
  const searchUpdater = useSearchUpdater();

  const routeMeetings = useRouteMeetings();

  const openDeleteConfirmation = useDeleteConfirmationDialog();

  const confirmDeleteMeetings = useCallback(() => {
    openDeleteConfirmation(
      `You are about to delete ${selected.length} instance${
        selected.length === 1 ? ". " : "s."
      } Would you like to proceed?`,
      () => onBulkDelete(selected),
    );
  }, [onBulkDelete, selected, openDeleteConfirmation]);

  const [{ close: closeBulkAddTags }, setModal, { DRAWER: type }] = useModal();

  const openAddBulkTagsMenu = useCallback(async () => {
    setModal({
      blur: false,
      content: <BulkAddTags close={closeBulkAddTags} instances={selected} />,
      type,
    });
  }, [closeBulkAddTags, selected, setModal, type]);

  const handleRouteMeetings = useCallback(async () => {
    try {
      const results = await routeMeetings(selected);

      const successes = results.instances;
      const failures = results.failures;
      const successCount = successes?.length || 0;
      const failureCount = failures?.length || 0;

      if (successCount > 0) {
        addGeneralNotification(
          `Successful routing of ${successCount} meeting${
            successCount === 1 ? `` : `s`
          }`,
          mdiAxisZArrow,
        );
      }

      if (failureCount > 0) {
        addError(
          <div>
            Unable to route the following
            {failureCount === 1 ? ` meeting: ` : ` ${failureCount} meetings: `}
            <ul className={style.failedIds}>
              {failures.map((failedId) => (
                <li key={failedId}>{failedId}</li>
              ))}
            </ul>
          </div>,
        );
      }
    } catch (error) {
      addGeneralNotification(`Unable to route meetings`, mdiAlertCircle);
    }
  }, [addError, addGeneralNotification, selected, routeMeetings]);

  const [searchBarText, setSearchBarText] = useState(search.filter.query);

  useEffect(() => {
    setSearchBarText(search.filter.query);
  }, [search.filter.query, setSearchBarText]);

  const searchDebounceInterval = useSearchDebounceInterval();

  const updateQuery = useMemo(() => {
    return debounce((...args) => {
      searchUpdater.setQuery(...args);
    }, searchDebounceInterval);
  }, [searchUpdater, searchDebounceInterval]);

  const onSearchBarTextChange = useCallback(
    (value) => {
      setSearchBarText(value);
      updateQuery(value);
    },
    [setSearchBarText, updateQuery],
  );

  const onSearchBarFilterChange = useCallback(
    (filters) => {
      searchUpdater.setCategories(
        Object.entries(filters)
          .filter(([, v]) => v)
          .map(([k]) => k),
      );
    },
    [searchUpdater],
  );

  const clearFiltersAndSearchBar = useCallback(() => {
    searchUpdater.clearFilters();
    setSearchBarText("");
  }, [searchUpdater, setSearchBarText]);

  const isDefaultUser = useIsDefaultUser();
  const isUserModifyingMeetings = selected.length > 0;
  const meetingDeletionDisabledForDefaultUser =
    useDefaultUserDisableMeetingsDelete();

  const selectedTotalCost =
    selected.length > 0
      ? selected.reduce((p, c) => p + (c.meetingType?.cost || 0), 0)
      : null;

  return (
    <Flexbox>
      {({ Row }) => (
        <>
          <section className={style.instanceMenu}>
            <Row width="150px">
              <AddButton onClick={onNewMeetingButtonClicked} />
              <UploadCsvBtn
                onClick={() => history.push("/reports/import/contacts")}
              />

              {hasOrgAdminPermissions && (
                <BulkActivateMenu
                  bulkActivate={onBulkActivate}
                  bulkActivateMeetingsSendLater={onBulkSendLater}
                  disabled={!isUserModifyingMeetings}
                  selectedMeetingCount={selected.length}
                />
              )}

              {!hasOrgAdminPermissions && (
                <ActivateButton
                  disabled={!isUserModifyingMeetings}
                  onClick={onBulkActivate}
                />
              )}
            </Row>

            <Row className={style.instanceMenu__selectedCount}>
              <div>{`${selected.length} of ${totalCount}`}</div>
            </Row>

            <Row width="200px">
              <ExportButton
                onClick={() =>
                  history.push(
                    `/reports/export/meetings?search=${
                      search.filter.query
                    }&categories=${search.filter.categories}&filterBy=${
                      search.filter.dateFilter.by
                    }&start=${
                      search.filter.dateFilter.start
                        ? search.filter.dateFilter.start.toISOString()
                        : ""
                    }&end=${
                      search.filter.dateFilter.end
                        ? search.filter.dateFilter.end.toISOString()
                        : ""
                    }&templates=${JSON.stringify(
                      search.filter.meetingTypeFilter.map((m) => ({
                        id: m.id,
                        name: m.name,
                      })),
                    )}&tags=${JSON.stringify(
                      search.filter.tagFilter,
                    )}&guestStatuses=${search.filter.guestStatusFilter.join(
                      ",",
                    )}&meetingStatuses=${search.filter.meetingStatusFilter.join(
                      ",",
                    )}&timeRange=${calendarFilterType?.[0]}`,
                  )
                }
              />
              <AddTags
                disabled={!isUserModifyingMeetings}
                onClick={openAddBulkTagsMenu}
              />
              <RouteButton
                disabled={!isUserModifyingMeetings}
                onClick={handleRouteMeetings}
              />
              <DeleteButton
                disabled={
                  !isUserModifyingMeetings ||
                  (isDefaultUser && meetingDeletionDisabledForDefaultUser)
                }
                onClick={confirmDeleteMeetings}
              />
            </Row>

            <div className={style.instanceMenu__space} />
            <ClearFiltersButton
              disabled={!hasFilters(search)}
              key="clear-filters-button"
              onClick={clearFiltersAndSearchBar}
            />
            <CalendarFilterControls />

            <SearchBar
              key="search-bar"
              value={searchBarText}
              filters={{
                "Guest Email": search.filter.categories.includes("Guest Email"),
                "Host Email": search.filter.categories.includes("Host Email"),
                "Meeting Id": search.filter.categories.includes("Meeting Id"),
                "Meeting Template":
                  search.filter.categories.includes("Meeting Template"),
                Notes: search.filter.categories.includes("Notes"),
                "Tag Name": search.filter.categories.includes("Tag Name"),
              }}
              recent={[]}
              onChange={onSearchBarTextChange}
              onFilterChange={onSearchBarFilterChange}
              width="max(244px, 18%)"
            />

            <Row className={style.instanceMenu__value}>
              {`$${
                formatThousands(
                  selectedTotalCost !== null ? selectedTotalCost : totalCost,
                )[2]
              }`}
            </Row>
          </section>
        </>
      )}
    </Flexbox>
  );
}
