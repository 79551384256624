import { useCallback, useState } from "react";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";

export function useRemoveTags(
  deleteTags,
  removeTagsFromCache,
  removeSelectedTagIds,
  removeNewlyCreatedTags,
  refresh,
  onRemove,
  onModify,
) {
  const { addInternalError } = useGeneralNotifications();
  const [isDeletingTags, setIsDeletingTags] = useState(false);
  const removeTags = useCallback(
    async (tagIds) => {
      try {
        setIsDeletingTags(true);
        // Remove the ids from the urlSearch (i.e local data source)
        removeTagsFromCache(tagIds);
        // Remove the ids from the list of selected tags
        removeSelectedTagIds(tagIds);
        const removed = removeNewlyCreatedTags(tagIds);
        await onRemove(tagIds);
        await deleteTags(tagIds);
        // If a newly created tag is removed then the urlSearch will automatically refresh
        if (!removed) {
          await refresh();
        }
        // If there is a listener for tag modifications call it
        if (onModify) {
          onModify();
        }
        setIsDeletingTags(false);
      } catch (error) {
        addInternalError(error.message);
      }
    },
    [
      addInternalError,
      deleteTags,
      onModify,
      onRemove,
      removeTagsFromCache,
      removeNewlyCreatedTags,
      removeSelectedTagIds,
      refresh,
      setIsDeletingTags,
    ],
  );
  return {
    isDeletingTags,
    removeTags,
  };
}
