import { Box, Button, Divider, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { TableSort } from "src/componentsV2/Table";
import { GuestStatus, MeetingStatus, Tag } from "src/types";
import DateRangeFilterForMeetings from "./filters/DateRangeFilterForMeetings";
import FilterSearchString from "./filters/FilterSearchString";
import FilterSelect from "./filters/FilterSelect";
import GuestStatusFilter from "./filters/GuestStatusFilter";
import TagFilter from "./filters/TagFilter";
import TemplateFilter from "./filters/TemplateFilter";
import { SkinnyMeetingDefinition } from "../queries";
import CoHostFilter, { CoHostFilterOption } from "./filters/CoHostFilter";
import { isEnterpriseMode } from "../configuration/getApplicationMode";

type MeetingsFilterBarProps = {
  filters: MeetingQuery;
  sort: TableSort;
  templateFilter: number[];
  onFilterUpdate: (filters: MeetingQuery) => void;
  onSortUpdate: (sort: TableSort) => void;
  onTemplateFilterUpdate: (templates: SkinnyMeetingDefinition[]) => void;
  onReset: () => void;
};

export const MeetingsFilterBar = (props: MeetingsFilterBarProps) => {
  const [query, setQuery] = useState<MeetingQuery>(props.filters);

  useEffect(() => {
    setQuery(props.filters);
  }, [props.filters]);

  const [updateTimeout, setUpdateTimeout] = useState<any>();

  // We have to maintain an extra state because meetingIdFilter accepts a number array.
  const [meetingIdString, setMeetingIdString] = useState("");

  const updateFilters = (newFilters: Partial<MeetingQuery>) => {
    clearTimeout(updateTimeout);

    setQuery((oldFilters) => {
      const filtersToSet = { ...oldFilters, ...newFilters };
      const newTimeout = setTimeout(
        () => props.onFilterUpdate(filtersToSet),
        750,
      );

      setUpdateTimeout(newTimeout);

      return filtersToSet;
    });
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <FilterSelect<TableSort>
        label="Sort By"
        value={props.sort}
        isOptionEqualToValue={(option, value) =>
          option.columnId === value?.columnId && option.order === value.order
        }
        onChange={(value) =>
          props.onSortUpdate(
            value || {
              columnId: "lastActivity",
              order: "desc",
            },
          )
        }
        options={[
          {
            label: "Start Time (Newest-Oldest)",
            value: { columnId: "meetingTime", order: "desc" },
          },
          {
            label: "Start Time (Oldest-Newest)",
            value: { columnId: "meetingTime", order: "asc" },
          },
          {
            label: "Last Activity (Newest-Oldest)",
            value: { columnId: "lastActivity", order: "desc" },
          },
          {
            label: "Last Activity (Oldest-Newest)",
            value: { columnId: "lastActivity", order: "asc" },
          },
        ]}
      />
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      >
        <FilterSearchString
          label="Meeting ID"
          value={meetingIdString}
          onChange={(value) => {
            setMeetingIdString(value);
            const meetingIdArray = value
              .split(",")
              .map((meetingIdString) => Number(meetingIdString.trim()));

            // If value is an empty string, meetingIdArray[0] will be 0, so we need this ternary
            updateFilters({
              meetingIdFilter: meetingIdArray[0] ? meetingIdArray : [],
            });
          }}
        />
      </Box>
      <FilterSearchString
        label="Guest Email"
        value={query.guestEmail}
        onChange={(value) => updateFilters({ guestEmail: value })}
      />
      <FilterSearchString
        label="Host Email"
        value={query.hostEmail}
        onChange={(value) => updateFilters({ hostEmail: value })}
      />
      {isEnterpriseMode && (
        <CoHostFilter
          value={query.coHostFilter}
          onChange={(value) => updateFilters({ coHostFilter: value || null })}
        />
      )}
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      >
        <FilterSearchString
          label="Notes"
          value={query.notes}
          onChange={(value) => updateFilters({ notes: value })}
        />
      </Box>
      <TemplateFilter
        search={query.templateFilterSearch}
        onSearchChange={(value) =>
          updateFilters({
            templateFilterSearch: value,
          })
        }
        value={props.templateFilter}
        onChange={(templates) => {
          props.onTemplateFilterUpdate(templates);
          updateFilters({ template: templates });
        }}
      />
      <GuestStatusFilter
        value={query.guestStatus}
        onChange={(statuses) => updateFilters({ guestStatus: statuses })}
      />
      <Box
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      >
        <TagFilter
          search={query.tagFilterSearch}
          onSearchChange={(value) =>
            updateFilters({
              tagFilterSearch: value,
            })
          }
          value={query.tags}
          onChange={(tags) => updateFilters({ tags })}
        />
      </Box>
      <DateRangeFilterForMeetings
        filterBy={query.date.field}
        onFilterByChange={(value) =>
          updateFilters({ date: { ...query.date, field: value } })
        }
        value={[query.date.range[0], query.date.range[1]]}
        onChange={(range) =>
          updateFilters({
            date: { ...query.date, range },
          })
        }
      />
      <Divider orientation="vertical" flexItem />
      <Button
        onClick={() => {
          props.onReset();
          setMeetingIdString("");
        }}
      >
        Reset
      </Button>
    </Stack>
  );
};

export interface MeetingQuery {
  meetingIdFilter: number[];
  guestEmail: string;
  hostEmail: string;
  notes: string;
  coHostFilter: CoHostFilterOption | null;
  meetingStatus: MeetingStatus[];
  guestStatus: GuestStatus[];
  template: SkinnyMeetingDefinition[];
  templateIds?: number[];
  tags: Tag[];
  date: {
    field: "startTime" | "lastActivity";
    range: [Dayjs | null, Dayjs | null];
  };
  sort: {
    field: "cost" | "lastActivity" | "meetingTime";
    order: "asc" | "desc";
  };
  tagFilterSearch: string;
  templateFilterSearch: string;
}
