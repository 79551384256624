import { Stack } from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "src/types";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { CalendarSelectInput } from "../inputs";

import Dialog from "./Dialog";

export function ApplyCalendarDialog({
  open,
  users,
  initialCalendars,
  onSubmit,
  onClose,
}: {
  open: boolean;
  users: number[];
  initialCalendars?: Calendar[];
  onSubmit: (users: number[], calendars: Calendar[]) => void;
  onClose: () => void;
}) {
  const [calendars, setCalendars] = useState<Calendar[]>(
    initialCalendars || [],
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Apply Calendar"
      actions={
        <>
          <PrimaryButton
            onClick={() => {
              onSubmit(users, calendars);
            }}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ mt: 1, minHeight: 400 }}>
        <CalendarSelectInput value={calendars} onChange={setCalendars} />
      </Stack>
    </Dialog>
  );
}

export default ApplyCalendarDialog;
