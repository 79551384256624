import { Edit, ErrorOutline } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import React, { useMemo, useState } from "react";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import {
  HostSelectInput,
  MeetingHost,
  MeetingTypeSelectInput,
  MinimumSchedulingNoticeInput,
} from "src/componentsV2/inputs";
import { DurationSlider, GapSlider } from "src/componentsV2/sliders";
import { State } from "./State";
import { useMaxDaysAllowed } from "src/types";

export function durationToString(duration: number) {
  if (duration >= 60) {
    const hours = Math.floor(duration / 60);
    const mins = duration % 60;

    return `${
      `${hours} hour${hours > 1 ? "s" : ""}` +
      (mins ? ` and ${mins} minute${mins > 1 ? "s" : ""}` : "")
    } before meeting start time`;
  } else {
    return `${duration} minutes`;
  }
}

function SchedulingRangePicker({
  value,
  onChange,
}: {
  value: [Dayjs | null, Dayjs | null];
  onChange?: (newValue: [Dayjs | null, Dayjs | null]) => void;
}) {
  const maxDays = useMaxDaysAllowed();
  const today = useMemo(() => {
    return dayjs().startOf("day");
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        start: "Start",
        end: "End",
      }}
    >
      <DateRangePicker
        calendars={1}
        shouldDisableDate={(day) => day.isAfter(today.add(maxDays, "days"))}
        disablePast
        value={value}
        onChange={
          onChange ||
          function () {
            return;
          }
        }
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <Stack spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </Stack>
            </Stack>
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}

function MeetingTimePicker({
  value,
  onChange,
}: {
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        start: "Start",
        end: "End",
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center">
          <DatePicker
            label="Date"
            disablePast
            value={value}
            onChange={(v) => {
              if (v && !v.isValid()) {
                onChange(value);
              } else {
                onChange(v);
              }
            }}
            renderInput={(props) => <TextField {...props} />}
          />
          <Box sx={{ mx: 2 }}> at </Box>
          <TimePicker
            disableOpenPicker
            label="Time"
            value={value}
            onChange={(v) => {
              if (v && !v.isValid()) {
                onChange(value);
              } else {
                onChange(v);
              }
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
}

function MeetingProperty<T>({
  label,
  value,
  displayedValue,
  Input,
  editable,
  onChange = () => null,
}: {
  label: string;
  value: T;
  displayedValue?: string;
  Input: React.FunctionComponent<{
    value: T;
    onChange?: (newValue: T) => void;
  }>;
  editable?: boolean;
  onChange?: (newValue: T) => void;
}) {
  const [edit, setEdit] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setEdit(false)}>
      <Stack spacing={1} alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>
            <strong>{label}:</strong> {displayedValue}
          </Typography>
          {editable && !edit && (
            <IconButton onClick={() => setEdit(true)} sx={{ p: "2px" }}>
              <Edit fontSize="small" color="primary" />
            </IconButton>
          )}
        </Stack>
        {edit && (
          <Box sx={{ width: "100%", pt: 1 }}>
            <Input value={value} onChange={onChange} />
          </Box>
        )}
      </Stack>
    </ClickAwayListener>
  );
}

type SeelectAMeetingProps = {
  state: State;
  setState: (state: State) => void;
  next: () => void;
};

function SelectAMeeting({ state, setState, next }: SeelectAMeetingProps) {
  const [meetingTime, setMeetingTime] = useState<Dayjs | null>(() => {
    if (state.startTime) {
      const startTime = dayjs(state.startTime);
      if (startTime.isValid()) {
        return startTime;
      }
      return null;
    }
    const time = dayjs()
      .add(state.overrides.bufferDurationMinutes || 0, "minutes")
      .add(1, "day")
      .startOf("minute");

    const defaultTime = time.set(
      "minute",
      // Round to the nearest 15 min interval
      Math.ceil(time.get("minute") / 15) * 15,
    );

    // store this in state in case user switches to fixed time but does not make changes
    setState({ ...state, startTime: defaultTime.toISOString() });

    return defaultTime;
  });
  const [schedulingRange, setSchedulingRange] = useState<
    [Dayjs | null, Dayjs | null]
  >([
    dayjs().add(state.overrides.dayRangeFrom, "days").startOf("day"),
    dayjs().add(state.overrides.dayRangeTo, "days").startOf("day"),
  ]);

  const [isEditingHost, setIsEditingHost] = useState<boolean>(false);

  return (
    <>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{
            pt: 1,
            height: "600px",
          }}
        >
          <MeetingTypeSelectInput
            meetingType={state.meetingType}
            onMeetingTypeSelect={(v) => {
              if (v) {
                const emailTemplate = v.emailTemplates[0] || null;
                const inviteTemplate = v.inviteTemplates[0] || null;

                setState({
                  ...state,
                  meetingType: v,
                  overrides: {
                    ...state.overrides,
                    gap: v.properties.gap,
                    duration: v.properties.duration,
                    bufferDurationMinutes: v.buffer_duration_mins,
                    dayRangeFrom: v.properties.dayRange.from,
                    dayRangeTo: v.properties.dayRange.to,
                    emailSubjectTemplate: emailTemplate?.title || "",
                    emailBodyTemplate: emailTemplate?.body || "",
                    inviteTitleTemplate: inviteTemplate?.title || "",
                    inviteBodyTemplate: inviteTemplate?.body || "",
                  },
                });
              } else {
                setState({
                  ...state,
                  meetingType: v,
                  overrides: {
                    ...state.overrides,
                    emailSubjectTemplate: null,
                    emailBodyTemplate: null,
                    inviteTitleTemplate: null,
                    inviteBodyTemplate: null,
                  },
                });
              }
            }}
          />

          {state.meetingType !== null && (
            <Stack spacing={2}>
              <Typography>
                <strong>Type:</strong>{" "}
                {state.meetingType.inviteStyle === "calendar_first"
                  ? "Calendar First"
                  : state.meetingType.inviteStyle === "custom"
                    ? "Email"
                    : "Unknown"}
              </Typography>

              <Stack direction="column">
                <Stack direction="row" sx={{ width: "100%" }}>
                  <Typography>
                    <strong>Host:</strong>{" "}
                    {state.host
                      ? `${state.host.firstName} ${state.host.lastName}`
                      : "None"}
                  </Typography>
                  <IconButton
                    onClick={() => setIsEditingHost(true)}
                    sx={{ p: "2px", ml: "auto" }}
                  >
                    <Edit fontSize="small" color="primary" />
                  </IconButton>
                </Stack>
                {isEditingHost && (
                  <Box sx={{ mt: 2 }}>
                    <HostSelectInput
                      host={state.host}
                      onHostSelect={async (user: MeetingHost | null) => {
                        if (user !== null) {
                          setIsEditingHost(false);
                          setState({ ...state, host: user });
                        }
                      }}
                    />
                  </Box>
                )}
              </Stack>

              <MeetingProperty
                label="Gap"
                value={state.overrides.gap}
                displayedValue={`${state.overrides.gap} minutes`}
                Input={(props) => (
                  <Box sx={{ px: 6 }}>
                    <GapSlider {...props} />
                  </Box>
                )}
                onChange={(v) => {
                  setState({
                    ...state,
                    overrides: {
                      ...state.overrides,
                      gap: v,
                    },
                  });
                }}
                editable
              />
              <MeetingProperty
                label="Duration"
                value={state.overrides.duration}
                displayedValue={`${state.overrides.duration} minutes`}
                Input={(props) => (
                  <Box sx={{ px: 6 }}>
                    <DurationSlider {...props} />
                  </Box>
                )}
                onChange={(v) => {
                  setState({
                    ...state,
                    overrides: {
                      ...state.overrides,
                      duration: v,
                    },
                  });
                }}
                editable
              />
              <MeetingProperty
                label="RSVP Required By"
                value={state.overrides.bufferDurationMinutes}
                displayedValue={durationToString(
                  state.overrides.bufferDurationMinutes || 0,
                )}
                Input={MinimumSchedulingNoticeInput}
                onChange={(v) => {
                  setState({
                    ...state,
                    overrides: {
                      ...state.overrides,
                      bufferDurationMinutes: v,
                    },
                  });
                }}
                editable
              />
              <ToggleButtonGroup
                value={state.preferredTimeType}
                exclusive
                onChange={(event, value) => {
                  setState({ ...state, preferredTimeType: value });
                }}
              >
                <ToggleButton
                  value="scheduling range"
                  color="info"
                  sx={{
                    height: 30,
                    textTransform: "none",
                  }}
                >
                  Scheduling Range
                </ToggleButton>
                <ToggleButton
                  value="fixed time"
                  color="info"
                  sx={{
                    height: 30,
                    textTransform: "none",
                  }}
                >
                  Meeting Time
                </ToggleButton>
              </ToggleButtonGroup>

              {state.preferredTimeType === "fixed time" ? (
                <MeetingTimePicker
                  value={meetingTime}
                  onChange={(v) => {
                    setMeetingTime(v);

                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                      },
                      startTime: v ? v.toISOString() : null,
                    });
                  }}
                />
              ) : (
                <SchedulingRangePicker
                  value={schedulingRange}
                  onChange={(v) => {
                    setSchedulingRange(v);

                    if (v[0] && v[1]) {
                      setState({
                        ...state,
                        overrides: {
                          ...state.overrides,
                          dayRangeFrom: Math.ceil(
                            (v[0]?.diff() || 0) / (24 * 60 * 60 * 1000),
                          ),
                          dayRangeTo: Math.ceil(
                            (v[1]?.diff() || 0) / (24 * 60 * 60 * 1000),
                          ),
                        },
                        startTime: null,
                      });
                    }
                  }}
                />
              )}
              <Stack direction="row" alignItems="center" spacing={1}>
                <ErrorOutline />
                <Typography variant="caption">
                  Please note these changes do not override the original
                  template
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          sx={{ pt: 2, width: "100%" }}
        >
          <PrimaryButton
            disabled={state.meetingType == null}
            onClick={() => next()}
          >
            Next
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </>
  );
}

export default SelectAMeeting;
