import { useCallback, useMemo, useState } from "react";

export function useSelectedTags(initialSelectedTags, tags) {
  const [selectedTags, setSelectedTags] = useState([...initialSelectedTags]);
  const selectedTagIds = useMemo(() => {
    return selectedTags.map((t) => t.id);
  }, [selectedTags]);
  const removeSelectedTagIds = useCallback(
    (tagIds) => {
      setSelectedTags((prev) => prev.filter((tag) => !tagIds.includes(tag.id)));
    },
    [setSelectedTags],
  );
  const selectedTagCount = useMemo(() => {
    return selectedTagIds.length;
  }, [selectedTagIds]);
  const toggleTag = useCallback(
    (toggledTag) => {
      setSelectedTags((prev) => {
        const index = prev.findIndex((t) => t.id === toggledTag.id);
        if (index > -1) {
          return prev.filter((tag) => tag.id !== toggledTag.id);
        }
        return [...prev, toggledTag];
      });
    },
    [setSelectedTags],
  );
  const isTagSelected = useCallback(
    (id) => {
      return selectedTagIds.includes(id);
    },
    [selectedTagIds],
  );
  const isEveryTagSelected = useMemo(() => {
    if (tags.length === 0) {
      return false;
    }
    return tags.map((tag) => tag.id).every(isTagSelected);
  }, [isTagSelected, tags]);
  const toggleTags = useCallback(() => {
    if (isEveryTagSelected) {
      const tagIds = tags.map((t) => t.id);
      setSelectedTags((prev) => prev.filter((t) => !tagIds.includes(t.id)));
      return;
    }
    setSelectedTags((prev) => {
      const notAlreadySelected = tags.filter(
        (t) => !selectedTagIds.includes(t.id),
      );
      return [...prev, ...notAlreadySelected];
    });
  }, [tags, isEveryTagSelected, setSelectedTags, selectedTagIds]);
  const editSelectedTag = useCallback(
    (tagId, update) => {
      setSelectedTags((prev) => {
        return prev.map((selectedTag) => {
          if (selectedTag.id === tagId) {
            return {
              ...selectedTag,
              ...update,
            };
          }
          return selectedTag;
        });
      });
    },
    [setSelectedTags],
  );
  return {
    editSelectedTag,
    isEveryTagSelected,
    isTagSelected,
    removeSelectedTagIds,
    selectedTagCount,
    selectedTagIds,
    selectedTags,
    toggleTag,
    toggleTags,
  };
}
