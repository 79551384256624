import React, { useCallback, useContext, useMemo } from "react";
import cx from "classnames";
import { mdiPlus, mdiArrowDecisionOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { API } from "../props";
import style from "./style.module.scss";
import { post } from "../utils/fetch";
import { EmptyView } from "../components/genericView/GenericView";
import Button from "../components/button/Button";
import { isObjectEmpty } from "../utils/helpers";
import { useDeblur, blur } from "../utils/blur";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import { ChannelsStateContext, ModalStateContext } from "./ChannelsState";
import AddModal from "./Modals/AddModal";
import UpdateModal from "./Modals/UpdateModal";
import Channel from "./Channel";
import { CHANNEL_TYPES } from "./props";
import { useHasOrgAdminPermissions } from "../auth";

const Update = ({
  openTagManager,
  meetingTypes,
  meetingDefinition = null,
  page,
  params,
}) => {
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const { addGeneralNotification } = useGeneralNotifications();
  const [
    {
      update: {
        add: { isOpen: addIsOpen },
        update: { isOpen: updateIsOpen },
      },
    },
    dispatchModalState,
  ] = useContext(ModalStateContext);
  const [state, dispatch] = useContext(ChannelsStateContext);
  const deblur = useDeblur();

  const channels = useMemo(() => {
    if (meetingDefinition !== null && !isObjectEmpty(params)) {
      return [...state.updateChannels.values()].filter(
        (channel) => channel.meeting.id === meetingDefinition.id,
      );
    }

    return [...state.updateChannels.values()];
  }, [meetingDefinition, params, state.updateChannels]);

  const toggleAddModal = useCallback(() => {
    if (addIsOpen) {
      deblur();
    }

    if (!addIsOpen) {
      blur();
    }

    dispatchModalState({
      payload: {
        channelType: CHANNEL_TYPES.UPDATE,
        value: !addIsOpen,
      },
      type: "SET_ADD_IS_OPEN",
    });
  }, [addIsOpen, dispatchModalState, deblur]);

  const toggleUpdateModal = useCallback(() => {
    if (updateIsOpen) {
      deblur();
    }

    if (!updateIsOpen) {
      blur();
    }

    dispatchModalState({
      payload: {
        channelType: CHANNEL_TYPES.UPDATE,
        value: !updateIsOpen,
      },
      type: "SET_UPDATE_IS_OPEN",
    });
  }, [updateIsOpen, dispatchModalState, deblur]);

  const onCreateChannel = useCallback(
    (name) => {
      toggleAddModal();

      dispatchModalState({
        payload: {
          channelType: CHANNEL_TYPES.UPDATE,
          value: null,
        },
        type: "SET_SELECTED_CHANNEL",
      });

      addGeneralNotification(
        `Successfully added channel '${name}'`,
        mdiArrowDecisionOutline,
      );
    },
    [addGeneralNotification, toggleAddModal, dispatchModalState],
  );

  const onUpdate = useCallback(
    (name) => {
      toggleUpdateModal();

      dispatchModalState({
        payload: {
          channelType: CHANNEL_TYPES.UPDATE,
          value: null,
        },
        type: "SET_SELECTED_CHANNEL",
      });

      addGeneralNotification(
        `Successfully updated channel '${name}'`,
        mdiArrowDecisionOutline,
      );
    },
    [addGeneralNotification, toggleUpdateModal, dispatchModalState],
  );

  const onRemove = useCallback(
    (name) => {
      addGeneralNotification(
        `Successfully removed channel '${name}'`,
        mdiArrowDecisionOutline,
      );
    },
    [addGeneralNotification],
  );

  const onClone = useCallback(
    async (data) => {
      const { id, name, integration, meeting, tags, tagAutomation, ...rest } =
        data;
      const { tagContacts, tagMeetings } = tagAutomation;
      const channelObject = {
        ...rest,
        enabled: false,
        integration: integration.id,
        meeting: meeting.id,
        name: `${name} Clone`,
        tagAutomation: {
          tagContacts: tagContacts.map((t) => t.id) || [],
          tagMeetings: tagMeetings.map((t) => t.id) || [],
        },
        tags: tags.map((t) => t.id) || [],
      };

      const response = await post(
        API.channels.update(),
        null,
        channelObject,
      ).then((r) => r.json());

      if (response) {
        dispatch({
          payload: {
            channelType: CHANNEL_TYPES.UPDATE,
            channels: [
              {
                ...channelObject,
                id: response.id,
                integration,
                meeting,
                tagAutomation,
                tags,
              },
            ],
          },
          type: "SET_INTEGRATION_CHANNEL",
        });

        addGeneralNotification(
          `Successfully cloned channel '${name}'`,
          mdiArrowDecisionOutline,
        );
      }
    },
    [addGeneralNotification, dispatch],
  );

  return (
    <div className={style.channels}>
      <AddModal
        channelType={CHANNEL_TYPES.UPDATE}
        onClose={toggleAddModal}
        onChannelAdded={onCreateChannel}
        presetMeetingDef={meetingDefinition}
        meetingTypes={meetingTypes}
      />
      <UpdateModal
        channelType={CHANNEL_TYPES.UPDATE}
        onClose={toggleUpdateModal}
        onUpdate={onUpdate}
        presetMeetingDef={meetingDefinition}
        meetingTypes={meetingTypes}
      />
      <div className="row">
        <div className="col-12">
          {!channels.length && hasOrgAdminPermissions && (
            <EmptyView
              icon={mdiArrowDecisionOutline}
              view="update channels"
              actions={[
                <Button
                  key="actions-add-team"
                  icon={mdiPlus}
                  name={`/${page}/update/add_channel`}
                  onClick={toggleAddModal}
                >
                  <span>Add Channel</span>
                </Button>,
              ]}
            />
          )}
          {!channels.length && !hasOrgAdminPermissions && (
            <EmptyView icon={mdiArrowDecisionOutline} view="update channels" />
          )}
          {channels.length > 0 && (
            <>
              <div className="row">
                <div className={cx("col-12", style.channelContainer)}>
                  {channels.map((channel) => {
                    return (
                      <Channel
                        key={`channel-${channel.id}`}
                        data={channel}
                        onClone={onClone}
                        onEdit={toggleUpdateModal}
                        onRemove={onRemove}
                        openTagManager={openTagManager}
                      />
                    );
                  })}
                  {hasOrgAdminPermissions && (
                    <Button
                      type="tertiary"
                      name={`/${page}/update/add_channel`}
                      onClick={toggleAddModal}
                    >
                      <Icon path={mdiPlus} size={2} className={style.close} />
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { Update as default };
