import React from "react";

import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Skeleton, TableSortLabel } from "@mui/material";

import { Loading } from "../Loading";

export interface TableSort {
  order: "asc" | "desc";
  columnId: string;
}

export interface Column<T> {
  id: string;
  label: string | React.ReactNode;
  component: React.FunctionComponent<T>;
  visible?: boolean;
  align?: "right" | "center" | "left";
  width?: number | string;
  sortable?: boolean;
}

export interface TableProps<T> {
  getKey?: (row: T) => void;
  columns: Column<T>[];
  data: T[];
  loading?: boolean;
  minRows?: number;
  sort?: TableSort;
  onSortChange?: (sort: TableSort) => void;
  onRowClick?: (selected: T) => void;
}

export function Table<T>({
  getKey,
  loading = false,
  minRows = 0,
  columns = [],
  data = [],
  sort,
  onSortChange = () => null,
  onRowClick,
}: TableProps<T>) {
  const emptyRows = minRows - data.length;

  return (
    <MuiTable
      sx={{
        minWidth: 50,
      }}
      size="small"
    >
      <TableHead>
        <TableRow>
          {columns.map(
            (col) =>
              (col.visible || col.visible === undefined) && (
                <TableCell
                  key={`col-${col.id}`}
                  align={col.align}
                  width={col.width}
                  padding="normal"
                >
                  {col.sortable ? (
                    <TableSortLabel
                      active={col.id === sort?.columnId}
                      direction={col.id === sort?.columnId ? sort.order : "asc"}
                      onClick={() => {
                        const isAsc =
                          sort?.columnId === col.id && sort?.order === "asc";
                        onSortChange({
                          columnId: col.id,
                          order: isAsc ? "desc" : "asc",
                        });
                      }}
                    >
                      {col.label}
                    </TableSortLabel>
                  ) : (
                    col.label
                  )}
                </TableCell>
              ),
          )}
        </TableRow>
      </TableHead>
      {loading ? (
        <TableBody>
          <TableRow sx={{ height: minRows * 54 }}>
            <TableCell colSpan={columns.length}>
              <Loading />
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          <>
            {data.map((row, rowIndex) => {
              return (
                <TableRow
                  hover={Boolean(onRowClick)}
                  tabIndex={-1}
                  key={(getKey && getKey(row)) || rowIndex}
                  sx={{ height: "54px" }}
                >
                  {columns.map(
                    (col, index) =>
                      (col.visible || col.visible === undefined) && (
                        <TableCell
                          key={`col-${col.id}`}
                          align={col.align}
                          width={col.width}
                        >
                          <col.component
                            key={`row-${rowIndex}-column-${index}`}
                            {...row}
                          />
                        </TableCell>
                      ),
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 54 * emptyRows,
                }}
              >
                <TableCell colSpan={20} />
              </TableRow>
            )}
          </>
        </TableBody>
      )}
    </MuiTable>
  );
}

export const TableLoading = () => (
  <MuiTable>
    <TableHead>
      <TableRow>
        {new Array(7).fill(null).map((_, index) => (
          <TableCell
            sx={{ height: "54px" }}
            key={`table-loading-header-${index}`}
          >
            <Skeleton variant="rectangular" animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {new Array(20).fill(null).map((_, rowIndex) => (
        <TableRow key={`table-loading-row-${rowIndex}`}>
          {new Array(7).fill(null).map((_, index) => (
            <TableCell
              sx={{ height: "54px" }}
              key={`table-loading-${rowIndex}-${index}`}
              padding="normal"
            >
              <Skeleton variant="rectangular" animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </MuiTable>
);

export default Table;
