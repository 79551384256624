import { mdiChevronDown } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useMemo } from "react";
import { AsyncSelect, tagsToOptions } from "../../inputs";
import {
  buildSearchTagsRequest,
  useQuery,
  useTagsRepository,
} from "../../repository";
import { NO_OPTIONS } from "../../utils/variables";
import { useFilterMenuContext } from "../context";
import { useSearch, useSearchUpdater } from "../Search";
import style from "./style.module.scss";
import { useDropdownTheme } from "./useDropdownTheme";

const NO_OPTIONS_MESSAGE = () => NO_OPTIONS.tags;

export function TagFilterControls() {
  const [components, theme] = useDropdownTheme();
  const { setFilterMenuClosed, setFilterMenuOpen } = useFilterMenuContext();
  const { search: searchTags } = useTagsRepository();
  const initialTagsRequest = useMemo(() => {
    return [buildSearchTagsRequest()];
  }, []);
  const search = useSearch();
  const searchUpdater = useSearchUpdater();
  const {
    payloads: [initialTagPayload = null],
  } = useQuery(initialTagsRequest);
  let initialTags = useMemo(() => [], []);
  if (initialTagPayload !== null) {
    const { data } = initialTagPayload;
    initialTags = data;
  }

  const placeholder = useMemo(() => {
    return (
      <div className={style.meetingType__filterPlaceHolder}>
        <div className={style.meetingType__filterPlaceHolderText}>Tags</div>
        <Icon
          className={style.meetingType__filterPlaceHolderIcon}
          path={mdiChevronDown}
          size={0.5}
        />
      </div>
    );
  }, []);
  return useMemo(() => {
    return (
      <AsyncSelect
        components={components}
        customTheme={theme}
        onMenuOpen={setFilterMenuOpen}
        onMenuClose={setFilterMenuClosed}
        isMulti
        defaultOptions={tagsToOptions(initialTags)}
        load={searchTags}
        name="/instances/action_bar/tags_dropdown"
        noOptionsMessage={NO_OPTIONS_MESSAGE}
        toOption={tagsToOptions}
        placeholder={placeholder}
        value={search.filter.tagFilter.map((t) => {
          return {
            label: t.name,
            value: t,
          };
        })}
        onChange={(e) => {
          searchUpdater.setTagFilter(e.map((item) => item.value));
        }}
      />
    );
  }, [
    components,
    initialTags,
    placeholder,
    searchUpdater,
    searchTags,
    setFilterMenuClosed,
    setFilterMenuOpen,
    search,
    theme,
  ]);
}
