import React, { useCallback, useContext, useMemo, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { BLACK } from "../../../../components/colors";
import Flexbox from "../../../../components/flexbox";
import { LoadingCard } from "../../../../components/genericView/GenericView";
import { SearchUpdaterContext } from "../../../Search";
import { P1 } from "../../../../components/typography";

import {
  ACCEPTED_STATUS,
  DECLINED_STATUS,
  NO_RESPONSE_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  toGuestStatusColor,
  toGuestStatusLabel,
} from "../../../props";
import { DrillDownButton } from "./DrillDownButton";
import style from "./guestFinal.module.scss";

const pieChart = {
  fontFamily: "Inter",
  labels: {
    text: {
      fontSize: 12,
    },
  },
  tooltip: {
    container: {
      background: "#222222",
      borderRadius: "5px",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25)",
      color: "#dedede",
      fontSize: "12px",
      padding: "5px 9px",
    },
  },
};

const calculatePercentage = (dividend, divisor) => {
  return ((dividend / divisor) * 100).toFixed(2);
};

function GuestFinal({
  isLoading,
  accepted = 0,
  declined = 0,
  respondedUnknownIntent = 0,
  noResponse = 0,
}) {
  const searchUpdater = useContext(SearchUpdaterContext);
  const [drilledDown, setDrilledDown] = useState(false);

  const finalTotal = useMemo(
    () =>
      [accepted, declined, respondedUnknownIntent, noResponse].reduce(
        (acc, cur) => acc + cur,
      ),
    [accepted, declined, respondedUnknownIntent, noResponse],
  );

  const data = useMemo(
    () =>
      [
        {
          color: toGuestStatusColor(NO_RESPONSE_STATUS),
          id: NO_RESPONSE_STATUS,
          label: toGuestStatusLabel(NO_RESPONSE_STATUS),
          value: noResponse,
        },
        {
          color: toGuestStatusColor(RESPONDED_UNKNOWN_INTENT_STATUS),
          id: RESPONDED_UNKNOWN_INTENT_STATUS,
          label: toGuestStatusLabel(RESPONDED_UNKNOWN_INTENT_STATUS),
          value: respondedUnknownIntent,
        },
        {
          color: toGuestStatusColor(DECLINED_STATUS),
          id: DECLINED_STATUS,
          label: toGuestStatusLabel(DECLINED_STATUS),
          value: declined,
        },
        {
          color: toGuestStatusColor(ACCEPTED_STATUS),
          id: ACCEPTED_STATUS,
          label: toGuestStatusLabel(ACCEPTED_STATUS),
          value: accepted,
        },
      ].filter((item) => item.value > 0),
    [accepted, declined, respondedUnknownIntent, noResponse],
  );

  const colors = useCallback((d) => d.color, []);
  const colorBy = useCallback((node) => node.label, []);

  const tooltip = useCallback(
    (node) => {
      return `${node.label}: ${calculatePercentage(node.value, finalTotal)}%`;
    },
    [finalTotal],
  );

  const onClick = useCallback(
    ({ id }) => {
      searchUpdater.toggleGuestStatusFilter(id);
    },
    [searchUpdater],
  );

  const drillDown = useCallback(() => {
    setDrilledDown(true);
  }, []);

  const leaveDrillDown = useCallback(() => {
    searchUpdater.clearGuestStatusFilters();
    setDrilledDown(false);
  }, [searchUpdater]);

  const formatSvgText = useCallback((pieDataObject) => {
    const splitText = pieDataObject.label.split(" ");
    if (splitText.length > 1) {
      return splitText.map((i, idx) => (
        <React.Fragment key={i}>
          <tspan x="0" dy={idx === 0 ? "-0.8em" : "1em"}>
            {i}
          </tspan>
        </React.Fragment>
      ));
    }

    return (
      <>
        <tspan x="0" dy="0rem">
          {splitText}
        </tspan>
      </>
    );
  }, []);

  return (
    <>
      <Flexbox>
        {({ Column }) => (
          <div className={style.guestFinal}>
            {isLoading && (
              <LoadingCard
                className={style.guestFinal__loadingCard}
                height={160}
              />
            )}

            {!isLoading && (
              <div className={style.guestFinal__card}>
                <Column>
                  {finalTotal !== 0 && (
                    <>
                      {!drilledDown && (
                        <div className={style.accept_box}>
                          <div>
                            <div>Meetings Accepted</div>
                            <div>{accepted}</div>
                          </div>
                          <div className={style.accept_percentage}>
                            <div>Accept Rate</div>
                            <div>
                              {calculatePercentage(accepted, finalTotal)}%
                            </div>
                          </div>
                          <DrillDownButton
                            text="Drill Down"
                            onClick={drillDown}
                          />
                        </div>
                      )}
                      {drilledDown && (
                        <>
                          <ResponsivePie
                            theme={pieChart}
                            colors={colors}
                            colorBy={colorBy}
                            cornerRadius={10}
                            margin={{
                              bottom: 30,
                              left: 20,
                              right: 10,
                              top: 25,
                            }}
                            innerRadius={0.7}
                            radialLabel={formatSvgText}
                            radialLabelsSkipAngle={20}
                            radialLabelsTextXOffset={2}
                            radialLabelsTextColor={{ from: "color" }}
                            radialLabelsLinkOffset={0}
                            radialLabelsLinkDiagonalLength={10}
                            radialLabelsLinkHorizontalLength={10}
                            radialLabelsLinkStrokeWidth={3}
                            radialLabelsLinkColor={{ from: "color" }}
                            slicesLabelsSkipAngle={10}
                            slicesLabelsTextColor={BLACK}
                            data={data}
                            tooltip={tooltip}
                            onClick={onClick}
                            onMouseEnter={(_data, event) => {
                              event.target.style.cursor = "pointer";
                            }}
                            onMouseLeave={(_data, event) => {
                              event.target.style.cursor = "auto";
                            }}
                          />
                          <DrillDownButton
                            text="Go Back"
                            onClick={leaveDrillDown}
                          />
                        </>
                      )}
                    </>
                  )}
                  {finalTotal === 0 && <P1 bold>N/A</P1>}
                </Column>
              </div>
            )}
          </div>
        )}
      </Flexbox>
    </>
  );
}

export default GuestFinal;
