import React from "react";

import { formatWordForPossession, capitalize } from "./util";
import style from "./globallogs.module.scss";

const buildLogFromOldAndNewAttributes = (
  userEmail,
  label,
  oldAttr,
  newAttr,
) => (
  <span>
    {`${capitalize(userEmail)} `}
    {` ${label} `}
    changed from
    {` ${oldAttr || '""'} `}
    to
    <span className={style.logBuilder__userAttributeChange}>
      {` ${newAttr || '""'} `}
    </span>
  </span>
);

const buildLogFromChangedAttribute = (userEmail, action, newAttr) => (
  <span>
    {`${capitalize(userEmail)} `}
    {`${action}:`}
    <span className={style.logBuilder__userAttributeChange}>
      {` ${newAttr || '""'} `}
    </span>
  </span>
);

const buildUserUpdatedLog = function* (log) {
  const {
    structuredData: {
      userFirstName,
      userFirstNameOld,
      userLastName,
      userLastNameOld,
      userLocation,
      userLocationOld,
      userRole,
      userRoleOld,
      userTitle,
      userTitleOld,
      userMeetingLink,
      userTeamsAdded,
      userTeamsRemoved,
      actorUserEmail,
      userEmail,
    },
  } = log;

  if (
    (userFirstNameOld?.length > 0 || userFirstName?.length > 0) &&
    userFirstNameOld !== userFirstName
  ) {
    yield buildLogFromOldAndNewAttributes(
      formatWordForPossession(userEmail),
      "First Name",
      userFirstNameOld,
      userFirstName,
    );
  }
  if (
    (userLastNameOld?.length > 0 || userLastName?.length > 0) &&
    userLastNameOld !== userLastName
  ) {
    yield buildLogFromOldAndNewAttributes(
      formatWordForPossession(userEmail),
      "Last Name",
      userLastNameOld,
      userLastName,
    );
  }

  if (
    (userTitleOld?.length > 0 || userTitle?.length > 0) &&
    userTitleOld !== userTitle
  ) {
    yield buildLogFromOldAndNewAttributes(
      formatWordForPossession(userEmail),
      "Title",
      userTitleOld,
      userTitle,
    );
  }

  if (
    (userLocationOld?.length > 0 || userLocation?.length > 0) &&
    userLocationOld !== userLocation
  ) {
    yield buildLogFromOldAndNewAttributes(
      formatWordForPossession(userEmail),
      "Location",
      userLocationOld,
      userLocation,
    );
  }

  if (
    (userRoleOld?.length > 0 || userRole?.length > 0) &&
    userRoleOld !== userRole
  ) {
    yield (
      <span>
        {` ${capitalize(actorUserEmail || "")} `}
        updated
        {` ${formatWordForPossession(userEmail)} `}
        Role from
        {` ${userRoleOld} `}
        to
        <span className={style.logBuilder__userAttributeChange}>
          <b>{` ${userRole} `}</b>
        </span>
      </span>
    );
  }

  if (userMeetingLink) {
    yield buildLogFromChangedAttribute(
      formatWordForPossession(userEmail),
      "Meeting Link was updated",
      userMeetingLink,
    );
  }

  if (userTeamsAdded) {
    yield buildLogFromChangedAttribute(
      userEmail,
      "was added to Teams",
      userTeamsAdded,
    );
  }

  if (userTeamsRemoved) {
    yield buildLogFromChangedAttribute(
      userEmail,
      "was removed from Teams",
      userTeamsRemoved,
    );
  }
};

export { buildUserUpdatedLog as default };
