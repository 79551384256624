import { getUserToken } from "src/utils/jwtToken";
import { useSWRConfig } from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useDeleteMeetingTemplate() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    id: number | string,
  ): Promise<{ error: string } | { message: string; success: boolean }> =>
    service
      .delete(`/api/meetings/definition/${id}`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/meetings/definition"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}
