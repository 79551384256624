import React, { useState, useCallback, useEffect, useContext } from "react";
import ConditionGroup from "./ConditionGroup";
import { ChannelsStateContext } from "../../ChannelsState";
import { BLANK_FIELD } from "./cardProps";
import { validateConditions } from "../helpers";

function ConditionCards({
  showInvalidFields,
  setConditionCardsAreValid,
  channelType,
  setTempModalState,
  conditions,
  fieldObjectType,
  selectedIntegration,
  isAddModal = false,
}) {
  const [{ [`${channelType}Fields`]: channelFields }] =
    useContext(ChannelsStateContext);
  const [conditionFields, setConditionFields] = useState([]);
  const [hasValidConditions, setHasValidConditions] = useState(false);

  const setConditions = useCallback(
    (value) => {
      setTempModalState((prevState) => ({
        ...prevState,
        conditions: value,
      }));
    },
    [setTempModalState],
  );

  const addGroup = useCallback(() => {
    setConditions([...conditions, [{ ...BLANK_FIELD }]]);
  }, [conditions, setConditions]);

  useEffect(() => {
    setHasValidConditions(validateConditions[channelType](conditions));
  }, [channelType, conditions]);

  useEffect(() => {
    setConditionCardsAreValid(hasValidConditions);
  }, [setConditionCardsAreValid, hasValidConditions]);

  useEffect(() => {
    if (fieldObjectType?.type && channelFields.has(selectedIntegration.name)) {
      setConditionFields(
        channelFields.get(selectedIntegration.name)[fieldObjectType.type] || [],
      );
    }
  }, [selectedIntegration.name, fieldObjectType, channelFields]);

  return (
    <>
      {conditions.map(
        (group, index) =>
          group?.length > 0 && (
            <ConditionGroup
              key={`condition-group-${index + 1}`}
              showInvalidFields={showInvalidFields}
              channelType={channelType}
              group={group}
              groupIndex={index}
              conditions={conditions}
              setConditions={setConditions}
              conditionFields={conditionFields}
              selectedIntegration={selectedIntegration}
              addGroup={addGroup}
              isAddModal={isAddModal}
            />
          ),
      )}
    </>
  );
}

export default ConditionCards;
