/* eslint-disable no-restricted-syntax */

import { SYSTEM_FIELDS } from "../meetingTypes/useDynamicVariableMappings";
import { ACCEPTABLE_FILE_TYPES } from "./props";

const humanize = (number) => {
  const unIndex = number + 1;
  const stringNumber = unIndex.toString();
  const lastDigit = stringNumber[stringNumber.length - 1];
  const secondToLastDigit =
    stringNumber.length >= 2 ? stringNumber[stringNumber.length - 2] : null;

  switch (true) {
    case +lastDigit === 1 &&
      (secondToLastDigit !== null ? +secondToLastDigit !== 1 : true):
      return `${unIndex}st`;
    case +lastDigit === 2 &&
      (secondToLastDigit !== null ? +secondToLastDigit !== 1 : true):
      return `${unIndex}nd`;
    case +lastDigit === 3 &&
      (secondToLastDigit !== null ? +secondToLastDigit !== 1 : true):
      return `${unIndex}rd`;
    default:
      return `${unIndex}th`;
  }
};

/**
 * @function
 * validateDynamicFields
 * @description
 * Checks meetingDefinition fields (invite, email) for any incorrect dynamic varaiables
 *
 * @param {String} text
 */
export const validateDynamicFields = (text) => {
  if (!text) {
    return "No text to validate";
  }
  // first check: make sure there are isn't }} prior to any {{
  const containsForwards = text.indexOf("{{") > -1;
  const containsBackwards = text.indexOf("}}") > -1;
  if (!containsForwards && !containsBackwards) {
    return null;
  }
  if (
    (!containsForwards && containsBackwards) ||
    (containsForwards && !containsBackwards)
  ) {
    return "Please make sure to surround all dynamic variables with double curly braces: {{___}}";
  }

  if (containsForwards && containsBackwards) {
    if (
      text.indexOf("}}") > -1 &&
      text.indexOf("{{") > -1 &&
      text.indexOf("}}") < text.indexOf("{{")
    ) {
      return "Please make sure opening bracket pairs {{ come before closing bracket pairs }}";
    }

    // second check: make sure number of {{ is equal to nunber of }}
    const regexForward = new RegExp(/{{/g);
    const regexBackward = new RegExp(/}}/g);

    const countOfForwardPairs = text.match(regexForward).length;
    const countOfBackwardsPairs = text.match(regexBackward).length;
    if (countOfForwardPairs !== countOfBackwardsPairs) {
      return "Mismatching number of {{ and }}";
    }

    // third check: make sure text between the curly brackets is valid
    const legalFields = Object.values(SYSTEM_FIELDS);
    let copyOfText = text.slice();

    for (let i = 0; i < countOfForwardPairs; i += 1) {
      const stringToCheck = copyOfText.slice(
        copyOfText.indexOf("{{") + 2,
        copyOfText.indexOf("}}"),
      );
      if (!legalFields.includes(stringToCheck)) {
        const position = humanize(i);
        return `Invalid message between the ${position} pair of brackets: ${stringToCheck} is not a valid dynamic field`;
      }
      copyOfText = copyOfText.slice(copyOfText.indexOf("}}") + 2);
    }
    return null;
  }
};

/**
 * @function
 * padNum
 * @description
 * Adds a leading zero to a number, used to force single digit month, dayOfMonth, hour, min, sec to be two digits
 *
 * @param {Number} num
 */
const padNum = (num) => `0${num}`;

/**
 * @function
 * padNum
 * @description
 * Formats date with any needed padding
 *
 * @param {pre-built Date} date
 */
const padDate = (date) => {
  const { year } = date;
  let { dayOfMonth, hour, minute, month, second } = date;

  if (month < 10) {
    month = padNum(month);
  }

  if (dayOfMonth < 10) {
    dayOfMonth = padNum(dayOfMonth);
  }

  if (hour < 10) {
    hour = padNum(hour);
  }

  if (minute < 10) {
    minute = padNum(minute);
  }

  if (second < 10) {
    second = padNum(second);
  }

  return {
    dayOfMonth,
    hour,
    minute,
    month,
    second,
    year,
  };
};

/**
 * @function
 * buildDate
 * @description
 * Builds a date as of the time function is called with year, month, dayOfMonth, hour, minute, second
 *
 * @param {String} page         // name of page user is downloading from (instances, contacts, etc...)
 * @param {String} type         // file type extension (.csv, csv, .pdf, pdf...)
 * @param {Date}   currentDate  // current date
 */
const buildDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // months are zero-indexed
  const dayOfMonth = currentDate.getDate();
  const hour = currentDate.getHours();
  const minute = currentDate.getMinutes();
  const second = currentDate.getSeconds();
  const date = {
    dayOfMonth,
    hour,
    minute,
    month,
    second,
    year,
  };

  return padDate(date);
};

/**
 * @function
 * buildExportFileName
 * @description
 * Builds a file name for export with this format: instances_2020_06_27_122130 (page_year_month_dayOfMonth_hourMinSec)
 *
 * @param {String} page         // name of page user is downloading from (instances, contacts, etc...)
 * @param {String} type         // file type extension (.csv, csv, .pdf, pdf...)
 * @param {Date}   currentDate  // current date
 */
const buildExportFileName = (page, type, currentDate) => {
  if (typeof page !== "string" || typeof type !== "string") {
    throw new Error("please enter strings");
  }

  let fileType = type;

  if (fileType[0] === ".") {
    fileType = fileType.slice(1);
  }

  if (ACCEPTABLE_FILE_TYPES.indexOf(fileType) === -1) {
    throw new Error(`${fileType} is not a valid file type`);
  }

  const { dayOfMonth, hour, minute, month, second, year } = currentDate;

  // file name ex: instances_2020_06_27_122130
  return `${page}_${year}_${month}_${dayOfMonth}_${hour}${minute}${second}.${fileType}`;
};

/**
 * RFC 2822 standard email validation
 * @see https://www.w3resource.com/javascript/form/email-validation.php
 */
export function validateEmailAddress(text) {
  if (typeof text !== "string" || !text.length) {
    return false;
  }

  const regEx = new RegExp(/^[^\s@\\]+@[^\s@_\\]+\.[^\s@_\\]+$/);
  return regEx.test(text);
}

export const isObjectEmpty = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const isAnObject = (obj) => {
  return obj !== null && typeof obj === "object";
};

export const isDeeplyEqual = (comparer, comparee) => {
  if (!isAnObject(comparer) || !isAnObject(comparee)) {
    throw new Error(
      "The arguments must be valid objects. Please check the values being passed in",
    );
  }

  const comparerKeys = Object.keys(comparer);
  const compareeKeys = Object.keys(comparee);

  if (comparerKeys.length !== compareeKeys.length) {
    return false;
  }

  for (const key of comparerKeys) {
    const comparerValue = comparer[key];
    const compareeValue = comparee[key];
    const areObjects = isAnObject(comparerValue) && isAnObject(compareeValue);

    if (
      (areObjects && !isDeeplyEqual(comparerValue, compareeValue)) ||
      (!areObjects && comparerValue !== compareeValue)
    ) {
      return false;
    }
  }

  return true;
};

export const downloadBlob = (blob, page) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  const date = buildDate();
  const fileName = buildExportFileName(page, "csv", date);
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

// start and end are both inclusive, optional callback to manipulate values if needed
// EX: if range is needed for a select
export const buildRange = ([start, end, step], callback = (x) => x) => {
  const length = Math.floor((end - start) / step) + 1;
  return [...Array(length).keys()].map((x) => callback(x * step + start));
};
