import React, { useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { TableCell } from "../../../components/tableV5/GridTable";
import { P1 } from "../../../components/typography";
import { formatCurrency } from "../../../utils/format";
import { COMPLETED_STAGE, SCHEDULED_STAGE } from "../../props";
import style from "./tableCells.module.scss";

const SHOW_VALUE_STATUSES = [SCHEDULED_STAGE, COMPLETED_STAGE];

const Cost = ({ meeting }) => {
  const showMeetingValue = useMemo(
    () => SHOW_VALUE_STATUSES.includes(meeting.status),
    [meeting],
  );

  const cost = useMemo(() => {
    if (meeting.meetingType?.cost !== undefined && showMeetingValue) {
      return formatCurrency(meeting.meetingType.cost);
    }
    return <span>&mdash;</span>;
  }, [meeting, showMeetingValue]);

  return (
    <Flexbox>
      {({ Column }) => (
        <TableCell className={style.tableCells}>
          <Column>
            <P1>{cost}</P1>
          </Column>
        </TableCell>
      )}
    </Flexbox>
  );
};

export default Cost;
