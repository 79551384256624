import { useCallback } from "react";
import { useCustomFetchFn } from "../components/fetch";
import { INTEGRATION_TYPES } from "../integrations/props";
import { API } from "../props";
import { buildRequest } from "../utils/fetchV2";

function buildRunSampleImportRequest(chanObj, chanId) {
  return buildRequest(API.integrations.sample.sampleChannel(chanId), {
    body: JSON.stringify(chanObj),
    method: "POST",
  });
}

function buildGetChannelsRequest(channelType) {
  return buildRequest(API.channels[channelType]());
}

export default function useChannelsRepository() {
  const { fetch } = useCustomFetchFn();
  const runSampleImportChannel = useCallback(
    async (chanObj, chanId = 0) =>
      fetch(buildRunSampleImportRequest(chanObj, chanId)),
    [fetch],
  );

  const getChannels = useCallback(
    async (channelType) =>
      fetch(buildGetChannelsRequest(channelType))
        .then((r) => r.json())
        .then((channels) => {
          // normalize any `salesforce-test` integrations to be just `salesforce`
          return channels.map((c) => {
            if (
              c.integration &&
              c.integration.name === INTEGRATION_TYPES.SALESFORCE_TEST
            ) {
              return {
                ...c,
                integration: {
                  ...c.integration,
                  name: INTEGRATION_TYPES.SALESFORCE,
                },
              };
            }

            return c;
          });
        }),
    [fetch],
  );

  return { getChannels, runSampleImportChannel };
}
