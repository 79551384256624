/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useCallback,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import cx from "classnames";
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import { get } from "../utils/fetch";
import { Tabs, Tab } from "../components/tabs/Tabs";
import Import from "./Import";
import Intercept from "./Intercept";
import Update from "./Update";
import { API } from "../props";
import {
  ChannelsStateContext,
  ChannelsStateReducer,
  DefaultChannelState,
  ModalStateContext,
  ModalStateReducer,
  DefaultModalState,
} from "./ChannelsState";
import {
  CHANNEL_PAGE_PATHS,
  CHANNEL_TYPES,
  MEETING_TYPES_PAGE_PATHS,
} from "./props";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import { INTEGRATION_TYPES } from "../integrations/props";
import { DRAWER, useModalV2 } from "../components/modal";
import ChannelsTagsManager from "./tags/Manager";
import { useHasOrgAdminPermissions } from "../auth";
import useIntegrationsRepository from "../repository/integrations";
import useChannelsRepository from "../repository/channels";
import style from "./style.module.scss";
import { useTagsRepository } from "../repository";
import { buildQueryString } from "../utils";
import { INVITE_STYLE } from "src/meetingTypes/invite/props";

/*
 * -- EXAMPLES --
 * meetingChannels/import
 * channels/update
 */
const CHANNEL_TYPE_REGEX =
  /(meetingChannels|channels)\/(?<type>import|intercept|update)/;

function getTabFromPath(pathname) {
  const result = pathname.match(CHANNEL_TYPE_REGEX);
  if (result === null) {
    return CHANNEL_TYPES.IMPORT;
  }
  const { groups } = result;
  if (groups && "type" in groups) {
    return groups.type;
  }
  return CHANNEL_TYPES.IMPORT;
}

function usePaths(id) {
  const location = useLocation();
  const { pathname } = location;
  const buildPaths = useCallback(() => {
    const pathBuilders = pathname.includes("meetingTypes")
      ? MEETING_TYPES_PAGE_PATHS
      : CHANNEL_PAGE_PATHS;
    return {
      // import is a key word so suffix `Path` to it
      importPath: pathBuilders.import(id),
      interceptPath: pathBuilders.intercept(id),
      redirect: pathBuilders.redirect(id),
      updatePath: pathBuilders.update(id),
    };
  }, [pathname, id]);
  const [paths, setPaths] = useState(buildPaths());
  useEffect(() => {
    setPaths(buildPaths());
  }, [buildPaths, setPaths]);
  return paths;
}

function Channels({ meetingDefinition = null }) {
  const { getChannels } = useChannelsRepository();
  const integrationsRepository = useIntegrationsRepository();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const channelState = useReducer(ChannelsStateReducer, DefaultChannelState);
  const [state, dispatch] = channelState;
  const [modalState, dispatchModalState] = useReducer(
    ModalStateReducer,
    DefaultModalState,
  );
  const { importPath, interceptPath, redirect, updatePath } = usePaths(
    meetingDefinition?.id,
  );
  const location = useLocation();
  const { pathname } = location;
  const [selectedTab, setSelectedTab] = useState(getTabFromPath(pathname));
  const page = useMemo(() => {
    if (pathname.includes("meetingTypes")) {
      return "meetingtypes";
    }
    return "channels";
  }, [pathname]);
  const [meetingTypes, setMeetingTypes] = useState({
    data: [],
    isLoading: false,
  });
  const [fetchFieldData, setFetchFieldData] = useState(true);
  const history = useHistory();
  const params = useParams();
  const { addError } = useGeneralNotifications();

  const [TagManager, openTagManager, , setPassedInProps] = useModalV2(
    ChannelsTagsManager,
    DRAWER,
  );

  const handleOpenTagManager = useCallback(
    (props) => {
      setPassedInProps(props);
      openTagManager();
    },
    [setPassedInProps, openTagManager],
  );

  const goToImport = useCallback(() => {
    history.push(importPath);
    setSelectedTab(CHANNEL_TYPES.IMPORT);
  }, [history, importPath]);

  const goToIntercept = useCallback(() => {
    history.push(interceptPath);
    setSelectedTab(CHANNEL_TYPES.INTERCEPT);
  }, [history, interceptPath]);

  const goToUpdate = useCallback(() => {
    history.push(updatePath);
    setSelectedTab(CHANNEL_TYPES.UPDATE);
  }, [history, updatePath]);

  const objectConversion = useCallback(
    (serverResponse, type, integrationName) => {
      dispatch({
        payload: {
          channelType: type,
          fieldObject: serverResponse.reduce(
            (acc, { object_key: objectKey, data }) => {
              acc[objectKey] = data;
              return acc;
            },
            {},
          ),
          integrationName,
        },
        type: "SET_INTEGRATION_FIELD",
      });
    },
    [dispatch],
  );

  const fetchIntegrations = useCallback(async () => {
    if (hasOrgAdminPermissions) {
      const integrations = await integrationsRepository.getIntegrations();

      if (integrations) {
        integrations.forEach((integration) => {
          if (integration.name === INTEGRATION_TYPES.SALESFORCE) {
            [
              CHANNEL_TYPES.IMPORT,
              CHANNEL_TYPES.INTERCEPT,
              CHANNEL_TYPES.UPDATE,
            ].forEach((type) => {
              dispatch({
                payload: {
                  channelType: type,
                  integration,
                },
                type: "SET_INTEGRATION",
              });
            });
          }

          if (integration.name === INTEGRATION_TYPES.HUBSPOT) {
            [CHANNEL_TYPES.IMPORT, CHANNEL_TYPES.UPDATE].forEach((type) => {
              dispatch({
                payload: {
                  channelType: type,
                  integration,
                },
                type: "SET_INTEGRATION",
              });
            });
          }

          if (integration.name === INTEGRATION_TYPES.DYNAMICS) {
            [CHANNEL_TYPES.IMPORT, CHANNEL_TYPES.UPDATE].forEach((type) => {
              dispatch({
                payload: {
                  channelType: type,
                  integration,
                },
                type: "SET_INTEGRATION",
              });
            });
          }

          if (integration.name === INTEGRATION_TYPES.OUTREACH) {
            [CHANNEL_TYPES.IMPORT, CHANNEL_TYPES.UPDATE].forEach((type) => {
              dispatch({
                payload: {
                  channelType: type,
                  integration,
                },
                type: "SET_INTEGRATION",
              });
            });
          }
        });
      }
      dispatch({
        payload: {
          integrations,
        },
        type: "SET_INTEGRATIONS",
      });
    }
  }, [dispatch, hasOrgAdminPermissions, integrationsRepository]);

  const fetchFields = useCallback(
    async (channelType) => {
      if (channelType) {
        dispatch({
          payload: {
            channelType,
            value: true,
          },
          type: "SET_IS_LOADING",
        });

        if (
          (channelType === CHANNEL_TYPES.IMPORT ||
            channelType === CHANNEL_TYPES.UPDATE) &&
          state.integrations.has(INTEGRATION_TYPES.HUBSPOT)
        ) {
          const integration = state.integrations.get(INTEGRATION_TYPES.HUBSPOT);

          try {
            const res = await get(
              API.integrations.hubspot.oFields(integration.id, channelType),
            ).then((response) => response.json());

            dispatch({
              payload: {
                channelType,
                errorLocation: null,
                integrationType: INTEGRATION_TYPES.HUBSPOT,
              },
              type: "SET_CHANNEL_ERROR",
            });

            objectConversion(res, channelType, INTEGRATION_TYPES.HUBSPOT);
          } catch (error) {
            if (error.message === "500") {
              dispatch({
                payload: {
                  channelType,
                  errorLocation: "fetchFields",
                  integrationType: INTEGRATION_TYPES.HUBSPOT,
                },
                type: "SET_CHANNEL_ERROR",
              });

              addError(
                `Unable to fetch ${channelType} Field Object for Hubspot`,
              );
            }
          }
        }

        if (
          (channelType === CHANNEL_TYPES.IMPORT ||
            channelType === CHANNEL_TYPES.UPDATE) &&
          state.integrations.has(INTEGRATION_TYPES.DYNAMICS)
        ) {
          const integration = state.integrations.get(
            INTEGRATION_TYPES.DYNAMICS,
          );

          try {
            const res = await get(
              API.integrations.dynamics.oFields(integration.id, channelType),
            ).then((response) => response.json());

            dispatch({
              payload: {
                channelType,
                errorLocation: null,
                integrationType: INTEGRATION_TYPES.DYNAMICS,
              },
              type: "SET_CHANNEL_ERROR",
            });

            objectConversion(res, channelType, INTEGRATION_TYPES.DYNAMICS);
          } catch (error) {
            if (error.message === "500") {
              dispatch({
                payload: {
                  channelType,
                  errorLocation: "fetchFields",
                  integrationType: INTEGRATION_TYPES.DYNAMICS,
                },
                type: "SET_CHANNEL_ERROR",
              });

              addError(
                `Unable to fetch ${channelType} Field Object for Dynamics`,
              );
            }
          }
        }

        if (state.integrations.has(INTEGRATION_TYPES.SALESFORCE)) {
          const integration = state.integrations.get(
            INTEGRATION_TYPES.SALESFORCE,
          );

          try {
            const res = await get(
              API.integrations.salesforce.oFields(integration.id, channelType),
            ).then((response) => response.json());

            dispatch({
              payload: {
                channelType,
                errorLocation: null,
                integrationType: INTEGRATION_TYPES.SALESFORCE,
              },
              type: "SET_CHANNEL_ERROR",
            });

            objectConversion(res, channelType, INTEGRATION_TYPES.SALESFORCE);
          } catch ({ message }) {
            if (message === "500") {
              dispatch({
                payload: {
                  channelType,
                  errorLocation: "fetchFields",
                  integrationType: INTEGRATION_TYPES.SALESFORCE,
                },
                type: "SET_CHANNEL_ERROR",
              });

              addError(
                `Unable to fetch ${channelType} Field Object for Salesforce`,
              );
            }
          }
        }

        if (state.integrations.has(INTEGRATION_TYPES.OUTREACH)) {
          const integration = state.integrations.get(
            INTEGRATION_TYPES.OUTREACH,
          );

          try {
            const res = await get(
              API.integrations.outreach.oFields(integration.id, channelType),
            ).then((response) => response.json());

            dispatch({
              payload: {
                channelType,
                errorLocation: null,
                integrationType: INTEGRATION_TYPES.OUTREACH,
              },
              type: "SET_CHANNEL_ERROR",
            });

            objectConversion(res, channelType, INTEGRATION_TYPES.OUTREACH);
          } catch ({ message }) {
            if (message === "500") {
              dispatch({
                payload: {
                  channelType,
                  errorLocation: "fetchFields",
                  integrationType: INTEGRATION_TYPES.OUTREACH,
                },
                type: "SET_CHANNEL_ERROR",
              });

              addError(
                `Unable to fetch ${channelType} Field Object for Outreach`,
              );
            }
          }
        }

        dispatch({
          payload: {
            channelType,
            value: false,
          },
          type: "SET_IS_LOADING",
        });
      }
    },
    [state.integrations, dispatch, objectConversion, addError],
  );

  const fetchChannels = useCallback(
    async (channelType = CHANNEL_TYPES.IMPORT) => {
      if (channelType) {
        const newChannels = await getChannels(channelType);

        if (newChannels) {
          dispatch({
            payload: {
              channelType,
              channels: newChannels,
            },
            type: "SET_INTEGRATION_CHANNEL",
          });
        }

        dispatch({
          payload: {
            value: false,
          },
          type: "SET_CHANNEL_IS_LOADING",
        });
      }
    },
    [dispatch, getChannels],
  );

  const fetchMeetings = useCallback(async () => {
    setMeetingTypes((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const filter = {
      inviteStyleFilter: [
        INVITE_STYLE.CALENDAR_FIRST,
        INVITE_STYLE.CUSTOM_INVITE,
      ],
    };
    const apiUrl = `${API.meetings.default()}?${buildQueryString(filter)}`;
    const response = await get(apiUrl).then((res) => res.json());

    if (response && response.data) {
      setMeetingTypes({
        data: response.data,
        isLoading: false,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTab) {
      let hasAllFields = true;

      state[`${selectedTab}Integrations`].forEach(({ name }) => {
        if (!state[`${selectedTab}Fields`].has(name)) {
          hasAllFields = false;
        }
      });

      if (!hasAllFields) {
        setFetchFieldData(true);
      }

      if (hasAllFields) {
        setFetchFieldData(false);
      }
    }
  }, [state, selectedTab]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  useEffect(() => {
    fetchMeetings();
  }, [fetchMeetings]);

  // fetch fields only for the tab we are on
  useEffect(() => {
    if (fetchFieldData) {
      fetchFields(selectedTab);
    }
  }, [fetchFields, selectedTab, fetchFieldData]);

  // fetches channels when you change tabs
  useEffect(() => {
    fetchChannels(selectedTab);
  }, [fetchChannels, selectedTab]);

  useEffect(() => {
    setSelectedTab(getTabFromPath(pathname));
  }, [pathname]);

  const LoadingChannel = () => (
    <div className={style.channels}>
      <Icon className={style.icon} path={mdiLoading} size={1} />
    </div>
  );

  const buildChannels = useCallback(
    () => (
      <Switch>
        <Route
          path={importPath}
          render={() => (
            <Import
              openTagManager={handleOpenTagManager}
              meetingTypes={meetingTypes}
              meetingDefinition={meetingDefinition || null}
              page={page}
              params={params}
            />
          )}
        />

        <Route
          path={interceptPath}
          render={() => (
            <Intercept
              openTagManager={handleOpenTagManager}
              meetingTypes={meetingTypes}
              meetingDefinition={meetingDefinition || null}
              page={page}
              params={params}
            />
          )}
        />

        <Route
          path={updatePath}
          render={() => (
            <Update
              openTagManager={handleOpenTagManager}
              meetingTypes={meetingTypes}
              meetingDefinition={meetingDefinition || null}
              page={page}
              params={params}
            />
          )}
        />

        <Route path="*" render={() => <Redirect to={redirect} />} />
      </Switch>
    ),
    [
      handleOpenTagManager,
      importPath,
      interceptPath,
      meetingTypes,
      meetingDefinition,
      page,
      params,
      redirect,
      updatePath,
    ],
  );
  const [channels, setChannels] = useState(buildChannels());
  useEffect(() => {
    setChannels(buildChannels());
  }, [buildChannels]);

  const repository = useTagsRepository();

  if (state.channelIsLoading) {
    return <LoadingChannel />;
  }

  return (
    <>
      <TagManager
        title="Manage Tags"
        initialSelectedTags={[]}
        tags={[]}
        dispatch={dispatch}
        repository={repository}
      />
      <ModalStateContext.Provider value={[modalState, dispatchModalState]}>
        <ChannelsStateContext.Provider value={channelState}>
          {!pathname.includes("meetingTypes") && (
            <section className={cx("container", "is--fluid")}>
              <div className="row">
                <div className="col-12">
                  <Tabs className={[style.content, style.tabs]}>
                    <Tab
                      index={importPath}
                      title={CHANNEL_TYPES.IMPORT}
                      name="/channels/import"
                      onClick={goToImport}
                      active={selectedTab === CHANNEL_TYPES.IMPORT}
                    />
                    <Tab
                      index={interceptPath}
                      title={CHANNEL_TYPES.INTERCEPT}
                      name="/channels/intercept"
                      onClick={goToIntercept}
                      active={selectedTab === CHANNEL_TYPES.INTERCEPT}
                    />
                    <Tab
                      index={updatePath}
                      title={CHANNEL_TYPES.UPDATE}
                      name="/channels/update"
                      onClick={goToUpdate}
                      active={selectedTab === CHANNEL_TYPES.UPDATE}
                    />
                  </Tabs>
                </div>
              </div>

              {channels}
            </section>
          )}
          {pathname.includes("meetingTypes") && (
            <div className={style.meetingTypesChannel}>
              <div className={style.meetingTypesChannelCategories}>
                <div
                  role="button"
                  tabIndex={0}
                  name="/meetingtypes/import"
                  onClick={goToImport}
                  onKeyPress={goToImport}
                  className={cx(style.meetingTypesChannelCategory, {
                    [style["meetingTypesChannelCategory--active"]]:
                      pathname.includes(CHANNEL_TYPES.IMPORT),
                  })}
                >
                  Import
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  name="/meetingtypes/intercept"
                  onClick={goToIntercept}
                  onKeyPress={goToIntercept}
                  className={cx(style.meetingTypesChannelCategory, {
                    [style["meetingTypesChannelCategory--active"]]:
                      pathname.includes(CHANNEL_TYPES.INTERCEPT),
                  })}
                >
                  Intercept
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  name="/meetingtypes/update"
                  onClick={goToUpdate}
                  onKeyDown={goToUpdate}
                  className={cx(style.meetingTypesChannelCategory, {
                    [style["meetingTypesChannelCategory--active"]]:
                      pathname.includes(CHANNEL_TYPES.UPDATE),
                  })}
                >
                  Update
                </div>
              </div>

              {channels}
            </div>
          )}
        </ChannelsStateContext.Provider>
      </ModalStateContext.Provider>
    </>
  );
}

export default Channels;
