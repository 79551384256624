import moment from "moment";
import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useDedupeInterval } from "../configuration";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

interface DailyStats {
  acceptPendingQuorum: number;
  accepted: number;
  awaitingResponse: number;
  date: string;
  declined: number;
  hostIntervened: number;
  negotiationInProgress: number;
  noResponse: number;
  paused: number;
  requiresHostIntervention: number;
  respondedUnknownIntent: number;
  staging: number;
}

interface TimelineData {
  total: number;
  data: DailyStats[];
  earliestDate: string;
  latestDate: string;
}

export function useMeetingTimeline(search: any) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  search.paging = { limit: 100, offset: 0 };

  const { filter } = search;
  const {
    dateFilter = null,
    meetingStatusFilter,
    meetingTypeFilter,
    tagFilter,
    query,
    ...remaining
  } = filter;
  const formattedFetchSetting = {
    ...search,
    filter: {
      ...remaining,
    },
  };

  // dateFilter is null for timeline searches
  if (dateFilter !== null) {
    const { by = "startTime" } = dateFilter;
    let t = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (t === undefined) {
      t = "UTC";
    }
    formattedFetchSetting.filter[
      by === "startTime" ? "dateFilter" : "lastActivityFilter"
    ] = {
      end: dateFilter.end
        ? moment(dateFilter.end).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      start: dateFilter.start
        ? moment(dateFilter.start).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      timezone: t,
    };
  }
  if (tagFilter.length > 0) {
    formattedFetchSetting.filter.tagFilter = tagFilter.map(
      (tag: any) => tag.id,
    );
  }
  if (meetingTypeFilter.length > 0) {
    formattedFetchSetting.filter.meetingTypeFilter = meetingTypeFilter.map(
      (meetingType: any) => meetingType.id,
    );
  }
  if (meetingStatusFilter.length > 0) {
    formattedFetchSetting.filter.meetingStatusFilter = meetingStatusFilter;
  }
  if (query.length > 0) {
    formattedFetchSetting.filter.query = query;
  }
  // `id` is a field only used by the UI
  delete formattedFetchSetting.id;

  formattedFetchSetting.paging = search.paging;
  let tz = "UTC";
  if (Intl) {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  formattedFetchSetting.timezone = tz;

  const interval = useDedupeInterval();

  const { data, error } = useSWR(
    [
      `/api/meetings/instances/timeline?page=${search.paging.offset}`,
      formattedFetchSetting,
    ],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),

    { dedupingInterval: interval, refreshInterval: 60000 },
  );

  return {
    data: data as TimelineData,
    error,
    loading: !error && !data,
  };
}
