import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { FixedSizeList as List } from "react-window";
import moment from "moment";

import {
  useSearchCalendarFilterType,
  SearchUpdaterContext,
  useSearch,
} from "../Search";
import { useMeetingTimeline } from "../../queries";
import Card from "../../components/card/Card";
import { LoadingCard } from "../../components/genericView/GenericView";
import {
  ElementLeftContext,
  ElementRightContext,
  ElementHeightContext,
  ElementWidthContext,
  ElementSize,
  useSize,
} from "../../components/size";
import useInterpolateTimeline from "./useInterpolateTimeline";
import { AddTimelinePageContext } from "../context";
import {
  ACCEPTED_STATUS,
  MEETING_START_TIME,
  ACCEPT_PENDING_QUORUM_STATUS,
  AI_NEGOTIATING_STATUS,
  AWAITING_RESPONSE_STATUS,
  DECLINED_STATUS,
  HOST_INTERVENED_STATUS,
  NO_RESPONSE_STATUS,
  PAUSED_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
  STAGING_STATUS,
  toGuestStatusColor,
  toGuestStatusLabel,
  RESPONDED_UNKNOWN_INTENT_STATUS,
} from "../props";
import style from "./timeline.module.scss";
import Flexbox from "../../components/flexbox";

const { key: TODAY_KEY } = MEETING_START_TIME.find((t) => t.key === "today");
const SetTimelineCurrentEventContext = createContext(null);
const TimelineDataContext = createContext(null);
const ScaleContext = createContext(null);
const ScaleRangeContext = createContext(null);

const ITEM_SIZE = 30;

function isInRange(date, dateRangeStart, dateRangeEnd) {
  let isDateInRange = false;
  if (dateRangeStart || dateRangeEnd) {
    if (dateRangeEnd === null) {
      if (
        dateRangeStart !== null &&
        moment(date).isSameOrAfter(dateRangeStart)
      ) {
        isDateInRange = true;
      }
    } else if (dateRangeStart === null) {
      if (moment(date).isSameOrBefore(dateRangeEnd)) {
        isDateInRange = true;
      }
    } else {
      isDateInRange =
        dateRangeStart.isSameOrBefore(moment(date).endOf("day")) &&
        dateRangeEnd.isSameOrAfter(moment(date).startOf("day"));
    }
  }
  return isDateInRange;
}

function sum(datum) {
  const {
    staging = 0,
    paused = 0,
    awaitingResponse = 0,
    negotiationInProgress = 0,
    requiresHostIntervention = 0,
    hostIntervened = 0,
    declined = 0,
    acceptPendingQuorum = 0,
    accepted = 0,
    noResponse = 0,
    respondedUnknownIntent = 0,
  } = datum;
  return [
    staging,
    paused,
    awaitingResponse,
    negotiationInProgress,
    requiresHostIntervention,
    hostIntervened,
    declined,
    acceptPendingQuorum,
    accepted,
    noResponse,
    respondedUnknownIntent,
  ].reduce((total, c) => total + c, 0);
}

function maxIndex(data) {
  if (data.length < 1) {
    return -1;
  }
  let index = 0;
  let maxValue = sum(data[index]);
  data.slice(1).forEach((datum, i) => {
    if (sum(datum) > maxValue) {
      index = i + 1;
      maxValue = sum(datum);
    }
  });
  return index;
}

function max(data) {
  if (data.length < 1) {
    throw new Error("No data.");
  }
  return sum(data[maxIndex(data)]);
}

function Scale({ children, data }) {
  const height = useContext(ElementHeightContext);
  const domain = useMemo(() => [0, max(data)], [data]);
  const range = useMemo(() => [0, height - 30], [height]);
  const buildScale = useCallback(() => {
    return (value) => {
      {
        const [min, maxValue] = domain;
        if (value <= min) {
          return range[0];
        }
        if (value >= maxValue) {
          return range[1];
        }
      }
      let valuePercent;
      {
        const [min, maxValue] = domain;
        valuePercent = (value - min) / (maxValue - min);
      }
      {
        const [min, maxValue] = range;
        return maxValue * valuePercent + min;
      }
    };
  }, [domain, range]);
  const scale = useMemo(() => buildScale(), [buildScale]);
  return (
    <ScaleContext.Provider value={scale}>
      <ScaleRangeContext.Provider value={range}>
        {children}
      </ScaleRangeContext.Provider>
    </ScaleContext.Provider>
  );
}

function toStatus(key) {
  switch (key) {
    case "accepted":
      return ACCEPTED_STATUS;
    case "acceptPendingQuorum":
      return ACCEPT_PENDING_QUORUM_STATUS;
    case "negotiationInProgress":
      return AI_NEGOTIATING_STATUS;
    case "awaitingResponse":
      return AWAITING_RESPONSE_STATUS;
    case "declined":
      return DECLINED_STATUS;
    case "hostIntervened":
      return HOST_INTERVENED_STATUS;
    case "respondedUnknownIntent":
      return RESPONDED_UNKNOWN_INTENT_STATUS;
    case "noResponse":
      return NO_RESPONSE_STATUS;
    case "paused":
      return PAUSED_STATUS;
    case "requiresHostIntervention":
      return REQUIRES_HOST_INTERVENTION_STATUS;
    case "staging":
      return STAGING_STATUS;
    default:
      throw new Error(`Unknown status key: ${key}`);
  }
}

const LoadingBar = React.memo(function LoadingBar({ load, date, nextOffset }) {
  const [, maxRange] = useContext(ScaleRangeContext);
  const addPage = useContext(AddTimelinePageContext);
  useEffect(() => {
    addPage(nextOffset);
  }, [load, addPage, nextOffset]);
  return (
    <div className={style.stackedBar} data-date={date} key={date.toString()}>
      <div className={style.stackedBar__day}>{date.format("dd")}</div>
      <div
        className={style.timelineLoadingBar}
        style={{ height: `${maxRange}px` }}
      />
    </div>
  );
});

function StackedBar({ date, heights }) {
  return (
    <div className={style.stackedBar} data-date={date} key={date.toString()}>
      <div className={style.stackedBar__day}>{date.format("dd")}</div>
      {heights.map(({ key, height }) => {
        // No need to create unless elements
        if (height === 0) {
          return null;
        }

        return (
          <div
            className={style.stackedBar__bar}
            key={key}
            style={{
              background: toGuestStatusColor(toStatus(key)),
              height,
            }}
          />
        );
      })}
    </div>
  );
}

function toStackedBar(date, heights, index, datum) {
  return (
    <StackedBar
      datum={datum}
      index={index}
      key={date}
      date={date}
      heights={heights}
    />
  );
}

function Tooltip({ event }) {
  const [left, setLeft] = useState(0);
  const timeLeft = useContext(ElementLeftContext);
  const timelineRight = useContext(ElementRightContext);
  const ref = React.createRef();
  const { width: tooltipWidth } = useSize(ref);

  useEffect(() => {
    if (event === null) {
      return;
    }

    const {
      position: { x },
    } = event;
    const halfTooltipWidth = tooltipWidth / 2;
    let newLeft = x;
    const minimumLeft = halfTooltipWidth;
    const minimumRight = timelineRight - timeLeft - halfTooltipWidth;

    if (newLeft < minimumLeft) {
      newLeft = minimumLeft;
    }

    if (newLeft > minimumRight) {
      newLeft = minimumRight;
    }

    setLeft(newLeft);
  }, [event, setLeft, timeLeft, timelineRight, tooltipWidth]);

  if (event === null) {
    return <span />;
  }
  const { data } = event;
  const { date } = data;
  const counts = [
    "accepted",
    "acceptPendingQuorum",
    "awaitingResponse",
    "declined",
    "hostIntervened",
    "negotiationInProgress",
    "noResponse",
    // 'paused',
    "requiresHostIntervention",
    "respondedUnknownIntent",
    // 'staging',
  ].reduce((acc, key) => [...acc, { count: data[key], key }], []);
  const now = new Date();
  const isCurrentYear = moment(date).isSame(now, "year");
  const dateString = isCurrentYear
    ? `${date.format("ddd M/DD")}`
    : `${date.format("ddd M/DD/YYYY")}`;
  return (
    <div className={style.toolTipWrapper} style={{ left }} ref={ref}>
      <div className={style.stackedBarTooltip}>
        <div className={style.stackedBarTooltip__date}>{dateString}</div>
        <table className={style.stackedBarTooltip__table}>
          <tbody>
            {counts.map(({ key, count }) => {
              const status = toStatus(key);
              const label = toGuestStatusLabel(status);

              return (
                <tr key={key}>
                  <td className={style.stackedBarTooltip__labelCell}>
                    <div
                      className={style.stackedBarTooltip__dot}
                      style={{
                        backgroundColor: toGuestStatusColor(status),
                      }}
                    />
                    {`${label}:`}
                  </td>
                  <td className={style.stackedBarTooltip__countCell}>
                    {count}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TimelineAxis() {
  const timelineWidth = useContext(ElementWidthContext);
  return (
    <div
      className={style.timelineAxis}
      style={{ bottom: "1.4rem", width: timelineWidth - 30 }}
    />
  );
}

const TimelineEvent = React.memo(function TimelineEvent({
  datum,
  date,
  staging,
  paused,
  awaitingResponse,
  negotiationInProgress,
  requiresHostIntervention,
  hostIntervened,
  declined,
  acceptPendingQuorum,
  accepted,
  noResponse,
  respondedUnknownIntent,
}) {
  return toStackedBar(
    date,
    [
      { height: staging, key: "staging" },
      { height: paused, key: "paused" },
      { height: awaitingResponse, key: "awaitingResponse" },
      {
        height: negotiationInProgress,
        key: "negotiationInProgress",
      },
      {
        height: requiresHostIntervention,
        key: "requiresHostIntervention",
      },
      {
        height: hostIntervened,
        key: "hostIntervened",
      },
      {
        height: declined,
        key: "declined",
      },
      {
        height: acceptPendingQuorum,
        key: "acceptPendingQuorum",
      },
      {
        height: accepted,
        key: "accepted",
      },
      {
        height: noResponse,
        key: "noResponse",
      },
      {
        height: respondedUnknownIntent,
        key: "respondedUnknownIntent",
      },
    ],
    datum,
  );
});

const ListItem = React.memo(function ListItem({ index, style: infiniteStyle }) {
  const scale = useContext(ScaleContext);
  const search = useSearch();
  const searchUpdater = useContext(SearchUpdaterContext);
  const [, setCalendarFilterType] = useSearchCalendarFilterType();
  const setCurrentEvent = useContext(SetTimelineCurrentEventContext);
  const data = useContext(TimelineDataContext);
  const ref = useRef(null);
  const datum = useMemo(() => {
    return data[index];
  }, [index, data]);
  const {
    date,
    staging = 0,
    paused = 0,
    awaitingResponse = 0,
    negotiationInProgress = 0,
    requiresHostIntervention = 0,
    hostIntervened = 0,
    declined = 0,
    acceptPendingQuorum = 0,
    accepted = 0,
    noResponse = 0,
    respondedUnknownIntent = 0,
  } = datum || {};
  const now = new Date();
  const isStartOfMonth = moment(date).startOf("month").isSame(date, "day");
  const isEndOfMonth = moment(date).endOf("month").isSame(date, "day");
  const isToday = moment(date).isSame(now, "day");
  const isBefore = moment(date).isBefore(now, "day");
  const isDateInRange = isInRange(
    date,
    search.filter.dateFilter.start,
    search.filter.dateFilter.end,
  );
  const updateDateFilter = useCallback(() => {
    if (isToday) {
      setCalendarFilterType(TODAY_KEY);
    } else {
      setCalendarFilterType("custom");
    }
    searchUpdater.setDateFilter({
      end: moment(date).endOf("day"),
      start: moment(date).startOf("day"),
      timezone: "UTC",
    });
  }, [date, setCalendarFilterType, searchUpdater, isToday]);
  const showTooltipOfEvent = useCallback(() => {
    const { left } = ref.current.getBoundingClientRect();
    setCurrentEvent({
      data: datum,
      position: {
        x: left,
      },
    });
  }, [datum, setCurrentEvent]);
  return (
    <div
      tabIndex={0}
      ref={ref}
      role="button"
      index={index}
      style={infiniteStyle}
      className={cx(style.stackedBar__container, {
        [style.timeline__startOfMonth]: isStartOfMonth,
        [style.timeline__endOfMonth]: isEndOfMonth,
        [style.timeline__inRange]: isDateInRange,
        [style.timeline__before]: isBefore,
        [style.timeline__today]: isToday,
      })}
      onMouseEnter={showTooltipOfEvent}
      onFocus={showTooltipOfEvent}
      onClick={updateDateFilter}
      onKeyPress={updateDateFilter}
    >
      {datum.type === "loading" && (
        <LoadingBar
          load={datum.load}
          date={datum.date}
          nextOffset={datum.nextOffset}
        />
      )}
      {datum.type !== "loading" && (
        <TimelineEvent
          datum={datum}
          date={date}
          staging={scale(staging)}
          paused={scale(paused)}
          awaitingResponse={scale(awaitingResponse)}
          negotiationInProgress={scale(negotiationInProgress)}
          requiresHostIntervention={scale(requiresHostIntervention)}
          hostIntervened={scale(hostIntervened)}
          declined={scale(declined)}
          acceptPendingQuorum={scale(acceptPendingQuorum)}
          accepted={scale(accepted)}
          noResponse={scale(noResponse)}
          respondedUnknownIntent={scale(respondedUnknownIntent)}
        />
      )}
    </div>
  );
});

function TimelineEvents() {
  const data = useContext(TimelineDataContext);
  const listRef = useRef(null);
  const width = useContext(ElementWidthContext);
  const currentDayIndex = useMemo(() => {
    const today = new Date();
    return data.findIndex(({ date }) => moment(date).isSame(today, "day"));
  }, [data]);
  const centerOnCurrentDay = useCallback(() => {
    if (listRef.current !== null) {
      if (currentDayIndex > -1) {
        listRef.current.scrollToItem(currentDayIndex, "start");
      }
    }
  }, [currentDayIndex]);
  const getDate = useCallback(
    (index) => {
      const item = data[index];
      return item.date.unix();
    },
    [data],
  );
  useEffect(() => {
    centerOnCurrentDay();
  }, [centerOnCurrentDay]);
  return (
    <List
      initialScrollOffset={currentDayIndex * ITEM_SIZE}
      itemCount={data.length}
      itemKey={getDate}
      itemSize={ITEM_SIZE}
      layout="horizontal"
      ref={listRef}
      width={width}
    >
      {ListItem}
    </List>
  );
}

export function Timeline() {
  const timelineRef = useRef(null);
  const [currentEvent, setCurrentEvent] = useState(null);

  const search = useSearch();

  const modifiedSearch = useMemo(
    () => ({
      ...search,
      dateFilter: {
        by: search?.dataFilter?.by,
        end: moment().startOf("day").subtract(30, "days"),
        start: moment().startOf("day").add(90, "days"),
        timezone: search?.dataFilter?.timezone,
      },
    }),
    [search],
  );

  const { data: timeline, loading, error } = useMeetingTimeline(modifiedSearch);

  const { timeline: initial } = useInterpolateTimeline({
    error,
    hasMoreData: false,
    initialized: !loading,
    nextOffset: 0,
    resources: timeline || null,
    timeline: [],
  });
  const data = initial;

  const clear = useCallback(
    (event) => {
      event.stopPropagation();
      setCurrentEvent(null);
    },
    [setCurrentEvent],
  );

  const addPage = () => undefined;

  return (
    <Flexbox>
      {({ Row }) => (
        <Row>
          {!Array.isArray(data) && (
            <LoadingCard className={style.timeline__loadingCard} height={240} />
          )}

          {Array.isArray(data) && (
            <AddTimelinePageContext.Provider value={addPage}>
              <TimelineDataContext.Provider value={data}>
                <SetTimelineCurrentEventContext.Provider
                  value={setCurrentEvent}
                >
                  <Card
                    onMouseLeave={clear}
                    onBlur={clear}
                    className={style.timeline__card}
                    contentClassName={style.timeline_card__content}
                  >
                    <div className={style.timelineContainer} ref={timelineRef}>
                      <ElementSize elementRef={timelineRef}>
                        <TimelineAxis index={0} />

                        <Scale data={data}>
                          <TimelineEvents />
                        </Scale>

                        <Tooltip event={currentEvent} />
                      </ElementSize>
                    </div>
                  </Card>
                </SetTimelineCurrentEventContext.Provider>
              </TimelineDataContext.Provider>
            </AddTimelinePageContext.Provider>
          )}
        </Row>
      )}
    </Flexbox>
  );
}
