import { Delete } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useActingAs } from "src/auth";
import { ROLE_LEVELS } from "src/auth/roles";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import FilterSearchString from "src/componentsV2/filters/FilterSearchString";
import TagFilter from "src/componentsV2/filters/TagFilter";
import TemplateStatusFilter from "src/componentsV2/filters/TemplateStatusFilter";
import { useDebounce } from "src/hooks";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import { INVITE_STYLE } from "src/meetingTypes/invite/props";
import { useActivateTemplate, useDeactivateTemplate } from "src/mutations";
import { useCloneMeetingTemplate } from "src/mutations/useCloneMeetingTemplate";
import { useCreateMeetingTemplate } from "src/mutations/useCreateMeetingTemplate";
import { useDeleteMeetingTemplate } from "src/mutations/useDeleteMeetingTemplate";
import ErrorPage from "src/pages/ErrorPage";
import {
  SkinnyMeetingDefinition,
  Team,
  useMeetingTemplates,
} from "src/queries";
import { formatDate } from "src/services/formatDateTime";
import { Tag, TemplateStatus } from "src/types";
import { getUserDetails } from "src/utils/jwtToken";

export default function KmeNewMeetingTemplates() {
  const [loadOffset, setLoadOffset] = useState(0);
  const [meetingTemplates, setMeetingTemplates] = useState<
    SkinnyMeetingDefinition[]
  >([]);
  const history = useHistory();

  const activateTemplate = useActivateTemplate();
  const deactivateTemplate = useDeactivateTemplate();
  const createMeetingTemplate = useCreateMeetingTemplate();

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [meetingTemplateToDelete, setMeetingTemplateToDelete] =
    useState<SkinnyMeetingDefinition | null>();

  const deleteMeetingTemplate = useDeleteMeetingTemplate();
  const { addError, addGeneralNotification } = useGeneralNotifications();
  const [actionInProgress, setActionInProgress] = useState(false);

  const { watch, setValue, reset, getValues } = useForm<{
    templateName: string;
    team: Team[];
    templateStatus: TemplateStatus | null;
    tag: Tag[];
    tagFilterSearch: string;
    creatorFilterSearch: string;
  }>({
    defaultValues: {
      templateName: "",
      team: [],
      templateStatus: null,
      tag: [],
      tagFilterSearch: "",
      creatorFilterSearch: "",
    },
  });

  const debouncedSearch = useDebounce(watch("templateName"), 1000);

  const status = getValues("templateStatus");

  const { data, loading, error, mutate } = useMeetingTemplates(12, loadOffset, {
    templateName: debouncedSearch,
    teams: watch("team").map((team) => team.id),
    tags: watch("tag").map((tag) => tag.id),
    status: status ? [status] : [],
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setMeetingTemplates(data.data);
  }, [data]);

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  return (
    <Box sx={{ backgroundColor: "#f1f1f1", minHeight: "100%" }}>
      <Container maxWidth="xl" disableGutters sx={{ px: "30px", pb: "65px" }}>
        <Dialog
          open={deleteDialogVisible}
          onClose={() => setDeleteDialogVisible(false)}
        >
          <DialogTitle>
            <Typography
              sx={{ color: "primary.dark", fontWeight: "bold" }}
              component="span"
              variant="h5"
            >
              Delete Template
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the{" "}
              <Typography component="span" fontWeight="bold">
                {meetingTemplateToDelete?.name}
              </Typography>{" "}
              template?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-start", px: 3 }}>
            <SecondaryButton
              disabled={actionInProgress}
              onClick={() => setDeleteDialogVisible(false)}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={actionInProgress}
              onClick={async () => {
                if (!meetingTemplateToDelete) {
                  setDeleteDialogVisible(false);
                  return;
                }
                try {
                  setActionInProgress(true);
                  await deleteMeetingTemplate(meetingTemplateToDelete.id);
                  addGeneralNotification(
                    `Successfully deleted template ${meetingTemplateToDelete.name}`,
                  );
                  setDeleteDialogVisible(false);
                } catch (error) {
                  addError("Failed to delete the template");
                  console.error(error);
                } finally {
                  setActionInProgress(false);
                  mutate();
                }
              }}
            >
              Delete
            </PrimaryButton>
          </DialogActions>
        </Dialog>

        <Stack
          sx={{
            py: "35px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: "40px", fontWeight: "bold" }}
          >
            Templates
          </Typography>
          <PrimaryButton
            onClick={async () => {
              const meetingTemplateResponse = await createMeetingTemplate();
              history.push(
                `/meeting-templates/edit/${meetingTemplateResponse.id}`,
              );
            }}
          >
            + New Template
          </PrimaryButton>
        </Stack>
        <Card sx={{ mb: "30px" }} elevation={0}>
          <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
            <FilterSearchString
              label="Template Name"
              value={watch("templateName")}
              onChange={(value) => {
                setValue("templateName", value);
              }}
            />
            <TagFilter
              search={watch("tagFilterSearch")}
              onSearchChange={(value) => {
                setValue("tagFilterSearch", value);
              }}
              value={watch("tag")}
              onChange={(value) => {
                setValue("tag", value);
              }}
            />
            <TemplateStatusFilter
              value={watch("templateStatus")}
              onChange={(value) => {
                setValue("templateStatus", value);
              }}
            />
            <Divider orientation="vertical" flexItem />
            <Button
              onClick={() => {
                reset();
              }}
            >
              Reset
            </Button>
          </Stack>
        </Card>
        {meetingTemplates.length > 0 && (
          <>
            <Grid
              sx={{ marginBottom: 4 }}
              container
              columnSpacing={5}
              rowSpacing={4}
            >
              {meetingTemplates.map((template) => (
                <Grid item xs={12} md={6} lg={4} key={template.id}>
                  <MeetingTemplateCard
                    template={template}
                    onActivate={async (active) => {
                      if (active) {
                        await activateTemplate(template.id);
                      } else {
                        await deactivateTemplate(template.id);
                      }

                      setMeetingTemplates((oldTemplates) =>
                        oldTemplates.map((templateItem) => {
                          if (templateItem.id === template.id) {
                            templateItem.active = active;
                          }
                          return templateItem;
                        }),
                      );
                    }}
                    onDeleteTemplate={() => {
                      setMeetingTemplateToDelete(template);
                      setDeleteDialogVisible(true);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            {meetingTemplates.length < (data?.total ?? 0) && (
              <Button
                onClick={() => {
                  setLoadOffset((oldOffset) => oldOffset + 12);
                }}
              >
                Show More
              </Button>
            )}
          </>
        )}
        {loading && <CircularProgress />}
      </Container>
    </Box>
  );
}

type MeetingTemplateCardProps = {
  template: SkinnyMeetingDefinition;
  onActivate: (active: boolean) => void;
  onDeleteTemplate: () => void;
};

const MeetingTemplateCard = (props: MeetingTemplateCardProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { addGeneralNotification, addError } = useGeneralNotifications();
  const cloneMeetingTemplate = useCloneMeetingTemplate();

  const userDetails = getUserDetails();
  const actingAsDetails = useActingAs();

  // If the user is the default user, and they haven't created the template, they shouldn't be able to edit it.
  let ableToEdit = true;
  if (actingAsDetails[0]?.role === ROLE_LEVELS.DEFAULT) {
    ableToEdit =
      actingAsDetails[0].id === props.template.creationData.creatorData.userId;
  } else if (userDetails?.role === ROLE_LEVELS.DEFAULT) {
    ableToEdit =
      userDetails?.id === props.template.creationData.creatorData.userId;
  }

  const [isLoading, setIsLoading] = useState(false);
  const hasMeetingLink = !!(
    props.template.schedulingUrls && props.template.schedulingUrls.length > 0
  );

  return (
    <Card sx={{ minHeight: "140px" }} elevation={0}>
      <CardContent>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              color: "primary.dark",
              width: "100%",
            }}
          >
            {props.template.inviteStyle === INVITE_STYLE.CALENDAR_FIRST && (
              <CalendarMonthIcon width={20} height={16} />
            )}
            {props.template.inviteStyle === INVITE_STYLE.CUSTOM_INVITE && (
              <MailOutlineIcon width={20} height={16} />
            )}
            {props.template.inviteStyle === INVITE_STYLE.LINK_FIRST && (
              <LinkIcon width={20} height={16} />
            )}

            <Tooltip title={props.template.name} placement="top">
              <Typography
                sx={{
                  [theme.breakpoints.down("md")]: {
                    maxWidth: "550px",
                  },
                  [theme.breakpoints.up("md")]: {
                    maxWidth: "290px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    maxWidth: "150px",
                  },
                }}
                variant="h5"
                fontSize={22}
                fontWeight="bold"
                noWrap
              >
                {props.template.name}
              </Typography>
            </Tooltip>
          </Stack>
          <Switch
            checked={props.template.active}
            title={props.template.active ? "Deactivate" : "Activate"}
            disabled={isLoading || !ableToEdit}
            onChange={async (event) => {
              setIsLoading(true);
              try {
                props.template.active = event.target.checked;
                await props.onActivate(event.target.checked);
                addGeneralNotification(
                  `Template ${props.template.name} successfully ${
                    event.target.checked ? "activated!" : "deactivated!"
                  }`,
                );
              } catch (error) {
                addError("Template activation failed");
                console.error(error);
              } finally {
                setIsLoading(false);
              }
            }}
          />
        </Stack>

        <Box sx={{ mb: 3 }}>
          <Typography component="span" fontSize={12} fontWeight="bold">
            Created At:{" "}
          </Typography>
          <Typography
            component="span"
            fontSize={12}
            sx={{ marginRight: "5px" }}
          >
            {formatDate(props.template.creationData.createdAt)}
          </Typography>

          <Typography component="span" fontSize={12} fontWeight="bold">
            Owner:{" "}
          </Typography>
          <Typography component="span" fontSize={12}>
            {props.template.creationData.creatorData.userFirstName}{" "}
            {props.template.creationData.creatorData.userLastName}
          </Typography>
        </Box>

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingRight: "4px",
            gap: 1,
          }}
        >
          <Box>
            <IconButton
              sx={{ borderRadius: "9999px", backgroundColor: "#E1E6EB" }}
              title="Copy scheduling link"
              aria-label="copy-scheduling-link"
              size="small"
              disabled={isLoading || !hasMeetingLink}
              onClick={async () => {
                try {
                  if (
                    props.template.schedulingUrls &&
                    props.template.schedulingUrls.length > 0
                  ) {
                    const url = props.template.schedulingUrls[0].url;
                    await navigator.clipboard.writeText(url);
                    addGeneralNotification("1 Click link copied to clipboard!");
                  }
                } catch (err) {
                  addError("Failed to copy the 1 click link to clipboard");
                }
              }}
            >
              <LinkIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box>
            <IconButton
              sx={{ borderRadius: "9999px", backgroundColor: "#E1E6EB" }}
              title="Clone"
              aria-label="clone"
              size="small"
              disabled={isLoading}
              onClick={async () => {
                addGeneralNotification(
                  `Cloning template ${props.template.name}`,
                );
                setIsLoading(true);
                try {
                  const clonedTemplate = await cloneMeetingTemplate(
                    props.template.id,
                  );
                  addGeneralNotification(
                    `Template ${props.template.name} cloned successfully!`,
                  );
                  history.push(`/meeting-templates/edit/${clonedTemplate.id}`);
                } catch (error) {
                  addError("Failed to clone the template");
                  console.error(error);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              sx={{ borderRadius: "9999px", backgroundColor: "#E1E6EB" }}
              title="Edit"
              aria-label="edit"
              size="small"
              disabled={isLoading || !ableToEdit}
              onClick={() =>
                history.push(`/meeting-templates/edit/${props.template.id}`)
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              sx={{ borderRadius: "9999px", backgroundColor: "#E1E6EB" }}
              title="Delete"
              aria-label="delete"
              size="small"
              disabled={isLoading || !ableToEdit}
              onClick={() => props.onDeleteTemplate()}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
