import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";
import ReactModal from "react-modal";
import { DEFAULT, DRAWER } from "./types";
import style from "./modal.module.scss";

export default function useModalV2(
  Content,
  type = DEFAULT,
  { open: initialOpen = false, onClose = null } = {},
) {
  // Passed In Props are good for values that you want to minimize re-renders on
  const [passedInProps, setPassedInProps] = useState(null);
  const [isOpen, setIsOpen] = useState(initialOpen);
  const isDrawer = useMemo(() => {
    return type === DRAWER;
  }, [type]);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
    if (onClose !== null) {
      onClose();
    }
  }, [onClose, setIsOpen]);

  return useMemo(() => {
    return [
      function ModalWrapper(props) {
        return (
          <ReactModal
            shouldCloseOnEsc
            isOpen={isOpen}
            className={cx({
              [style.drawer__content]: isDrawer,
              "modal--content": !isDrawer,
            })}
            overlayClassName={cx({
              [style.drawer__overlay]: isDrawer,
              "modal--overlay": !isDrawer,
            })}
            shouldCloseOnOverlayClick={!isDrawer}
            onRequestClose={close}
          >
            <Content close={close} {...props} {...passedInProps} />
          </ReactModal>
        );
      },
      open,
      close,
      setPassedInProps,
    ];
  }, [isDrawer, isOpen, open, close, passedInProps, Content]);
}
