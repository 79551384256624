import React, { useCallback } from "react";

import { TagManager } from "../../components/tagManager";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { useUpdateMeetingTags, useUpdateTag } from "../../mutations";
import { useTagsRepository } from "../../repository";

export function ManageTags({ close, instanceId, tags }) {
  const { addError } = useGeneralNotifications();
  const updateMeetingTags = useUpdateMeetingTags();
  const updateTag = useUpdateTag();

  const repository = useTagsRepository();

  const saveInstanceTags = useCallback(
    (selectedTags) => {
      updateMeetingTags(
        [instanceId],
        selectedTags.map((t) => t.id),
      ).catch(() => {
        addError(`Failed to update tags for meeting ${instanceId}`);
      });
    },
    [instanceId, updateMeetingTags, addError],
  );
  return (
    <TagManager
      close={close}
      initialSelectedTags={tags}
      onRemove={useCallback(() => [], [])}
      onSave={saveInstanceTags}
      onEdit={(id, tag) => updateTag(id, tag.name)}
      repository={repository}
      title="Manage Tags"
    />
  );
}
