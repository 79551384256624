function formatThousands(value, toDecimalPlaces = 2) {
  if (value && (typeof value === "string" || typeof value === "number")) {
    let formattedValue = value;
    let notation = null;

    if (typeof value === "string") {
      formattedValue = +value;
    }

    if (formattedValue >= 1.0e3 && formattedValue < 1.0e6) {
      formattedValue = (formattedValue / 1.0e3).toFixed(toDecimalPlaces);
      notation = "k";
    } else if (formattedValue >= 1.0e6 && formattedValue < 1.0e9) {
      formattedValue = (formattedValue / 1.0e6).toFixed(toDecimalPlaces);
      notation = "m";
    } else if (formattedValue >= 1.0e9 && formattedValue < 1.0e12) {
      formattedValue = (formattedValue / 1.0e9).toFixed(toDecimalPlaces);
      notation = "b";
    } else if (formattedValue >= 1.0e12) {
      formattedValue = (formattedValue / 1.0e12).toFixed(toDecimalPlaces);
      notation = "t";
    }

    return [formattedValue, notation, `${formattedValue}${notation || ""}`];
  }

  return [0, "", `0`];
}

/**
 * this function takes in a toLocaleString() number (ex: $126,000 as a string)
 * @param {$localeString} amount (this MUST have $ as first position -- string)
 */
function shortenCurrency(amount) {
  if (typeof amount !== "string") return;

  if (amount[0] !== "$") return amount;

  // turn back to number to use
  const numAmount = +amount.slice(1).split(",").join("");

  if (numAmount < 1000) {
    return amount;
  }

  if (numAmount >= 1000000000) {
    return `$${(numAmount / 1000000000).toFixed(3)}B`;
  }

  if (numAmount >= 1000000) {
    return `$${(numAmount / 1000000).toFixed(2)}M`;
  }

  if (numAmount >= 1000) {
    return `$${(numAmount / 1000).toFixed(1)}K`;
  }
}

function formatCurrency(amount, digits = 0) {
  if (typeof amount === "number") {
    return shortenCurrency(
      amount.toLocaleString("en-US", {
        currency: "USD",
        minimumFractionDigits: digits,
        style: "currency",
      }),
    );
  }

  if (!Number.isNaN(amount) || typeof amount === "string") {
    const amt = parseInt(amount, 10);

    return shortenCurrency(
      amt.toLocaleString("en-US", {
        currency: "USD",
        minimumFractionDigits: digits,
        style: "currency",
      }),
    );
  }

  return 0;
}

export { formatCurrency, formatThousands };
