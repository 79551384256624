import React from "react";
import cx from "classnames";

import style from "./input.module.scss";

export function Input({
  autocomplete: isAutoCompleteOn = false,
  className,
  disabled: isDisabled,
  dataCy = null,
  dataGtm = null,
  small: isSmall = false,
  medium: isMedium = false,
  large: isLarge = false,
  name = null,
  inputRef,
  ...props
}) {
  const dataProps = {};
  dataProps["data-gtm"] = dataGtm || name;
  dataProps["data-cy"] = dataCy || name;

  return (
    <input
      aria-label={name}
      autoComplete={isAutoCompleteOn ? "on" : "off"}
      className={cx(
        style.input,
        {
          [style["input--large"]]: isLarge,
          [style["input--medium"]]: isMedium,
          [style["input--small"]]: isSmall,
        },
        className,
      )}
      disabled={isDisabled}
      name={name}
      ref={inputRef}
      {...props}
      {...dataProps}
    />
  );
}
