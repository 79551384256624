import React, { useState } from "react";
import {
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Select,
  MenuItem,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useListPATs } from "../../../../queries/useListPATs";
import { useCreatePAT } from "../../../../mutations/useCreatePAT";
import PersonalAccessTokenList from "./PersonalAccessTokenList";
import PrimaryButton from "../../../../componentsV2/buttons/PrimaryButton";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";
import dayjs from "dayjs";

const MAX_PAT_LENGTH = 30;

interface ResponseError {
  response?: {
    body?: {
      message?: string;
    };
  };
}

// PAT is a page for generating/managing personal access tokens
function PersonalAccessTokens() {
  const theme = useTheme();
  const { data, error, loading } = useListPATs();
  const createPAT = useCreatePAT();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [enteredDescription, setEnteredDescription] = useState<string>("");
  const [newlyCreatedPAT, setNewlyCreatedPAT] = useState<string>("");
  const { addError, addGeneralNotification } = useGeneralNotifications();
  const [tokenExpiresDays, setTokenExpiresDays] = useState<string>("30");

  const onSubmitPAT = async () => {
    try {
      const expiresIn = Number.parseInt(tokenExpiresDays);
      const expiresAt: dayjs.Dayjs | null =
        expiresIn > 0 ? dayjs().add(expiresIn, "day") : null;
      const created = await createPAT(enteredDescription, expiresAt);
      setIsCreating(false);
      setEnteredDescription("");
      // show user their new PAT for them to copy
      // we never show them again
      setNewlyCreatedPAT(created.token);

      addGeneralNotification("New PAT generated");
    } catch (exc) {
      addError(
        (exc as ResponseError)?.response?.body?.message ||
          "There was a problem generating a new PAT. Please try again later",
      );
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Personal Access Tokens</Typography>
        <PrimaryButton
          onClick={() => {
            setIsCreating(true);
          }}
        >
          + Create PAT
        </PrimaryButton>
      </Stack>

      {newlyCreatedPAT.length > 0 && (
        <Stack direction="column" marginTop="15px" marginBottom="15px">
          <Typography sx={{ fontWeight: "bold" }}>
            Please copy this PAT. You will not be able to see this again.
          </Typography>
          <Stack direction="row">
            <Typography
              sx={{
                fontSize: "small",
                marginRight: "5px",
                marginBottom: "auto",
                marginTop: "auto",
                overflowWrap: "anywhere",
              }}
            >
              {newlyCreatedPAT}
            </Typography>
            <Tooltip title="Copy to clipboard" placement="top">
              <IconButton
                sx={{
                  color: theme.palette.primary.main,
                  marginBottom: "auto",
                  marginTop: "auto",
                }}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(newlyCreatedPAT);
                    addGeneralNotification("Token copied to clipboard!");
                  } catch {
                    addError(
                      "Failed to copy token to clipboard. Please copy the token manually.",
                    );
                  }
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}

      {loading && <CircularProgress />}
      {error && (
        <Typography color="error">
          Unable to retrieve Personal Access Token data. Please try again later.
        </Typography>
      )}
      {isCreating && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitPAT();
          }}
        >
          <Stack direction="column" width="50%" marginTop="15px">
            <TextField
              fullWidth
              label="Enter PAT Description"
              value={enteredDescription}
              onChange={(e) => {
                setEnteredDescription(e.target.value);
              }}
              type="text"
              inputProps={{
                maxLength: MAX_PAT_LENGTH,
              }}
            />
            <Select
              sx={{
                marginTop: "5px",
              }}
              value={tokenExpiresDays}
              onChange={(e) => setTokenExpiresDays(e.target.value)}
            >
              <MenuItem value={7}>Expires after 7 days </MenuItem>
              <MenuItem value={30}>Expires after 30 days</MenuItem>
              <MenuItem value={60}>Expires after 60 days</MenuItem>
              <MenuItem value={90}>Expires after 90 days</MenuItem>
              <MenuItem value={180}>Expires after 180 days</MenuItem>
              <MenuItem value={0}>No Expiration</MenuItem>
            </Select>
            <PrimaryButton
              sx={{
                width: "25%",
                marginTop: "5px",
                marginLeft: "auto",
              }}
              type="submit"
            >
              Create
            </PrimaryButton>
          </Stack>
        </form>
      )}
      {data && <PersonalAccessTokenList data={data} />}
    </>
  );
}

export default PersonalAccessTokens;
