import { useDebounce } from "src/hooks";
import { useMeetingTemplates, SkinnyMeetingDefinition } from "src/queries";

import { FilterAutocomplete } from "./FilterAutocomplete";

export function TemplateFilter(props: {
  search: string;
  onSearchChange: (value: string) => void;
  value?: number[];
  onChange?: (value: SkinnyMeetingDefinition[]) => void;
}) {
  const searchDebounced = useDebounce(props.search, 750);

  const filters = {
    templateName: searchDebounced,
  };
  const { data, loading } = useMeetingTemplates(100, 0, filters);

  return (
    <FilterAutocomplete
      label="Template"
      value={(data?.data || []).filter((meetingTemplate) =>
        (props.value || []).includes(meetingTemplate.id),
      )}
      onChange={props.onChange}
      loading={loading}
      search={props.search}
      onSearchChange={props.onSearchChange}
      options={data?.data || []}
      getOptionLabel={(template) => template.name}
      getOptionId={(template) => template.id}
    />
  );
}

export default TemplateFilter;
