import React, { useState } from "react";
import { Box, Stack, Switch, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { PersonalAccessToken } from "../../../../types/PersonalAccessToken";
import { useEnablePAT } from "../../../../mutations/useEnablePAT";
import HighEmphasisButton from "../../../../componentsV2/buttons/HighEmphasisButton";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";
import DisablePATDialog from "./dialogs/DisablePATDialog";
import DeletePATDialog from "./dialogs/DeletePATDialog";

function PersonalAccessTokenList({ data }: { data: PersonalAccessToken[] }) {
  const enablePAT = useEnablePAT();
  const { addError, addGeneralNotification } = useGeneralNotifications();
  const [disableToken, setDisableToken] = useState<PersonalAccessToken | null>(
    null,
  );
  const [deleteToken, setDeleteToken] = useState<PersonalAccessToken | null>(
    null,
  );

  return (
    <Box>
      {disableToken && (
        <DisablePATDialog
          token={disableToken}
          onClose={() => {
            setDisableToken(null);
          }}
        />
      )}
      {deleteToken && (
        <DeletePATDialog
          token={deleteToken}
          onClose={() => {
            setDeleteToken(null);
          }}
        />
      )}
      <Stack direction="column">
        {data.map((token: PersonalAccessToken) => {
          const lastUsed: dayjs.Dayjs = dayjs(token.lastUsed);
          const createdAt: dayjs.Dayjs = dayjs(token.createdAt);

          return (
            <Stack
              direction="row"
              sx={{
                marginTop: "25px",
              }}
              key={`pat-${token.id}`}
            >
              <Tooltip
                placement="top"
                title={token.enabled ? "Disable" : "Enable"}
              >
                <Switch
                  sx={{
                    marginBottom: "auto",
                    marginRight: 2,
                    marginTop: "auto",
                  }}
                  checked={token.enabled}
                  onChange={async () => {
                    // if token is currently enabled, we are disabling it
                    if (token.enabled) {
                      setDisableToken(token);
                      return;
                    }

                    // otherwise, we are enabling it
                    try {
                      await enablePAT(token.id);
                      addGeneralNotification(`${token.description} enabled`);
                    } catch {
                      addError(
                        `There was a problem enabling ${token.description}. Please try again later.`,
                      );
                    }
                  }}
                />
              </Tooltip>
              <Stack direction="column">
                <Typography
                  component="span"
                  sx={{ fontSize: "small", marginBottom: "1px" }}
                >
                  Description:{" "}
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {token.description}
                  </Typography>
                </Typography>
                <Typography sx={{ fontSize: "small", marginBottom: "1px" }}>
                  Last Used:{" "}
                  {
                    // check if `lastUsed` is after `createdAt`. if not, then the token has never been used
                    lastUsed.isAfter(createdAt)
                      ? lastUsed.format("MMMM DD, YYYY [at] h:mm:ssa z")
                      : "Never"
                  }
                </Typography>
                <Typography sx={{ fontSize: "small", marginBottom: "1px" }}>
                  Expires:{" "}
                  <span style={{ color: token.expired ? "red" : "" }}>
                    {token.expiresAt
                      ? dayjs(token.expiresAt).format(
                          "MMMM DD, YYYY [at] h:mm:ssa z",
                        )
                      : "Never"}
                  </span>
                </Typography>
                <Typography sx={{ fontSize: "small" }}>
                  Created: {createdAt.format("MMMM DD, YYYY [at] h:mm:ssa z")}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                sx={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "auto",
                }}
              >
                <HighEmphasisButton
                  disabled={false}
                  onClick={() => {
                    setDeleteToken(token);
                  }}
                >
                  Delete
                </HighEmphasisButton>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
}

export default PersonalAccessTokenList;
