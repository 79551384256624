import { useCallback, useEffect, useRef, useState } from "react";
import { buildRequest } from "../utils/fetchV2";
import { API } from "../props";
import { useCustomFetchFn } from "../components/fetch";

const buildGetMeetingHistory = (id) => buildRequest(API.meetings.history(id));

const useGetMeetingHistory = () => {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    (id) => fetch(buildGetMeetingHistory(id)).then((res) => res.json()),
    [fetch],
  );
};

const buildPostMeetingHistoryMessage = (reqBody) =>
  buildRequest(API.meetings.message, {
    body: JSON.stringify(reqBody),
    method: "POST",
  });

const usePostMeetingHistoryMessage = () => {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    (reqBody) =>
      fetch(buildPostMeetingHistoryMessage(reqBody)).then((res) => res.json()),
    [fetch],
  );
};

const buildGetCalendarInvite = (id) =>
  buildRequest(API.meetings.calendarInvite(id));

const useGetCalendarInvite = () => {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    (id) => fetch(buildGetCalendarInvite(id)).then((res) => res.json()),
    [fetch],
  );
};

const useCalendarInvite = (meetingId = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const getCalendarInvite = useGetCalendarInvite();

  useEffect(() => {
    const run = async () => {
      try {
        setIsLoading(true);
        const response = await getCalendarInvite(meetingId);
        setData(response);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (meetingId) {
      run();
    }
  }, [meetingId, getCalendarInvite]);

  return { data, error, isLoading };
};

const useMeetingHistoryRepository = () => {
  const getMeetingHistory = useGetMeetingHistory();
  const postMeetingHistoryMessage = usePostMeetingHistoryMessage();
  const getCalendarInvite = useGetCalendarInvite();

  const { current } = useRef({
    getCalendarInvite,
    getMeetingHistory,
    postMeetingHistoryMessage,
    useCalendarInvite,
  });

  return current;
};

export default useMeetingHistoryRepository;
