import {
  Card,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AddDomainDialog, ProvisioningDialog } from "src/componentsV2/dialogs";
import { Input } from "src/componentsV2/inputs/Input";
import { KMeOrganizationTable } from "src/kronologic-me/componentsV2/tables/OrganizationTable";
import { PageLayout } from "src/componentsV2/PageLayout";
import { Pagination } from "src/componentsV2/Pagination";
import { TableSort } from "src/componentsV2/tables/Table";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import { useAddDomain, useProvisionOrg } from "src/mutations";
import { useEnableOrg } from "src/kronologic-me/mutations/useEnableOrg";
import { useOrganizationsSearch, useOrgVersionInfo } from "src/queries";

const PAGE_SIZE = 100;

function KMeProvisioning() {
  const { addError, addGeneralNotification } = useGeneralNotifications();

  const [showProvisioningDialog, setShowProvisioningDialog] = useState(false);
  const [showAddDomainDialog, setShowAddDomainDialog] = useState(false);

  const [isProvisioning, setIsProvisioning] = useState(false);
  const [addDomainOrg, setAddDomainOrg] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<TableSort>({
    columnId: "name",
    order: "asc",
  });

  const provisionOrg = useProvisionOrg();
  const addDomain = useAddDomain();
  const enableOrg = useEnableOrg();

  const { data: versionInfo } = useOrgVersionInfo();
  const { data: organizations, loading } = useOrganizationsSearch(100, 0, {
    search,
    sort: {
      field: sort.columnId as "name" | "enabled",
      order: sort.order,
    },
  });

  return (
    <PageLayout title="Provisioning">
      {showProvisioningDialog && (
        <ProvisioningDialog
          open
          onClose={() => setShowProvisioningDialog(false)}
          onSubmit={({ name, domains, preferredClient }) => {
            setIsProvisioning(true);
            setShowProvisioningDialog(false);
            provisionOrg(
              name,
              domains
                .filter((d) => d.length > 0)
                .map((d) => ({
                  domain: d,
                  preferredClient,
                })),
            )
              .then(() => {
                addGeneralNotification(`${name} successfully provisioned`);
              })
              .catch((e) => {
                addError(
                  JSON.parse(e?.message)?.message ||
                    "Failed to provision organizations",
                );
              })
              .finally(() => {
                setIsProvisioning(false);
              });
          }}
        />
      )}

      {showAddDomainDialog && (
        <AddDomainDialog
          open
          onSubmit={({ orgId, domain, preferredClient }) => {
            addDomain(orgId, domain, preferredClient)
              .then(() => {
                addGeneralNotification(`${domain} successfully added`);
                setShowAddDomainDialog(false);
                setAddDomainOrg(null);
              })
              .catch(() => {
                addError("Unable to add domain at this time");
              });
          }}
          onClose={() => setShowAddDomainDialog(false)}
          orgId={addDomainOrg?.id || 0}
          orgName={addDomainOrg?.name || ""}
        />
      )}

      <Stack spacing={2}>
        {isProvisioning && (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Provisioning New Org...</Typography>
            <CircularProgress color="primary" />
          </Stack>
        )}
        {versionInfo ? (
          <Stack direction="row" justifyContent="space-around" spacing={4}>
            <Card elevation={0} sx={{ p: 2, w: "150px", h: "75px" }}>
              <Typography variant="h6">Global Version</Typography>
              <Stack justifyContent="center" alignItems="center">
                <Typography align="center" fontSize={22}>
                  {versionInfo.globalVersion || "N/A"}
                </Typography>
              </Stack>
            </Card>

            <Card elevation={0} sx={{ p: 2, w: "150px", h: "75px" }}>
              <Typography variant="h6">Tenant Version</Typography>
              <Stack justifyContent="center" alignItems="center">
                <Typography align="center" fontSize={22}>
                  {Math.max(...versionInfo.orgVersions.map((o) => o.version)) ||
                    "N/A"}
                </Typography>
              </Stack>
            </Card>

            <Card elevation={0} sx={{ p: 2, w: "150px", h: "75px" }}>
              <Typography variant="h6">Total Orgs</Typography>
              <Stack justifyContent="center" alignItems="center">
                <Typography align="center" fontSize={22}>
                  {versionInfo.orgVersions.length || 0}
                </Typography>
              </Stack>
            </Card>
          </Stack>
        ) : (
          <Typography align="center">Version Info Not Available</Typography>
        )}

        <Input
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <Paper elevation={0}>
          <KMeOrganizationTable
            loading={loading}
            minRows={10}
            organizations={organizations?.data || []}
            sort={sort}
            onSortChange={setSort}
            onAddDomain={({ id, name }) => {
              setAddDomainOrg({ id, name });
              setShowAddDomainDialog(true);
            }}
            onUpdateStatus={async ({ name, id }) => {
              try {
                await enableOrg(id);
                addGeneralNotification(`${name} has been enabled.`);
              } catch {
                addError(`Unable to enable ${name}. Please try again later.`);
              }
            }}
          />
          <Pagination
            pages={Math.ceil((organizations?.total || 0) / PAGE_SIZE)}
            currentPage={page + 1}
            onPageChange={(p) => setPage(p - 1)}
          />
        </Paper>
      </Stack>
    </PageLayout>
  );
}

export default KMeProvisioning;
