import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import debounce from "lodash.debounce";
import { mdiCheck, mdiClose } from "@mdi/js";
import FieldDropdown from "./FieldDropdown";
import ConditionDropdown from "./ConditionDropdown";
import ValueDropdown from "./ValueDropdown";
import { ChannelsStateContext } from "../../../ChannelsState";
import style from "../style.module.scss";
import Button from "../../../../components/button/Button";
import ConditionText from "./ConditionText";
import { CHANNEL_TYPES } from "../../../props";
import { INTEGRATION_TYPES } from "../../../../integrations/props";

const ADD_AND = "+ AND";
const ADD_OR = "+ OR";

function Condition({
  showInvalidFields,
  groupIndex,
  conditionIndex,
  channelType,
  selectedIntegration,
  fields,
  total,
  conditions,
  setConditions,
  field,
  condition,
  value,
  conditionsBeingEdited,
  setConditionsBeingEdited,
  handleDeleteCondition,
  handleAddCondition,
  addGroup,
  isAddModal,
}) {
  const [{ [`${channelType}Error`]: channelErrorMap }] =
    useContext(ChannelsStateContext);
  const [valueOptions, setValueOptions] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [localCondition, setLocalCondition] = useState("");
  const [localField, setLocalField] = useState({
    label: "",
    name: "",
  });
  const [debouncedField, setDebouncedField] = useState({
    label: "",
    name: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const hasError = useMemo(
    () => channelErrorMap.get(selectedIntegration.name) !== null,
    [channelErrorMap, selectedIntegration.name],
  );

  // isLoading is true until the field is populated from integration API
  const isLoading = useMemo(() => field?.label === null, [field]);

  const isFirstConditionInGroup = useMemo(
    () => conditionIndex < 1,
    [conditionIndex],
  );

  const isFirstGroup = useMemo(() => groupIndex < 1, [groupIndex]);

  const isFilledOut = useMemo(
    () =>
      (localField?.label?.length > 0 || localField?.name?.length > 0) &&
      (channelType === CHANNEL_TYPES.IMPORT ||
      channelType === CHANNEL_TYPES.INTERCEPT
        ? localCondition?.length > 0
        : true) &&
      localValue?.length > 0,
    [channelType, localCondition, localField, localValue],
  );

  useEffect(() => {
    // TODO: (Sam) can this be simplified?
    setIsEditMode(
      (prev) =>
        conditionsBeingEdited.includes(conditionIndex) ||
        !isFilledOut ||
        // prev is here to prevent isEditMode auto-changing to "false" in certain edge cases
        // such as typing something in the `value` field, closing the modal, reopening the modal,
        // and then selecting something form the `field` dropdown when creating a new channel
        (prev && isAddModal),
    );
  }, [conditionsBeingEdited, conditionIndex, isAddModal, isFilledOut]);

  const enableEditing = useCallback(() => {
    setConditionsBeingEdited((prev) => [...prev, conditionIndex]);
  }, [conditionIndex, setConditionsBeingEdited]);

  const updateDebouncedValue = useMemo(() => {
    return debounce((v) => {
      setDebouncedValue(v);
    }, 500);
  }, []);

  const updateDebouncedField = useMemo(() => {
    return debounce((v) => {
      setDebouncedField(v);
    }, 500);
  }, []);

  const isOrphaned = useMemo(
    () => !!selectedIntegration.orphaned,
    [selectedIntegration],
  );

  const isOutreach = useMemo(() => {
    if (selectedIntegration.id) {
      return selectedIntegration.name === INTEGRATION_TYPES.OUTREACH;
    }

    return false;
  }, [selectedIntegration]);

  const canShowActions = useMemo(
    () => !hasError && !isOrphaned && !isOutreach,
    [hasError, isOrphaned, isOutreach],
  );

  const handleUpdateCondition = useCallback(() => {
    setConditions(
      conditions.map((group, i) => {
        if (i === groupIndex) {
          return group.reduce((acc, cur, j) => {
            if (j === conditionIndex) {
              return [
                ...acc,
                {
                  condition: localCondition,
                  field: localField,
                  value: localValue,
                },
              ];
            }

            return [...acc, cur];
          }, []);
        }

        return group;
      }),
    );
  }, [
    conditionIndex,
    conditions,
    groupIndex,
    localCondition,
    localField,
    localValue,
    setConditions,
  ]);

  useEffect(() => {
    setIsTyping(
      JSON.stringify(localField) !== JSON.stringify(debouncedField) ||
        localValue !== debouncedValue,
    );
  }, [localField, localValue, debouncedField, debouncedValue]);

  useEffect(() => {
    setLocalField((prevField) => {
      const { name: prevName, label: prevLabel } = prevField;
      const { name, label } = field;

      if (name !== prevName) {
        return field;
      }

      if (name === prevName) {
        if (prevLabel === null && label !== null) {
          return field;
        }
      }

      return prevField;
    });
  }, [field]);

  useEffect(() => {
    setLocalCondition(condition);
  }, [condition]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    updateDebouncedField(localField);
  }, [localField, updateDebouncedField]);

  useEffect(() => {
    updateDebouncedValue(localValue);
  }, [localValue, updateDebouncedValue]);

  useEffect(() => {
    if (
      !isTyping &&
      localField.label !== "" &&
      localField.name !== "" &&
      JSON.stringify(field) !== JSON.stringify(localField)
    ) {
      handleUpdateCondition();
    }
  }, [isTyping, localField, handleUpdateCondition, field]);

  useEffect(() => {
    if (!isTyping && localCondition !== "" && localCondition !== condition) {
      handleUpdateCondition();
    }
  }, [isTyping, localCondition, handleUpdateCondition, condition]);

  useEffect(() => {
    if (!isTyping && localValue !== "" && localValue !== value) {
      handleUpdateCondition();
    }
  }, [isTyping, localValue, handleUpdateCondition, value]);

  const onAddAnd = useCallback(() => {
    handleAddCondition();
  }, [handleAddCondition]);

  const onAddOr = useCallback(() => {
    addGroup();
  }, [addGroup]);

  const onDelete = useCallback(() => {
    handleDeleteCondition(conditionIndex);
  }, [conditionIndex, handleDeleteCondition]);

  const onCheckClick = useCallback(() => {
    setConditionsBeingEdited((p) => {
      p.splice(p.indexOf(conditionIndex), 1);
      return p;
    });

    setIsEditMode(false);
  }, [conditionIndex, setConditionsBeingEdited]);

  // user cannot delete first condition in first group
  const isDeletable = useMemo(() => {
    if (isFirstGroup) {
      return !isFirstConditionInGroup;
    }

    return !isFirstGroup;
  }, [isFirstConditionInGroup, isFirstGroup]);

  return (
    <div className={style.conditions__card_condition_wrapper}>
      <div className={style.conditions__card_condition_dropdowns_wrapper}>
        {!isFirstConditionInGroup && !isLoading && (
          <span
            className={
              style.conditions__card_condition_dropdowns_wrapper__and_or
            }
          >
            AND
          </span>
        )}
        {isFirstConditionInGroup && !isFirstGroup && !isLoading && (
          <span
            className={
              style.conditions__card_condition_dropdowns_wrapper__and_or
            }
          >
            OR
          </span>
        )}
        {isEditMode && (
          <>
            <FieldDropdown
              showInvalidFields={showInvalidFields}
              hasError={hasError}
              channelType={channelType}
              isOrphaned={isOrphaned}
              fields={fields}
              localField={localField}
              setValueOptions={setValueOptions}
              setLocalField={setLocalField}
              setLocalCondition={setLocalCondition}
              setLocalValue={setLocalValue}
            />

            <ConditionDropdown
              showInvalidFields={showInvalidFields}
              hasError={hasError}
              channelType={channelType}
              isOrphaned={isOrphaned}
              localCondition={localCondition}
              setLocalCondition={setLocalCondition}
              isOutreach={isOutreach}
            />

            <ValueDropdown
              showInvalidFields={showInvalidFields}
              hasError={hasError}
              channelType={channelType}
              isOrphaned={isOrphaned}
              localValue={localValue}
              valueOptions={valueOptions}
              setLocalValue={setLocalValue}
              isOutreach={isOutreach}
            />
            {canShowActions && (
              <>
                <Button
                  disabled={!isFilledOut}
                  icon={mdiCheck}
                  type="tertiary"
                  name={`/channels/${channelType}/add_channel/check_mark`}
                  onClick={onCheckClick}
                />

                {isDeletable && (
                  <Button
                    icon={mdiClose}
                    type="tertiary"
                    onClick={onDelete}
                    name={`/channels/${channelType}/add_channel/filter_addition/close`}
                  />
                )}
              </>
            )}
          </>
        )}

        {!isEditMode && (
          <>
            {isLoading ? (
              <div>Loading</div>
            ) : (
              <ConditionText
                channelType={channelType}
                condition={localCondition}
                enableEditing={enableEditing}
                field={localField}
                value={localValue}
                isOutreach={isOutreach}
              />
            )}
          </>
        )}
      </div>
      {canShowActions && (
        <div>
          {total === conditionIndex + 1 && (
            <div
              className={style.conditions__card_condition_wrapper__add_buttons}
            >
              <Button
                type="tertiary"
                name={`/channels/${channelType}/add_channel/and_filter_addition`}
                onClick={onAddAnd}
              >
                {ADD_AND}
              </Button>
              {conditions.length === groupIndex + 1 &&
                channelType === CHANNEL_TYPES.IMPORT && (
                  <Button
                    type="tertiary"
                    name={`/channels/${channelType}/add_channel/or_filter_addition`}
                    onClick={onAddOr}
                  >
                    {ADD_OR}
                  </Button>
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Condition;
