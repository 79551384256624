import { Delete, LocalOffer } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Box,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "src/componentsV2/Loading";
import { PageLayout } from "src/componentsV2/PageLayout";
import { TagList } from "src/componentsV2/TagList";
import { PrimaryButton } from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import { useConfirmationDialog } from "src/hooks";

import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import {
  useDeleteChannel,
  useTagChannels,
  useUpdateChannel,
} from "src/mutations";
import ErrorPage from "src/pages/ErrorPage";
import { useChannel } from "src/queries";
import { ChannelWithDetails } from "src/types";
import { INTEGRATION_IMG_SRC } from "../../../integrations/props";

function Channel({
  data,
  onChannelUpdate,
}: {
  data: ChannelWithDetails;
  onChannelUpdate: () => void;
}) {
  const history = useHistory();

  const tagChannels = useTagChannels();
  const deleteChannel = useDeleteChannel();
  const updateChannel = useUpdateChannel();
  const openConfirmationDialog = useConfirmationDialog();
  const { addGeneralNotification, addError } = useGeneralNotifications();

  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);

  const {
    getValues,
    reset,
    register,
    formState: { isDirty },
  } = useForm<ChannelWithDetails>({
    defaultValues: Object.assign(
      {
        id: 0,
        name: "",
        type: "",
        description: "",
        enabled: true,
        config: "",
        createdAt: "",
        tags: [],
        // integration: //FIXME: add this
        meetingTemplateID: 0,
      },
      data,
    ),
  });

  return (
    <>
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Update Channel Tags"
          open
          initialTags={data.tags}
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            try {
              await tagChannels(
                [data.id],
                tags.map((tag) => tag.id),
              );
              addGeneralNotification("Successfully updated tags");
              setShowTagManagementDialog(false);
            } catch {
              addError("Failed to update channel");
            }
          }}
        />
      )}
      <PageLayout
        title="Channel Details"
        breadcrumbs={[
          { name: "Channels", route: "/channels" },
          { name: data.id.toString(), route: `/channels/${data.id}` },
        ]}
        actions={
          <>
            <Tooltip title={data.enabled ? "Disable" : "Enable"} arrow>
              <Switch
                checked={data.enabled || false}
                onClick={async () => {
                  alert("FIXME: wire up enable / disable");
                }}
              />
            </Tooltip>
            <Tooltip title="Clone" arrow>
              <span>
                <IconButton
                  onClick={() => {
                    // setShowTagManagementDialog(true);
                    alert("FIXME: wire up cloning channel");
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Manage Tags" arrow>
              <span>
                <IconButton
                  onClick={() => {
                    setShowTagManagementDialog(true);
                  }}
                >
                  <LocalOffer />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Delete" arrow>
              <span>
                <IconButton
                  onClick={async () => {
                    openConfirmationDialog(
                      "Delete Confirmation",
                      `Are you sure you want to delete ${data.name}?`,
                      async () => {
                        try {
                          await deleteChannel(data.id);
                          addGeneralNotification(
                            "Channel successfully deleted",
                          );
                          history.push("/channels");
                        } catch {
                          addError("Failed to delete channel");
                        }
                      },
                    );
                  }}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ width: "fit-content" }}
            >
              <img
                src={INTEGRATION_IMG_SRC[data.integration.name]}
                alt={data.integration.name}
                width={120}
              />
              <Stack>
                <Typography variant="h4">{data.name}</Typography>
                <Typography>{data.description}</Typography>
                <Typography variant="caption">Type: {data.type}</Typography>
              </Stack>
            </Stack>
          </Stack>

          {data.tags.length > 0 && (
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Typography component="span" fontWeight="bold">
                Tags:{" "}
              </Typography>
              <TagList tags={data.tags} />
            </Stack>
          )}
          <Box>
            <Box
              sx={{
                pt: 2,
                minHeight: "500px",
              }}
            >
              <Stack spacing={2}>
                <TextField label="Name" {...register("name")} />
                <TextField label="Description" {...register("description")} />
                <TextField
                  label="Meeting Template"
                  {...register("meetingTemplateID")}
                  value={data.meetingTemplate.id}
                />
                <TextField
                  label="Config"
                  multiline
                  disabled
                  maxRows={4}
                  value={JSON.stringify(data.config)}
                />
              </Stack>
            </Box>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <SecondaryButton disabled={!isDirty} onClick={() => reset()}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={!isDirty}
              onClick={() => {
                updateChannel(getValues())
                  .then(() => {
                    addGeneralNotification("Successfully updated channel");
                  })
                  .catch(() => {
                    addError("Failed to update channel");
                  });
              }}
            >
              Save
            </PrimaryButton>
          </Stack>
        </Stack>
      </PageLayout>
    </>
  );
}

export default function Page() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error, mutate } = useChannel(id);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  return <Channel data={data} onChannelUpdate={() => mutate()} />;
}
