import React, { useCallback } from "react";

import { useTagsRepository } from "../../../repository";
import { TagManager } from "../../../components/tagManager";
import { useMeeting } from "../state";
import { useUpdateMeetingTags } from "../../../mutations";

const isSavable = (selectedTags) => selectedTags !== null;

const InstanceDetailsTagManager = ({ tags = [], close }) => {
  const [meeting] = useMeeting();
  const meetingId = meeting?.id || null;
  const repository = useTagsRepository();
  const updateMeetingTags = useUpdateMeetingTags();
  const saveInstanceTags = useCallback(
    (selectedTags) => {
      updateMeetingTags(
        [meetingId],
        selectedTags.map((t) => t.id),
      );
    },
    [meetingId, updateMeetingTags],
  );
  return (
    <TagManager
      title="Manage Meeting Details Tags"
      initialSelectedTags={tags}
      repository={repository}
      isSavable={isSavable}
      onSave={saveInstanceTags}
      close={close}
    />
  );
};

export default InstanceDetailsTagManager;
