import React, { useMemo } from "react";
import style from "./style.module.scss";

function DisplayFailedInstances({ failures = [] }) {
  const failCount = useMemo(() => failures.length, [failures]);

  const failMessage = useMemo(
    () =>
      failCount === 1
        ? "Unable to update the following meeting: "
        : `Unable to update the following ${failCount} meetings: `,
    [failCount],
  );

  return (
    <>
      {failMessage}
      <div className={style.container}>
        <ul className={style.list}>
          {failures.map((failure, i) => (
            <li key={`failed-instance-${i + 1}`} className={style.item}>
              {`${failure.meetingId} - ${failure.display}`}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default DisplayFailedInstances;
