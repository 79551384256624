import React from "react";
import PropTypes from "prop-types";
import { mdiCheck, mdiClose, mdiMinus } from "@mdi/js";
import Icon from "@mdi/react";
import cx from "classnames";
import shortid from "shortid";
import style from "./switch.module.scss";

function Switch({
  children,
  dataCy = null,
  dataGtm = null,
  disabled,
  handleToggle,
  icon,
  id,
  isOn,
  useIcon,
  name = null,
  size,
  labelClassName,
  ...rest
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  const elmID = id || shortid.generate();

  const iconSize = {
    default: 0.7,
    large: 1,
    small: 0.5,
    xsmall: 0.3,
  };

  const getIcon = () => {
    if (isOn) {
      return mdiCheck;
    }

    if (disabled) {
      return mdiMinus;
    }

    return mdiClose;
  };

  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className={style.switchChk}
        id={`chk-switch-${elmID}`}
        type="checkbox"
        disabled={disabled}
        name={name}
        aria-label={name}
        {...rest}
        {...dataProps}
      />
      <label
        style={{ margin: 0 }}
        data-size={size}
        className={cx(
          style.switchLbl,
          {
            [style.isOn]: isOn,
            [style.disabled]: disabled,
          },
          labelClassName,
        )}
        htmlFor={`chk-switch-${elmID}`}
      >
        <span className={cx(style.switchBtn)}>
          {useIcon ? <Icon path={getIcon()} size={iconSize[size]} /> : children}
        </span>
      </label>
    </>
  );
}

Switch.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  handleToggle: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOn: PropTypes.bool,
  size: PropTypes.oneOf(["xsmall", "small", "default", "large"]),
  useIcon: PropTypes.bool,
};

Switch.defaultProps = {
  children: null,
  disabled: false,
  handleToggle: () => {},
  icon: null,
  id: null,
  isOn: false,
  size: "default",
  useIcon: true,
};

export default Switch;
