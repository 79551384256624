import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { mdiDeleteAlert } from "@mdi/js";

import { DEFAULT, useModalV2 } from "../components/modal";

import { useMeetings } from "../queries/useMeetings";
import { MeetingTable } from "./multiGuestTable";
import { buildUrl } from "../utils/fetchV2";
import InstanceDetails from "./instanceDetails";
import { useSearch } from "./Search";
import { useMeetingSearchResults } from "../queries";
import MeetingTagNames from "./MeetingTagNames";
import { Menu } from "./multiGuestMenu";
import {
  useCreateMeetings,
  useDeleteMeetings,
  useSendLater,
  useUpdateMeetings,
} from "../mutations";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import DisplayFailedInstances from "./notifications/DisplayFailedInstances";
import Batching from "./Batching/Batching";

// A container component for the meeting menu and table
export function MenuAndTable() {
  const history = useHistory();

  const { id = null } = useParams();

  const search = useSearch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [ids, setIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [isBatching, setIsBatching] = useState(false);

  const createMeetings = useCreateMeetings();
  const deleteMeetings = useDeleteMeetings();
  const updateMeetings = useUpdateMeetings();
  const sendLater = useSendLater();

  const { addError, addInternalError, addGeneralNotification } =
    useGeneralNotifications();

  const onClose = useCallback(() => {
    const { pathname, search: s } = window.location;
    const url = buildUrl("/instances", s);
    if (pathname + s !== url) history.push(url);
  }, [history]);

  const [InstanceDetailsModal, openInstanceDetails, closeInstanceDetails] =
    useModalV2(InstanceDetails, DEFAULT, {
      onClose,
    });

  const handleCloseInstanceDetails = useCallback(() => {
    closeInstanceDetails();
  }, [closeInstanceDetails]);

  useEffect(() => {
    if (id !== null) {
      openInstanceDetails();
    } else {
      closeInstanceDetails();
    }
  }, [closeInstanceDetails, id, openInstanceDetails]);

  const { data: meetingSearchResults, loading } = useMeetingSearchResults({
    ...search,
    paging: {
      limit: pageSize,
      offset: page,
    },
  });

  // Extract meeting ids from the search results and use meetings hook to request updates.
  useEffect(() => {
    setIds(
      meetingSearchResults ? meetingSearchResults.data.map((m) => m.id) : [],
    );
  }, [meetingSearchResults]);

  const { data: meetingData } = useMeetings(ids);

  useEffect(() => {
    if (meetingSearchResults?.data) {
      setMeetings(meetingSearchResults.data);
    }
  }, [meetingSearchResults, setMeetings]);

  useEffect(() => {
    if (meetingData) {
      setMeetings(meetingData);
    }
  }, [meetingData, setMeetings]);
  const [showCreateNewMeetingRow, setShowCreateNewMeetingRow] = useState(false);

  const handleBulkActivate = useCallback(async () => {
    setIsBatching(true);
    try {
      const { instances, failures } = await updateMeetings(
        selected.map((meetingId) => ({ enabled: true, id: meetingId })),
      );

      // report failures
      if (failures && failures.length > 0) {
        addError(<DisplayFailedInstances failures={failures} />);
      }

      // report successes
      if (instances && instances.length > 0) {
        const instanceCount = instances.length;
        addGeneralNotification(
          <div>
            Successfully activated
            {instanceCount === 1
              ? ` instance.`
              : ` ${instanceCount} instances.`}
          </div>,
        );
      }
    } catch (error) {
      addInternalError(error);
    }
    setIsBatching(false);
  }, [
    addError,
    addGeneralNotification,
    addInternalError,
    updateMeetings,
    selected,
  ]);

  const handleBulkSendLater = useCallback(
    async (executeAt) => {
      setIsBatching(true);
      try {
        const { instances, failures } = await sendLater(
          selected.map((meetingId) => ({
            enabled: true,
            executeAt,
            id: meetingId,
          })),
        );

        // report failures
        if (failures && failures.length > 0) {
          addError(<DisplayFailedInstances failures={failures} />);
        }

        // report successes
        if (instances && instances.length > 0) {
          const instanceCount = instances.length;
          addGeneralNotification(
            <div>
              Successfully queued
              {instanceCount === 1
                ? ` instance `
                : ` ${instanceCount} instances `}
              for future activation
            </div>,
          );
        }
      } catch (error) {
        addInternalError(error);
      }
      setIsBatching(false);
    },
    [addError, addGeneralNotification, addInternalError, sendLater, selected],
  );

  const handleBulkDelete = useCallback(async () => {
    try {
      await deleteMeetings(selected);

      setIds(ids.filter((i) => !selected.includes(i)));
      setMeetings(meetings.filter((m) => !selected.includes(m.id)));

      setSelected([]);

      addGeneralNotification(
        `Successful deletion of ${selected.length} records`,
        mdiDeleteAlert,
      );
    } catch (err) {
      addError("Failed to delete meetings");
      return null;
    }
  }, [
    ids,
    setIds,
    meetings,
    setMeetings,
    selected,
    addGeneralNotification,
    addError,
    deleteMeetings,
  ]);

  const handleNewMeetingCreated = useCallback(
    (a) => {
      createMeetings([
        {
          contact: a.guest?.id,
          meetingType: a.meetingType?.id,
          meta: {
            platform: "web",
            source: "instances_page/add_new_button",
          },
          user: a.host?.id,
        },
      ])
        .then((res) => {
          setIds(res.instances.map((m) => m.id).concat(ids));
          setMeetings(
            res.instances
              .map((m) => ({
                ...m,
                contact: a.guest || m.contact,
                host: a.host || m.host,
                meetingType: a.meetingType || m.meetingType,
              }))
              .concat(meetings),
          );
        })
        .then(() => setShowCreateNewMeetingRow(false));
    },
    [ids, createMeetings, meetings, setMeetings],
  );

  const handleSelectedToggle = useCallback(
    (meetingId) => {
      const checked = selected.includes(meetingId);
      if (checked) {
        setSelected(selected.filter((s) => s !== meetingId));
      } else {
        setSelected(selected.concat(meetingId));
      }
    },
    [selected, setSelected],
  );

  const handleSelectAllToggle = useCallback(
    (state, meetingIds) => {
      if (state) {
        setSelected(Array.from(new Set(selected.concat(meetingIds))));
      } else {
        setSelected(selected.filter((m) => !meetingIds.includes(m)));
      }
    },
    [selected, setSelected],
  );

  const handleNewMeetingRowClose = useCallback(
    () => setShowCreateNewMeetingRow(false),
    [setShowCreateNewMeetingRow],
  );

  const handlePageChange = setPage;

  const handlePageSizeChange = (p) => {
    setPageSize(p);
    setPage(0);
  };

  const totalCost =
    meetings &&
    meetings
      .filter((m) => m.guests?.length > 0 && m.guests[0].status === "accepted")
      .reduce((p, c) => p + (c.meetingType?.cost || 0), 0);

  return (
    <>
      {isBatching && <Batching />}
      <MeetingTagNames>
        <Menu
          key="meetings-table-menu"
          selected={selected}
          totalCount={meetingSearchResults?.total || 0}
          totalCost={totalCost}
          onBulkDelete={handleBulkDelete}
          onBulkActivate={handleBulkActivate}
          onBulkSendLater={handleBulkSendLater}
          onNewMeetingButtonClicked={() => setShowCreateNewMeetingRow(true)}
        />

        <InstanceDetailsModal
          close={handleCloseInstanceDetails}
          initialized={!loading}
          meetings={meetings}
        />
        <MeetingTable
          loading={loading}
          total={meetingSearchResults?.total || 0}
          page={page}
          pageSize={pageSize}
          meetings={meetings}
          selected={selected}
          onPageSizeChange={handlePageSizeChange}
          showCreateNewMeetingRow={showCreateNewMeetingRow}
          onCreateNewMeetingRowClose={handleNewMeetingRowClose}
          onNewMeetingCreated={handleNewMeetingCreated}
          onSelectedToggle={handleSelectedToggle}
          onSelectAllToggle={handleSelectAllToggle}
          onPageChange={handlePageChange}
        />
      </MeetingTagNames>
    </>
  );
}
