import React, { useRef, useCallback, useMemo, useEffect } from "react";
import * as ReactQuill from "react-quill";
import Quill from "quill";
import ImageResize from "quill-image-resize-module-react";
import * as BlotFormatter from "quill-blot-formatter";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import "react-quill/dist/quill.snow.css";
import style from "./style.module.scss";
import OutlinedInput from "../OutlinedInput";
import {
  BRIDGE_FIELDS,
  DISPLAY_SYSTEM_FIELDS,
} from "src/meetingTypes/useDynamicVariableMappings";

export const cleanRichTextHTML = (msg: string) => {
  return (
    msg
      .trimStart()
      // replace double new-lines with single new-lines
      .replace(/(\n\n)/gm, "\n")
      // do the same for double carriage returns
      .replace(/(\r\n\r\n)/gm, "\r\n")
      // replace new-lines breaks
      .replace(/(\r\n|\n|\r)/gm, "<div><br></div>")
  );
};

// ui/icons only contains icons for header 1 and header 2, but header 3 is contained elsewhere (quill assets), so i just copied the SVG and manually edited icons
const icons = Quill.import("ui/icons");
icons.header[3] = `<svg viewBox="0 0 18 18">
    <path
      class="ql-fill"
      d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"
    />
  </svg>`;

const mergeFieldIcon = `<svg viewBox="0 0 18 18"><polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon><polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon></svg>`;

Quill.register("modules/imageResize", ImageResize, true);
Quill.register("modules/blotFormatter", BlotFormatter);

// we use a <div> as the line break character. we do this in order to overwrite
// the default line break character (<p><br><p/>) which produces a larger
// line break than expected
const Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block);

// method found here: https://github.com/quilljs/quill/issues/262#issuecomment-948890432
const Link = Quill.import("formats/link");
// add overrides to the default list (default: ['http', 'https', 'mailto', 'tel'])
Link.PROTOCOL_WHITELIST = [
  "http",
  "https",
  "mailto",
  "tel",
  "radar",
  "rdar",
  "smb",
  "sms",
];

function isValidUrl(url = "") {
  return Link.PROTOCOL_WHITELIST.some((prefix: string) =>
    url.startsWith(prefix),
  );
}

// custom sanitize function
Link.sanitize = (url = "") => {
  if (isValidUrl(url)) {
    return url;
  }

  return `http://${url}`;
};

const FORMATS = [
  "font",
  "size",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "blockquote",
  "list",
  "script",
  "bullet",
  "indent",
  "link",
  "image",
  "width",
  "align",
];

function QuillWrapper({
  value = "",
  onChange = () => null,
  readOnly = false,
  withMergeFields = false,
}: {
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  withMergeFields?: boolean;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  const { addError } = useGeneralNotifications();

  const canInsertImage = useCallback(
    (ops = []) => {
      if (ops.length < 1) {
        return false;
      }

      for (let i = 0; i < ops.length; i += 1) {
        const currentOp = ops[i];
        if (currentOp.insert && currentOp.insert.image) {
          if (!isValidUrl(currentOp.insert.image)) {
            addError("Image must contain an embedded URL");
            return false;
          }
        }
      }

      return true;
    },
    [addError],
  );

  const imageHandler = useCallback(() => {
    if (!ref || !ref.current) {
      return;
    }

    const editor = ref.current.getEditor();

    const range = editor.getSelection();

    // method found here: https://github.com/quilljs/quill/issues/2044#issuecomment-603630374
    const {
      theme: { tooltip },
    } = editor;

    const originalSaveFn = tooltip.save;
    const originalHideFn = tooltip.hide;

    tooltip.hide = () => {
      tooltip.save = originalSaveFn;
      tooltip.hide = originalHideFn;
      tooltip.hide();
    };

    tooltip.save = () => {
      const {
        textbox: { value: enteredVal },
      } = tooltip;

      if (enteredVal) {
        editor.insertEmbed(
          range.index,
          "image",
          enteredVal,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Quill.sources.USER,
        );
      }

      tooltip.hide();
    };

    tooltip.edit("image");
    tooltip.textbox.placeholder = "Embed Image URL";
  }, [ref]);

  const mergeFieldHandler = useCallback(
    (val) => {
      if (!ref || !ref.current) {
        return;
      }

      if (typeof val !== "string") {
        return;
      }

      const editor = ref.current.getEditor();

      const cursor = editor.getSelection().index;

      editor.insertText(cursor, `{{${BRIDGE_FIELDS[val]}}}`);
    },
    [ref],
  );

  const MODULES = useMemo(() => {
    const toolbarContainer: any[] = [
      [{ font: [] }], // font selection
      [{ size: [] }], // font-size selection
      [{ header: 1 }, { header: 2 }, { header: 3 }], // header selection
      ["bold", "italic", "underline", "strike", "blockquote"], // text formatting
      [{ color: [] }, { background: [] }], // text color and background (highlighting)
      [{ list: "ordered" }, { list: "bullet" }], // numbered lists and bulleted lists
      [{ script: "sub" }, { script: "super" }], // subscript and superscript
      ["link", "image"], // insert link, image
      [{ align: "" }, { align: "center" }, { align: "right" }], // text align
    ];
    if (withMergeFields) {
      toolbarContainer.push([
        { mergeFields: Object.values(DISPLAY_SYSTEM_FIELDS) }, // our merge fields for dynamic variables
      ]);
    }

    return {
      clipboard: {
        matchVisual: false,
      },
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true,
      },
      imageResize: {
        modules: ["Resize", "DisplaySize"],
        parchment: Quill.import("parchment"),
      },
      toolbar: {
        container: toolbarContainer,
        handlers: {
          image: imageHandler,
          mergeFields: mergeFieldHandler,
        },
      },
    };
  }, [imageHandler, mergeFieldHandler, withMergeFields]);

  // init custom dropdown
  useEffect(() => {
    if (!withMergeFields) {
      return;
    }

    // set the text for each of the dropdown items
    document
      .querySelectorAll(".ql-mergeFields .ql-picker-item")
      .forEach((item) => {
        item.textContent = item.getAttribute("data-value");
      });

    // set the name (drop down display)
    document
      .querySelectorAll(".ql-mergeFields .ql-picker-label")
      .forEach((selector) => {
        selector.innerHTML = `Merge Fields ${mergeFieldIcon}`;
      });
  }, [withMergeFields]);

  const onEditorChange = useCallback(
    (v, delta) => {
      if (!canInsertImage(delta.ops)) {
        // set value back to what we had previously. this is required as Quill will allow addition of text/image even
        // when value does not get updated, so we revert to previous `value` state
        onChange(value);
        return;
      }

      onChange(v);
    },
    [canInsertImage, onChange, value],
  );

  return (
    <ReactQuill
      className={style.quill_wrapper}
      ref={ref}
      value={value}
      readOnly={readOnly}
      onChange={onEditorChange}
      modules={MODULES}
      formats={FORMATS}
      theme="snow"
    />
  );
}

export function EmailEditor({
  label,
  value,
  onChange,
  withMergeFields = false,
}: {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  withMergeFields?: boolean;
}) {
  return (
    <OutlinedInput label={label}>
      <QuillWrapper
        value={value}
        onChange={onChange}
        withMergeFields={withMergeFields}
      />
    </OutlinedInput>
  );
}
