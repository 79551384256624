import useSWR from "swr";
import { Account } from "src/pages/settings/accounts";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useUserAccounts() {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(`/api/users/me/accounts`, (url) =>
    service
      .get(url)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler),
  );

  return {
    data: { data } as { data: Account[] },
    error,
    loading: !error && !data,
  };
}

export default useUserAccounts;
