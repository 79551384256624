import { useCallback } from "react";
import {
  buildRequest,
  buildUrl,
  fetchWithErrorHandling,
} from "../utils/fetchV2";
import { API } from "../props";
import { useCustomFetchFn } from "../components/fetch";

const buildGetGuestsRequest = (params) =>
  buildRequest(buildUrl(API.contacts.default(), params));

const buildPatchGuestStatusRequest = (reqBody) =>
  buildRequest(API.meetings.guestStatus, {
    body: JSON.stringify(reqBody),
    method: "PATCH",
  });

async function searchGuests(filter, limit = 100, offset = 0) {
  try {
    const params = [
      ["limit", limit],
      ["offset", offset],
    ];

    if (filter) {
      params.push(["qry", filter]);
    }

    const request = buildGetGuestsRequest(params);
    const response = await fetchWithErrorHandling(request);
    const { status } = response;

    if (status !== 200) {
      return {
        data: null,
        error: new Error(await response.text()),
      };
    }

    return {
      data: await response.json(),
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
}

export function useGuestsRepository() {
  const { fetch } = useCustomFetchFn();

  const patchGuestStatus = useCallback(
    async (reqBody) => fetch(buildPatchGuestStatusRequest(reqBody)),
    [fetch],
  );

  return {
    patchGuestStatus,
    searchGuests,
  };
}
