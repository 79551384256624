import { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { mdiCalendarCheck } from "@mdi/js";

import validateMeetingType from "../meetingTypes/useValidateMeetingType";
import {
  buildRequest,
  buildUrl,
  fetchWithErrorHandling,
} from "../utils/fetchV2";
import { API } from "../props";
import useDoFetch from "./useDoFetch";
import { useCustomFetchFn } from "../components/fetch";
import { UnauthorizedError, toErrorLogInfo, UnknownError } from "../error";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import { HTTP_METHODS } from "../components/fetch/props";

const buildGetMeetingTypeById = (id) => buildRequest(API.meetings.default(id));

const buildGetMeetingTypesRequest = () => buildRequest(API.meetings.default());

const buildUpsertUnsubscribeTemplateRequest = ({ body, meetingTypeId }) =>
  buildRequest(API.meetings.unsubscribeTemplate(meetingTypeId), {
    body: JSON.stringify(body),
    method: HTTP_METHODS.POST,
  });

export function buildMeetingTypeRequest({
  query = null,
  limit = 100,
  inviteStyleFilter = [],
}) {
  const params = [["limit", limit]];

  inviteStyleFilter?.forEach((inviteStyle) => {
    params.push(["inviteStyleFilter", inviteStyle]);
  });

  if (query !== null) {
    const trimmedQuery = query.trim();

    if (trimmedQuery.length > 0) {
      params.push(["query", trimmedQuery]);
    }
  }
  return buildRequest(buildUrl(API.meetings.default(), params));
}

function buildMeetingTemplateRequest({ query = null, limit = 100 }) {
  const params = [["limit", limit]];
  if (query !== null) {
    const trimmedQuery = query.trim();
    if (trimmedQuery.length > 0) {
      params.push(["query", trimmedQuery]);
    }
  }
  return buildRequest(buildUrl(API.meetings.global.default(), params));
}

function buildUpdateMeetingTypeRequest(meetingTypeId, newMeetingType) {
  return buildRequest(API.meetings.default(meetingTypeId), {
    body: JSON.stringify(newMeetingType, null, 4),
    method: "PATCH",
  });
}

function buildUpdateMeetingTemplatesRequest(
  meetingTemplate,
  newMeetingTemplate,
) {
  return buildRequest(API.meetings.global.default(meetingTemplate), {
    body: JSON.stringify(newMeetingTemplate, null, 4),
    method: "PATCH",
  });
}

function buildCreateMeetingTypeRequest(newMeetingType) {
  return buildRequest(API.meetings.default(), {
    body: JSON.stringify(newMeetingType, null, 4),
    method: "POST",
  });
}

function buildCreateMeetingTemplatesRequest(newMeetingType) {
  return buildRequest(API.meetings.global.default(), {
    body: JSON.stringify(newMeetingType, null, 4),
    method: "POST",
  });
}

function buildDeleteMeetingTypeRequest(meetingTypeId) {
  return buildRequest(API.meetings.default(meetingTypeId), {
    method: "DELETE",
  });
}

function buildDeleteMeetingTemplateRequest(meetingTypeId) {
  return buildRequest(API.meetings.global.default(meetingTypeId), {
    method: "DELETE",
  });
}

function useMeetingTypesQuery(query = null) {
  const [isManuallyRefreshing, setIsManuallyRefreshing] = useState(false);

  const meetingTypeRequests = useMemo(() => {
    return [buildMeetingTypeRequest({ query })];
  }, [query]);

  const {
    errors: [meetingTypesError],
    isInitialized: areMeetingTypesInitialized,
    isLoading: areMeetingTypesLoading,
    payloads: [meetingTypesPayload = null],
    refresh,
    setPayloads,
  } = useDoFetch(meetingTypeRequests);

  const meetingTypes = useMemo(() => {
    if (meetingTypesPayload === null) {
      return [];
    }
    const { data } = meetingTypesPayload;
    return data;
  }, [meetingTypesPayload]);

  const setMeetingTypes = useCallback(
    (...args) => {
      const arg0 = args[0];
      if (typeof arg0 === "function") {
        setPayloads(([value]) => {
          if (value === null) {
            return;
          }
          const { data: prevMeetingTypes } = value;
          return [{ data: arg0(prevMeetingTypes) }];
        });
        return;
      }
      setPayloads([{ data: arg0 }]);
    },
    [setPayloads],
  );

  const total = useMemo(() => {
    if (meetingTypesPayload === null) {
      return 0;
    }
    const { total: value } = meetingTypesPayload;
    return value;
  }, [meetingTypesPayload]);

  const refreshWithTemporaryTypes = useCallback(async () => {
    const temporaryTypes = meetingTypes.filter(
      (meetingType) => !Number.isInteger(meetingType.id),
    );
    await refresh();
    setMeetingTypes((prev) => [...prev, ...temporaryTypes]);
  }, [meetingTypes, refresh, setMeetingTypes]);

  return {
    error: meetingTypesError,
    isInitialized: areMeetingTypesInitialized,
    isLoading: areMeetingTypesLoading,
    isManuallyRefreshing,
    meetingTypes,
    payload: meetingTypesPayload,
    refresh: refreshWithTemporaryTypes,
    setIsManuallyRefreshing,
    setMeetingTypes,
    total,
  };
}

async function search(query = null) {
  try {
    const request = buildMeetingTypeRequest({ query });
    const response = await fetchWithErrorHandling(request);
    const { status } = response;
    if (status === 401) {
      throw new UnauthorizedError(
        await response.text(),
        toErrorLogInfo("UnauthorizedError", request),
      );
    }
    if (status !== 200) {
      throw new Error(await response.text());
    }
    return {
      data: await response.json(),
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
}

function toPayload(newMeetingType) {
  const payload = {
    ...newMeetingType,
    enabled: newMeetingType.active,
  };
  if (newMeetingType.tagAutomation) {
    payload.tagAutomation = {
      tagContacts: newMeetingType.tagAutomation.tagContacts.map((t) => t.id),
      tagMeetings: newMeetingType.tagAutomation.tagMeetings.map((t) => t.id),
      tagUsers: newMeetingType.tagAutomation.tagUsers.map((t) => t.id),
    };
  }
  if (newMeetingType.tags) {
    payload.tags = newMeetingType.tags.map((t) => t.id);
  }
  if (newMeetingType.team) {
    payload.team = newMeetingType.team.id;
  }
  if (!newMeetingType.properties.meetingReminder) {
    payload.properties.meetingReminder = {
      body: "",
      days: 0,
      enabled: false,
      hours: 0,
      minutes: 15,
      title: "",
    };
  }
  delete payload.active;
  return payload;
}

export function useCreateMeetingType() {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    async (newMeetingType) => {
      const response = await fetch(
        buildCreateMeetingTypeRequest(toPayload(newMeetingType)),
      );
      if (response.ok) {
        const result = await response.json();
        return result;
      }
      // TODO: Reorganize this error
      throw new UnknownError("Failed to create meeting template.");
    },
    [fetch],
  );
}

export default function useMeetingTypesRepository() {
  const { fetch } = useCustomFetchFn();
  const getMeetingTypeById = useCallback(
    (id) => fetch(buildGetMeetingTypeById(id)).then((res) => res.json()),
    [fetch],
  );

  const getMeetingTypes = useCallback(
    () => fetch(buildGetMeetingTypesRequest()).then((r) => r.json()),
    [fetch],
  );

  const upsertUnsubscribeTemplate = useCallback(
    async (meetingTypeId, body) =>
      fetch(
        buildUpsertUnsubscribeTemplateRequest({ body, meetingTypeId }),
      ).then((r) => r.json()),
    [fetch],
  );

  return {
    getMeetingTypeById,
    getMeetingTypes,
    search,
    upsertUnsubscribeTemplate: upsertUnsubscribeTemplate,
    useMeetingTypesQuery,
  };
}

function useMeetingItem({
  isLibraryTemplate,
  query,
  requests: {
    buildCreateRequest,
    buildDeleteRequest,
    buildGetRequest,
    buildUpdateRequest,
  },
  saveOnActive = false,
}) {
  const { upsertUnsubscribeTemplate: upsertUnsubscribeTemplate } =
    useMeetingTypesRepository();
  const { addGeneralNotification } = useGeneralNotifications();
  const [initialized, setInitialized] = useState(false);
  // TODO: Replace these with a reducer
  const [data, setData] = useState(null);
  const [creating, setCreating] = useState(true);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const { fetch } = useCustomFetchFn();

  useEffect(() => {
    async function run() {
      setLoading(true);
      try {
        const response = await fetch(buildGetRequest({ limit: 100, query }));
        const { data: newData } = await response.json();
        setData(newData);
        setInitialized(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    run();
  }, [buildGetRequest, fetch, query]);

  const deleteMeetingItem = useCallback(
    async (meetingTypeId) => {
      try {
        setDeleting(true);
        await fetch(buildDeleteRequest(meetingTypeId));
        setData((prev) => prev.filter((mt) => mt.id !== meetingTypeId));
      } catch (error) {
        setError(error);
      } finally {
        setDeleting(false);
      }
    },
    [fetch, buildDeleteRequest],
  );

  const save = useCallback(
    async ({
      meetingTypeId,
      newMeetingType,
      previousMeetingType = null,
      showNotification = false,
    }) => {
      const meetingTypeValidation = validateMeetingType({
        meetingType: newMeetingType,
      });
      const templateWithErrors = meetingTypeValidation.emails.emails.find(
        (email) => email.body.error || email.subject.error,
      );
      if (templateWithErrors) {
        return;
      }

      // TODO: break up the rest of this request body into smaller, bite-size requests

      // -- handle unsubscribe -- //
      const { unsubscribeTemplate: newUnsubscribeTemplate = {} } =
        newMeetingType;

      try {
        setSaving(true);

        if (!isLibraryTemplate) {
          // save unsubscribe config
          await upsertUnsubscribeTemplate(
            meetingTypeId,
            newUnsubscribeTemplate,
          );
        }
        // save main meeting type content
        // this update returns the full meeting type, so we use it to populate state
        const mainResponse = await fetch(
          buildUpdateRequest(meetingTypeId, toPayload(newMeetingType)),
        );

        if (mainResponse.ok) {
          const result = await mainResponse.json();
          setData((prev) => {
            const copy = [...prev];
            const index = copy.findIndex((m) => m.id === meetingTypeId);
            copy[index] = result;
            return copy;
          });
        }

        if (showNotification) {
          addGeneralNotification("Type Updated", mdiCalendarCheck);
        }
      } catch (newError) {
        // Restore the meeting template to previous state
        if (previousMeetingType !== null) {
          setData((prev) => {
            const copy = [...prev];
            const index = copy.findIndex((m) => m.id === meetingTypeId);
            copy[index] = previousMeetingType;
            return copy;
          });
        }
        try {
          setSaveError(JSON.parse(newError.message).errors);
        } catch (error) {
          setSaveError(newError);
        }
      } finally {
        setSaving(false);
      }
    },
    [
      addGeneralNotification,
      buildUpdateRequest,
      fetch,
      isLibraryTemplate,
      upsertUnsubscribeTemplate,
    ],
  );

  const debouncedSave = useMemo(() => {
    return debounce(save, 1000);
  }, [save]);

  const update = useCallback(
    async (meetingTypeId, newMeetingType, isDebounced = true) => {
      const meetingTypeIndex = data.findIndex((m) => m.id === meetingTypeId);
      if (meetingTypeIndex === -1) {
        throw new Error("Meeting template not found.");
      }
      const copy = [...data];
      // TODO: Probably need a more complex function here
      // TODO: Should this be a merge?
      const previousMeetingType = data[meetingTypeIndex];
      copy[meetingTypeIndex] = {
        ...previousMeetingType,
        ...newMeetingType,
      };
      setData(copy);
      setSaveError(null);
      if (
        saveOnActive ||
        !previousMeetingType.active ||
        (previousMeetingType.active && !newMeetingType.active)
      ) {
        setSaving(true);
        if (isDebounced) {
          // TODO(Matt): Find a way to separate the debounced version from the regular version
          debouncedSave({
            meetingTypeId,
            newMeetingType,
            previousMeetingType,
          });
        } else {
          save({
            meetingTypeId,
            newMeetingType,
            previousMeetingType,
          });
        }
      }
    },
    [data, debouncedSave, save, saveOnActive],
  );

  const create = useCallback(
    async (newMeetingType) => {
      setCreating(true);
      try {
        const response = await fetch(
          buildCreateRequest(toPayload(newMeetingType)),
        );
        if (response.ok) {
          const result = await response.json();
          setData((prev) => [result, ...prev]);
          return result;
        }
        // TODO: Reorganize this error
        throw new UnknownError("Failed to create meeting template.");
      } finally {
        setCreating(false);
      }
    },
    [buildCreateRequest, fetch],
  );

  return {
    create,
    creating,
    data,
    deleteMeetingItem,
    deleting,
    error,
    initialized,
    loading,
    save,
    saveError,
    saving,
    setData,
    setSaveError,
    update,
  };
}

export function useMeetingTemplates({ query }) {
  return useMeetingItem({
    isLibraryTemplate: true,
    query,
    requests: {
      buildCreateRequest: buildCreateMeetingTemplatesRequest,
      buildDeleteRequest: buildDeleteMeetingTemplateRequest,
      buildGetRequest: buildMeetingTemplateRequest,
      buildUpdateRequest: buildUpdateMeetingTemplatesRequest,
    },
    saveOnActive: true,
  });
}

export function useMeetingTypes({ query }) {
  return useMeetingItem({
    isLibraryTemplate: false,
    query,
    requests: {
      buildCreateRequest: buildCreateMeetingTypeRequest,
      buildDeleteRequest: buildDeleteMeetingTypeRequest,
      buildGetRequest: buildMeetingTypeRequest,
      buildUpdateRequest: buildUpdateMeetingTypeRequest,
    },
  });
}
